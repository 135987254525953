import React, { ReactElement } from 'react';
import { Column, UseExpandedRowProps, UseTableRowProps } from 'react-table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinearProgress } from '@mui/material';
import cn from 'classnames';
import { format } from 'date-fns';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { Routes } from 'enums/Routes.enum';
import { UserTableNavCell } from 'views/People/UserTableNavCell';

import styles from './LearnerTaskLibrary.module.scss';

interface ExpandRow {
  row: UseExpandedRowProps<LearnerTaskColumn> &
    UseTableRowProps<LearnerTaskColumn>;
}

export type LearnerTaskColumn = Task & {
  user?: User;
  tasks: Task[];
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  isCombiningHover?: boolean;
};

export const columns: Column<LearnerTaskColumn>[] = [
  {
    id: 'task',
    Header: 'Lesson',
    Cell: ({ row }: ExpandRow): ReactElement => {
      const { depth, original, isExpanded } = row;
      const { title, id, imageUrl, isFolder, stepsAmount, tasksAmount } =
        original;

      return (
        <div className={cn(depth && styles['sub-row-icon-bar'])}>
          <UserTableNavCell
            hasAvatarPlaceholder
            title={title}
            imageURL={imageUrl}
            params={{ id }}
            route={isFolder ? undefined : Routes.LearnerTaskDetails}
            isDragIconVisible
            isDragDisabled={isExpanded && !!tasksAmount}
            task={{
              id,
              title,
              stepsAmount,
              isFolder,
              tasksAmount
            }}
          />
        </div>
      );
    },
    width: 3,
    minWidth: 200,
    maxWidth: 200
  },
  {
    id: 'complitionPercentage',
    Header: 'Progress',
    accessor: (task): LearnerTaskColumn => task,
    Cell: ({ value }: { value: LearnerTaskColumn }) => (
      <span className={styles.progress}>
        <LinearProgress
          style={{ height: 7 }}
          className={styles['progress-bar']}
          key={value.id}
          variant="determinate"
          value={value.completionPercentage ?? 0}
        />
        <span>{value.completionPercentage}%</span>
      </span>
    ),
    width: 1,
    minWidth: 150
  },
  {
    id: 'firstCompletedDate',
    Header: 'Completed On',
    accessor: (row) =>
      row.firstCompletedDate
        ? format(row.firstCompletedDate, 'dd MMMM yyyy')
        : '',
    width: 1,
    minWidth: 100
  },
  {
    id: 'expander',
    Header: '',
    Cell: ({ row }: ExpandRow) => {
      const { original, getToggleRowExpandedProps, isExpanded } = row;

      return original.isFolder && original.tasksAmount ? (
        <span {...getToggleRowExpandedProps()}>
          <ExpandMoreIcon
            className={cn(
              styles['expand-icon'],
              isExpanded && styles['expand-icon-active']
            )}
          />
        </span>
      ) : null;
    },
    width: 30,
    minWidth: 30
  }
];
