import { StepContentType } from 'enums/StepContentType.enum';
import { date, deserialize, object, serializable } from 'serializr';

export class FileDTO {
  @serializable
  id? = '';

  @serializable(date())
  createdAt?: Date = new Date();

  @serializable(date())
  updatedAt?: Date = new Date();

  @serializable
  key? = '';

  @serializable
  tag? = '';

  @serializable
  originalName? = '';

  @serializable
  fullUrl? = '';

  @serializable
  thumbnailKey? = '';

  @serializable
  thumbnailUrl? = '';

  @serializable
  compressedKey? = '';

  @serializable
  compressedUrl? = '';

  @serializable
  type?: StepContentType = StepContentType.text;

  static deserialize(json: Object | string): File {
    return deserialize(File, json);
  }
}
