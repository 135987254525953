import React, { FC, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { useActivityContext } from 'hooks/Auth/useActivityContext';
import * as queryString from 'query-string';

import styles from './SignoutCallback.module.scss';

export const SignoutCallback: FC = () => {
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const isOpen = true;
  const { location } = useHistory();
  const query = queryString.parse(location.search);
  const { stopActivity } = useActivityContext();
  const auth = useAuth();

  useEffect(() => {
    const executeSignOut = async () => {
      stopActivity(true);
      const sessionMonitor = window.cr_session_monitor;
      if (
        !auth.isLoading &&
        auth.isAuthenticated &&
        sessionMonitor &&
        sessionMonitor.is_running()
      ) {
        await auth.signoutSilent();
      }
    };
    executeSignOut();
  }, [auth, stopActivity]);

  const logIn = async () => {
    setIsRedirecting(true);
    setIsError(false);
    try {
      await auth.signinRedirect({
        state: query.state?.toString() || '/'
      });
    } catch (error) {
      console.error(error);
      setIsRedirecting(false);
      setIsError(true);
    }
  };

  return (
    <div>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.IdentityRedirectFailed}
      />
      <Modal isOpen={isOpen} onCloseModal={logIn} title="Session time out">
        <Box>
          You have been signed out of your account. Please click here to login
          again.
        </Box>
        <Box className={styles.login}>
          <LoadingButton
            size="small"
            aria-label="LogIn"
            onClick={() => logIn()}
            color="primary"
            variant="contained"
            loading={isRedirecting}
          >
            Login
          </LoadingButton>
        </Box>
      </Modal>
    </div>
  );
};
