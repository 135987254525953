import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { uploadAsset } from 'actions/Assets/assetsActions';
import imagePlaceholder from 'assets/image-placeholder.png';
import { If } from 'components/If';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Asset } from 'entities/Asset.entity';
import { File } from 'entities/File.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import {
  StepContentType,
  StepContentTypeTitles
} from 'enums/StepContentType.enum';

import styles from './FileUploader.module.scss';

export interface ImageData {
  url?: string | null;
  id: string | null;
}

interface Props {
  label: string;
  fileData?: File | null;
  contentType?: StepContentType;
  onChangeFile: (fileData: string | null) => void;
}

const Input = styled('input')({
  display: 'none'
});

export const FileUploader: FC<React.PropsWithChildren<Props>> = ({
  label,
  fileData,
  onChangeFile,
  contentType = StepContentType.image
}) => {
  const [file, setFile] = useState<File | null | undefined>(fileData);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { isError, error, mutateAsync, isLoading, isSuccess } = useMutation<
    Asset,
    ApiError,
    FormData
  >(uploadAsset, {
    onSuccess: ({ id, fullUrl, originalName, thumbnailUrl }) => {
      onChangeFile(id);
      setFile({
        url: fullUrl,
        originalName,
        thumbnailUrl
      });
    }
  });

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files?.length) {
      const formData = new FormData();

      formData.append('file', files[0]);

      mutateAsync(formData);
    }
  };

  const removeFile = () => {
    setFile(null);
    onChangeFile(null);

    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      <p className={styles.label}>{label}</p>
      <If
        condition={
          contentType === StepContentType.audio && !!file?.originalName
        }
      >
        <span className={styles.name}>{file?.originalName}</span>
      </If>
      <div className={styles['file-uploader']}>
        <StatusSnackBar
          isError={isError}
          isSuccess={isSuccess}
          errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
          successMessage={`${StepContentTypeTitles[contentType]} successfully updated`}
        />
        <If condition={contentType !== StepContentType.audio}>
          <img
            alt="uploaded"
            data-testid="image"
            className={styles.image}
            src={file?.thumbnailUrl || file?.url || imagePlaceholder}
          />
        </If>
        <label htmlFor={`contained-${contentType}-file`}>
          <Input
            type="file"
            accept={`${contentType}/*`}
            onChange={uploadFile}
            data-testid="file-input"
            ref={fileInputRef}
            id={`contained-${contentType}-file`}
          />
          <LoadingButton
            size="small"
            color="secondary"
            variant="contained"
            component="span"
            loading={isLoading}
            className={styles.button}
            startIcon={<CloudUploadIcon />}
          >
            {`Upload ${contentType}`}
          </LoadingButton>
        </label>
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={removeFile}
        >
          Remove
        </LoadingButton>
      </div>
    </div>
  );
};
