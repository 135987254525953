import React from 'react';
import cn from 'classnames';
import { If } from 'components/If';

import styles from './LoadingOverlay.module.scss';

interface Props {
  loading: boolean;
  contentWidth?: boolean;
}

export const AbsoluteSpinner: React.FC<React.PropsWithChildren<Props>> = ({
  loading,
  contentWidth
}) => (
  <If condition={loading}>
    <div
      className={cn(
        styles['loading-overlay'],
        styles.absolute,
        contentWidth && styles['content-width']
      )}
    >
      <div data-testid="spinner" className={styles.spinner} />
    </div>
  </If>
);
