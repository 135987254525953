export enum ErrorMessages {
  FailedPostRequest = 'Your action could not be completed at this time. Please check your internet connection and refresh this page. If you continue to have an issue, please reach out to our support team.',
  FailedGetRequest = "We're sorry, we could not load this page. Please check your internet connection and refresh this page. If you continue to have an issue, please reach out to our support team.",
  FailedRequiredField = 'This field is required',
  NotValidNewPassword = 'New password is not valid',
  NotValidEmail = 'Invalid email format',
  NotValidUsername = 'Invalid username format',
  NotValidScheduledDate = 'Only future date and time should be selected',
  NotValidConfirmPassword = 'Confirm password does not match the password',
  NotValidIdIdentityOrgId = 'Invalid Identity Organization ID',
  NewPasswordMatchOld = 'New password should be different from the old password',
  FailedRoleLogin = 'You do not have access to the CR CareCoordinator web portal. Please log into the mobile app or contact your administrator with questions.',
  FailedAction = 'Sorry, we could not process this request. Please try again in a moment.',
  NoMediaContent = 'Oops, it looks like you are missing media file',
  NoStepContentType = 'You must chose a content type to add step',
  IdentityRedirectFailed = 'Sorry, we could not redirect you to the Identity Provider. Please try again.'
}
