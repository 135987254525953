import { deserialize, list, primitive, serializable } from 'serializr';

export class CheckinDTO {
  @serializable
  prompt = '';

  @serializable(list(primitive()))
  responses?: string[] | null;

  static deserialize(json: Object | CheckinDTO): CheckinDTO {
    return deserialize(CheckinDTO, json);
  }

  static deserializeAsList(list: CheckinDTO[]): CheckinDTO[] {
    return list.map(CheckinDTO.deserialize);
  }
}
