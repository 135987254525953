import { useCallback, useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ApiError } from 'entities/ApiError.entity';
import { Routes } from 'enums/Routes.enum';

export const useErrorRedirectToPeople = (errorCondition: () => boolean) => {
  const history = useHistory();
  const { slug, slugId } = useParams<{
    slugId: string;
    slug: string;
  }>();
  const goToPeople = useCallback(() => {
    const path = generatePath(Routes.OrganizationPeople, {
      slug,
      slugId
    });
    history.push(path);
  }, [slug, slugId, history]);

  useEffect(() => {
    if (errorCondition()) {
      goToPeople();
    }
  }, [goToPeople, errorCondition]);
};

export const useUnauthorizedRedirectToPeople = (error: ApiError | null) => {
  useErrorRedirectToPeople(() => error?.statusCode === 403);
};
