import React, { FC, useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import _ from 'lodash';

interface Props {
  icon: JSX.Element;
  className?: string;
  menuPosition?: MenuPosition;
  rootClass?: string;
  paperClass?: string;
  buttonLabel?: string;
}

interface MenuPosition {
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
}

const menuDefaultPosition: MenuPosition = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
};

export const IconMenu: FC<React.PropsWithChildren<Props>> = ({
  children,
  icon,
  className,
  menuPosition = menuDefaultPosition,
  rootClass,
  paperClass,
  buttonLabel
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [id] = useState(_.uniqueId('icon-button-'));

  return (
    <>
      <IconButton
        id={id}
        aria-controls="icon-menu"
        aria-haspopup="true"
        aria-label={buttonLabel || 'Open burger menu'}
        onClick={handleClick}
        data-testid="icon-menu-button"
        aria-expanded={open ? 'true' : undefined}
      >
        {icon}
      </IconButton>
      <Menu
        id="icon-menu"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={menuPosition.anchorOrigin}
        transformOrigin={menuPosition.transformOrigin}
        data-testid="icon-menu"
        className={className}
        onClose={handleClose}
        onClick={handleClose}
        classes={{
          root: rootClass,
          paper: paperClass
        }}
        MenuListProps={{
          'aria-labelledby': `${id}`
        }}
      >
        {children}
      </Menu>
    </>
  );
};
