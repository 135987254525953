import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { changePassword } from 'actions/User/userActions';
import { Modal } from 'components/Modal';
import { PasswordWarningBanner } from 'components/PasswordWarningBanner';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';

import { ChangePasswordForm, FormData } from '../ChangePasswordForm';

import styles from './ChangePasswordModal.module.scss';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
}

export const ChangePasswordModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal
}) => {
  const { data } = useLoadAuthUserData();

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    void,
    ApiError,
    FormData
  >(
    (passwordData: FormData) =>
      changePassword(data!.id, {
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword
      }),
    {
      onSuccess: () => {
        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
        successMessage="Password was successfully updated"
      />
      <Modal
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        title="Set a new password"
      >
        <div className={styles['change-password-modal']}>
          <PasswordWarningBanner />
          <ChangePasswordForm
            isLoading={isLoading}
            onSubmit={mutateAsync}
            onCloseModal={onCloseModal}
          />
        </div>
      </Modal>
    </>
  );
};
