import React, { FC, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { getStateFromUrl } from 'utils/helpers/urlHelper';

export const SignOut: FC = () => {
  const auth = useAuth();
  const navigate = useHistory();
  useEffect(() => {
    if (!auth.isLoading) {
      const state = getStateFromUrl(navigate.location.search);
      auth.signinRedirect({ state });
    }
  }, [auth, navigate.location.search]);
  return <AbsoluteSpinner loading={auth.isLoading} />;
};
