import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { fetchTasks } from 'actions/Task/taskActions';
import { fetchUsers } from 'actions/User/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { queryKeys, TaskFilters } from 'enums/QueryKeys.enum';
import {
  ACCESS_TOKEN_EXPIRATION,
  DEFAULT_PAGE_LIMIT,
  MAX_PAGE_LIMIT
} from 'utils/constants';
import { LearnerTaskColumn } from 'views/People/TaskLibrary/LearnerTaskLibrary/columns';

interface Props {
  user: User;
  filters: TaskFilters;
}

type UseLearnerTasks = (
  props: Props
) => UseInfiniteQueryResult<LearnerTaskColumn[], ApiError>;

export const useLearnerTasks: UseLearnerTasks = ({ user, filters }) =>
  useInfiniteQuery<LearnerTaskColumn[], ApiError>(
    queryKeys.filteredTasks(filters),
    async ({ pageParam }): Promise<LearnerTaskColumn[]> => {
      const tasks: Task[] = await fetchTasks({
        sort: ['learnerLibraryIndex:ASC'],
        assignedToIdEq: user.id,
        organizationIdEq: user.organizationId,
        parentIdIsNull: true,
        parentIdEq: undefined,
        exclude: ['steps'],
        limit: DEFAULT_PAGE_LIMIT,
        offset: pageParam?.offset || 0
      });

      const folderIds = (tasks || []).reduce((acc: string[], task) => {
        if (task.isFolder) {
          acc.push(task.id);
        }

        return acc;
      }, []);

      const folderTasks = folderIds?.length
        ? await fetchTasks({
            sort: ['learnerLibraryIndex:ASC'],
            parentIdIn: folderIds,
            exclude: ['steps'],
            limit: MAX_PAGE_LIMIT
          })
        : null;

      const usersIds = [...tasks, ...(folderTasks || [])].map(
        (task) => task.createdById
      );

      const users: User[] = usersIds.length
        ? await fetchUsers({
            sort: ['firstName:ASC'],
            limit: DEFAULT_PAGE_LIMIT,
            idIn: [...new Set(usersIds)]
          })
        : [];

      const usersMap: Map<string, User> = users.reduce((acc, user) => {
        acc.set(user.id, user);

        return acc;
      }, new Map());

      const folderTasksMap = (folderTasks || []).reduce((acc, task) => {
        if (task.parentId) {
          const taskWithUser = {
            ...task,
            imageUrl: task.imageUrl,
            user: usersMap.get(task.createdById)
          };

          acc[task.parentId] = acc[task.parentId]?.length
            ? [...acc[task.parentId], taskWithUser]
            : [taskWithUser];
        }

        return acc;
      }, {});

      return tasks.map((task) => ({
        ...task,
        user: usersMap.get(task.createdById),
        imageUrl: task.imageUrl,
        isFolder: task.isFolder,
        tasks: folderTasksMap[task.id] || []
      }));
    },
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < DEFAULT_PAGE_LIMIT) {
          return undefined;
        }

        return {
          offset: allPages.flat().length
        };
      }
    }
  );
