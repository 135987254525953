import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthCallback } from 'components/AuthCallback/AuthCallback';
import { SignOut } from 'components/AuthCallback/SignOut';
import { SignoutCallback } from 'components/AuthCallback/SignoutCallback';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Routes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useActivityContext } from 'hooks/Auth/useActivityContext';
import { LocalStorage } from 'services/LocalStorage';
import {
  ACTIVITY_IDLE_TIME,
  ACTIVITY_PING_TIME,
  AUTH_TOKEN_KEY
} from 'utils/constants';
import { AccountPage } from 'views/Account/AccountPage';
// eslint-disable-next-line
import { LoginForm } from 'views/Auth/LoginForm';
import { LoginPage } from 'views/Auth/LoginPage';
import { CareRedirectPage } from 'views/Care/CareRedirectPage';
import { LibraryPage } from 'views/Library/LibraryPage/LibraryPage';
import { TasksProvider } from 'views/Library/TasksProvider';
import { CreateOrganizationPage } from 'views/Organizations/CreateOrganizationPage';
import { OrganizationDetailsPage } from 'views/Organizations/OrganizationDetailsPage';
import { OrganizationsPage } from 'views/Organizations/OrganizationsPage';
import {
  ErrorPage,
  FailedRoleLoginPage,
  InactiveUserPage
} from 'views/Problems';
import { LearnerReport } from 'views/Reports/LearnerReport';
import { ManagerReport } from 'views/Reports/ManagerReport';
import { SettingPage } from 'views/Settings/SettingPage';
import { TaskDetailsPage } from 'views/Task/TaskDetailsPage';

export const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { stopActivity, updateActivity, startActivity, refreshActivity } =
    useActivityContext();

  const onActive = () => {
    if (LocalStorage.getItem(AUTH_TOKEN_KEY)) {
      startActivity();
    }
  };

  useIdleTimer({
    crossTab: true,
    timeout: ACTIVITY_IDLE_TIME,
    throttle: ACTIVITY_PING_TIME,
    onActive,
    onAction: updateActivity,
    onIdle: () => stopActivity(false)
  });

  useEffect(() => {
    refreshActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isInitializing = false;
  const exact = true;

  return (
    <LoadingOverlay loading={isInitializing} unmountOnLoading>
      <Switch>
        <Route exact path={Routes.CareRedirect}>
          <CareRedirectPage />
        </Route>

        <Route exact={exact} path="/signin-oidc" component={AuthCallback} />
        <Route
          exact={exact}
          path="/signout-callback-oidc"
          component={SignOut}
        />

        <Route
          exact={exact}
          path={Routes.UserSignedOut}
          component={SignoutCallback}
        />

        <Route exact path={Routes.Root}>
          <Redirect to={Routes.Organizations} />
        </Route>

        <ProtectedRoute exact path={Routes.Login} forAuthenticated={false}>
          <LoginPage title="Login to your account">
            <LoginForm />
          </LoginPage>
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.Account}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          <AccountPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.Organizations}
          redirectPath={Routes.Login}
          scopes={[UserScopes.ViewOrganizations]}
        >
          <OrganizationsPage />
        </ProtectedRoute>

        <ProtectedRoute
          path={Routes.CreateOrganization}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          <CreateOrganizationPage />
        </ProtectedRoute>

        <ProtectedRoute
          path={Routes.LearnerReport}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          <LearnerReport />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.OrganizationReport}
          scopes={[UserScopes.ViewManagerReport]}
        >
          <ManagerReport />
        </ProtectedRoute>

        <ProtectedRoute
          path={Routes.OrganizationDetails}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          <OrganizationDetailsPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.Library}
          redirectPath={Routes.Login}
          scopes={[UserScopes.ViewAvailLibrary]}
        >
          <TasksProvider>
            <LibraryPage />
          </TasksProvider>
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.LibrarySettings}
          redirectPath={Routes.Login}
          scopes={[UserScopes.ViewAvailLibrary]}
        >
          <SettingPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.TaskDetails}
          redirectPath={Routes.Login}
          scopes={[UserScopes.ViewTask]}
        >
          <TaskDetailsPage />
        </ProtectedRoute>

        <Route path={Routes.ErrorPage}>
          <ErrorPage />
        </Route>

        <Route path={Routes.UserInactivePage}>
          <InactiveUserPage />
        </Route>

        <Route path={Routes.FailedRoleLoginPage}>
          <FailedRoleLoginPage />
        </Route>
      </Switch>
    </LoadingOverlay>
  );
};
