export const OIDC_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL || '',
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID || '',
  redirect_uri: process.env.REACT_APP_REDIRECT_URL || '',
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: true,
  scope: process.env.REACT_APP_SCOPE || '',
  monitorSession: true,
  post_logout_redirect_uri: process.env.REACT_APP_SIGNOUT_REDIRECT_URL || '',
  seconds_warning_before_timeout: parseInt(
    process.env.REACT_APP_SECONDS_WARNING_BEFORE_TIMEOUT || '60',
    10
  )
};
