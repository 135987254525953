import React, { FC } from 'react';
import { Grid } from '@mui/material';
import careCoordinatorProd from 'assets/cc-product-welcome.svg';
import compProdAssign from 'assets/comp-product-assign.svg';
import compProdJournal from 'assets/comp-product-journal.svg';
import compProdLesson from 'assets/comp-product-lesson.svg';
import logoCareCompanion from 'assets/logo-care-companion.png';
import logo from 'assets/logo-cr-care-coordinator-navy.svg';

import styles from './CareRedirectPage.module.scss';

export const CareRedirectPage: FC = () => (
  <Grid container alignItems="stretch" className={styles.page}>
    <Grid item className={styles.banner}>
      <h2>Caregiver Education & Engagement Just Got Simpler</h2>
      <h3>
        Step into the future of therapist/caregiver collaboration and
        engagement, ensuring superior outcomes for children receiving ABA
        therapy with game-changing features.
      </h3>
    </Grid>
    <Grid item className={styles.content}>
      <main className={styles.instructions}>
        <p className={styles.experience}>
          Ready to experience the new CR CareCoordinator and CR CareCompanion
          apps?
        </p>
        <div className={styles['get-started']}>
          <a href="/login" title="Redirects to CareCoordinator">
            GET STARTED
          </a>
        </div>
        <div className={styles.products}>
          <section>
            <div>
              <img
                src={logo}
                alt="CareCoordinator logo"
                className={styles.logo}
              />
            </div>
            <div className={styles['cc-prod']}>
              <img
                src={careCoordinatorProd}
                alt="CareCoordinator web portal and mobile app"
              />
            </div>
            <div className={styles.features}>
              <h4>For Staff:</h4>
              <ul>
                <li>New Web and Mobile Apps — CareCoordinator</li>
                <li>CentralReach Single Sign On (SSO)</li>
                <li>Viewing and Commenting on Caregiver Journal Entries</li>
                <li>Expanded Content and Lesson Management</li>
                <li>Additional Data Collection Rubrics in Mobile App</li>
              </ul>
            </div>
          </section>
          <section className={styles.plus}>
            <h4>+</h4>
          </section>
          <section>
            <div>
              <img
                src={logoCareCompanion}
                alt="CareCompanion logo"
                className={styles.logo}
              />
            </div>
            <div className={styles['comp-prod']}>
              <img
                src={compProdAssign}
                alt="CareCompanion mobile app assign lesson"
              />
              <img
                src={compProdJournal}
                alt="CareCompanion mobile app journal"
              />
              <img
                src={compProdLesson}
                alt="CareCompanion mobile app play lesson"
                className={styles['comp-prod-last']}
              />
            </div>
            <div className={styles.features}>
              <h4>For Caregivers:</h4>
              <ul>
                <li>New Mobile App Experience — CareCompanion</li>
                <li>Journal entries with quick start suggestions, check-ins</li>
                <li>Share Journal with Therapists</li>
                <li>Self-Serve Lesson Browsing</li>
                <li>Improved Lesson Experience</li>
                <li>In-App Notifications</li>
                <li>Home Page and Profile</li>
              </ul>
            </div>
          </section>
        </div>
      </main>
    </Grid>
  </Grid>
);
