import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchLearners } from 'actions/Learner/learnerActions';
import { updateTaskDetails } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { Task } from 'entities/Task.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { TasksContext } from 'hooks/Task/useTasksCache';
import { useTasksContext } from 'hooks/Task/useTasksContext';
import { queryClient } from 'queryClient';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

import { fetchPreassignedLessons } from '../../../actions/Modules/moduleActions';
import { AssignedLessonsModal } from '../AssignedLessonsModal/AssignedLessonsModal';

import styles from './AssignTaskModal.module.scss';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  tasks: Task[];
  onSuccess?: () => void;
}

export const AssignTaskModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal,
  tasks,
  onSuccess
}) => {
  const [preassignedLessons, setPreassignedLessons] = React.useState<
    { id: string; title: string }[]
  >([]);
  const [
    isCheckingForPreassignedLessonsError,
    setIsCheckingForPreassignedLessonsError
  ] = React.useState(false);
  const [isCheckingForPreassignedLessons, setIsCheckingForPreassignedLessons] =
    React.useState(false);
  const [isAssignedLessonsModalOpen, setIsAssignedLessonsModalOpen] =
    React.useState(false);

  const { data: organizationData } = useOrganizationBySlug();
  const { data: userData } = useLoadAuthUserData();
  const { activeFolder, onChangeActiveFolder } = useTasksContext();

  const [selectedLearner, setSelectedLearner] = useState<Learner | null>(null);

  const learnerOptions = {
    organizationIdEq: organizationData!.id,
    facilitatorIdsEq:
      userData?.role === UserRoles.Facilitator ? userData.id : undefined,
    statusIn: [EntityStatus.Active]
  };

  const firstTaskOrganizatinId = tasks[0].organizationId!;

  const { data: learnersData, isLoading: islearnersLoading } = useQuery(
    queryKeys.filteredLearners(firstTaskOrganizatinId, learnerOptions),
    () =>
      fetchLearners({
        ...learnerOptions,
        sort: ['firstName:ASC']
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0,
      enabled: !!organizationData?.id
    }
  );

  const { isLoading, isError, error, mutateAsync } = useMutation<
    Task,
    ApiError,
    Task['id'],
    TasksContext
  >(
    (taskId: Task['id']) =>
      updateTaskDetails(taskId, {
        assignedToId: selectedLearner?.id || null,
        parentId: null
      }),
    {
      onSuccess: async (updatedTask) => {
        if (activeFolder?.id === updatedTask.id) {
          onChangeActiveFolder(updatedTask);
        }

        await queryClient.setQueryData(
          queryKeys.taskDetails(updatedTask.id),
          updatedTask
        );
      }
    }
  );

  const checkForPreassignedLessons = async () => {
    const moduleIds = tasks
      .filter(({ type }) => type === 'group')
      .map(({ id }) => id);

    if (moduleIds.length > 0) {
      setIsCheckingForPreassignedLessonsError(false);
      setIsCheckingForPreassignedLessons(true);

      try {
        const preassignedLessonsRequests = moduleIds.map((id) =>
          fetchPreassignedLessons(id, selectedLearner?.id ?? '')
        );
        const results = await Promise.all(preassignedLessonsRequests);

        setIsCheckingForPreassignedLessons(false);
        const lessons = results
          // If the module is pre-assigned, the assignment itself will report it (as a module is still a lesson internally)
          .filter((r) => !r.isModulePreassigned)
          .map((r) => r.lessons)
          .flat();

        setPreassignedLessons(lessons);

        if (lessons.length > 0) {
          setIsAssignedLessonsModalOpen(true);

          return false;
        }

        return true;
      } catch (err) {
        setIsCheckingForPreassignedLessons(false);
        console.error(err);
        setIsCheckingForPreassignedLessonsError(true);

        return false;
      }
    }

    return true;
  };

  const handleAssignTasksList = async () => {
    const requests = tasks.map(({ id }) => mutateAsync(id));

    await Promise.all(requests);
    await queryClient.invalidateQueries(queryKeys.tasksList);
    await queryClient.invalidateQueries(queryKeys.folders);

    if (onSuccess) {
      onSuccess();
    }

    onCloseModal();
  };

  return (
    <>
      <StatusSnackBar
        isError={isError || isCheckingForPreassignedLessonsError}
        errorMessage={error?.errorMessage || ErrorMessages.FailedGetRequest}
      />
      <AssignedLessonsModal
        lessons={preassignedLessons}
        isOpen={isAssignedLessonsModalOpen}
        isLoading={isLoading}
        handleAssignTasksList={handleAssignTasksList}
        onCloseModal={() => {
          setIsAssignedLessonsModalOpen(false);
          onCloseModal();
        }}
      />
      <Modal
        title="Assign lesson to caregiver"
        isOpen={isOpen}
        onCloseModal={onCloseModal}
      >
        <div className={styles.container}>
          <Autocomplete
            id="choose-learner"
            aria-label="Choose caregiver"
            options={learnersData || []}
            getOptionLabel={(option) =>
              `${(option as Learner).firstName} ${(option as Learner).lastName}`
            }
            renderInput={(params) => (
              <TextField {...params} label="Choose caregiver" />
            )}
            onChange={(_e, value) => {
              if (typeof value !== 'string') {
                setSelectedLearner(value);
              }
            }}
            freeSolo
            open
            className={styles.autocomplete}
            classes={{
              paper: styles.paper,
              listbox: styles.listbox
            }}
          />
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  !selectedLearner ||
                  isLoading ||
                  isCheckingForPreassignedLessons
                }
                onClick={async () => {
                  const canAssignWithoutWarning =
                    await checkForPreassignedLessons();

                  if (canAssignWithoutWarning) {
                    await handleAssignTasksList();
                  }
                }}
                loading={
                  islearnersLoading ||
                  isLoading ||
                  isCheckingForPreassignedLessons
                }
              >
                Assign
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
