import { useEffect, useRef } from 'react';
import { Task } from 'entities/Task.entity';

/**
 * Load paginated lessons
 * @param lessons - List of lessons
 * @param onLoadMore - Callback to load more data
 * @return lastLoadedItemRef - This must reference the last item in the list, if it's visible it will try to fetch a new page
 */
export const useLoadMoreLessons = (
  lessons: Task[],
  onLoadMore?: () => void
) => {
  const lastLoadedItemRef = useRef(null);

  useEffect(
    () => {
      const observer = new IntersectionObserver((entries) => {
        // Is target visible? If so, load more data
        //
        // NOTE:
        // This triggers only once, if no data loads nothing will trigger a second call
        // aka last page if no more data
        if (entries[0].isIntersecting) {
          onLoadMore?.();
        }
      });

      const { current } = lastLoadedItemRef;

      if (current) {
        observer.observe(current);
      }

      return () => {
        if (current) {
          observer.unobserve(current);
        }
      };
    },
    // If more data is loaded, observerTarget will change to be the last item in the list
    // This will trigger the useEffect again
    [onLoadMore, lessons.length]
  );

  return lastLoadedItemRef;
};
