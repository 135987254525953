import React, { FC } from 'react';
import { differenceInCalendarDays, format } from 'date-fns';
import { Organization } from 'entities/Organization.entity';
import { User } from 'entities/User.entity';

import styles from './OrganizationSummarySection.module.scss';

interface Props {
  organization: Organization;
  organizationAdmin?: User;
}

export const OrganizationSummarySection: FC<React.PropsWithChildren<Props>> = ({
  organization,
  organizationAdmin
}) => {
  const renderSummaryRow = (title: string, value = '-') => (
    <div className={styles.row}>
      <h5 className={styles.title}>{title}</h5>
      <span className={styles.value}>{value}</span>
    </div>
  );

  return (
    <div className={styles['organization-summary-section']}>
      <div className={styles.container}>
        {renderSummaryRow('Report title', 'Managers Report')}
        {renderSummaryRow('Organization', organization.name)}
        {renderSummaryRow('Org Admin', organizationAdmin?.fullName)}
        {renderSummaryRow(
          'Date range',
          `from ${format(organization.createdAt, 'dd-MM-yyyy')} to ${format(
            new Date(),
            'dd-MM-yyyy'
          )}`
        )}
        {renderSummaryRow(
          'Total days in range',
          `${
            differenceInCalendarDays(new Date(), organization.createdAt) + 1
          } days`
        )}
      </div>
    </div>
  );
};
