import { EntityStatus } from 'enums/EntityStatus.enum';
import { object, serializable, serialize } from 'serializr';

import { AdminDTO } from './AdminDTO.entity';

export class OrganizationDTO {
  @serializable
  name = '';

  @serializable
  address = '';

  @serializable
  type = '';

  @serializable
  description?: string;

  @serializable
  managersAmountLimit = 0;

  @serializable
  learnersAmountLimit = 0;

  @serializable
  facilitatorsAmountLimit = 0;

  @serializable
  passiveFacilitatorsAmountLimit = 0;

  @serializable
  image?: string | null;

  @serializable
  status: EntityStatus = EntityStatus.Active;

  @serializable(object(AdminDTO))
  admin: AdminDTO = new AdminDTO();

  @serializable
  identityOrgId?: string | null;

  static serialize(json: Object | string): OrganizationDTO {
    return serialize(OrganizationDTO, json);
  }
}
