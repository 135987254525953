import React, { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import {
  generatePath,
  Link as RouterLink,
  useHistory,
  useParams
} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Avatar, Button, Chip, Grid, IconButton, Link } from '@mui/material';
import { deleteTasks } from 'actions/Task/taskActions';
import imagePlaceholder from 'assets/image-placeholder.png';
import { EmptyState } from 'components/EmptyState';
import { IconCell } from 'components/IconCell';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { useTaskData } from 'hooks/Task/useTaskData';
import { queryClient } from 'queryClient';
import { DeleteTaskModal } from 'views/Task/DeleteTaskModal';
import { EditTaskModal } from 'views/Task/EditTaskModal';
import { StepsList } from 'views/Task/StepsList';
import { TaskPreview } from 'views/Task/TaskPreview';

import styles from './UserTaskDetailsPage.module.scss';

export const UserTaskDetailsPage: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    slug,
    slugId,
    userId,
    id: taskId
  } = useParams<{
    userId: string;
    slugId: string;
    slug: string;
    id: Task['id'];
  }>();
  const history = useHistory();
  const { data: organizationData } = useOrganizationBySlug();

  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    task,
    isError,
    isLoading,
    createdBy,
    assignedTo,
    currentUser,
    repertoireName
  } = useTaskData();

  const onToggleDeleteTaskModal = useCallback(() => {
    setIsDeleteTaskModalOpen(
      (prevIsDeleteTaskModalOpen) => !prevIsDeleteTaskModalOpen
    );
  }, []);

  const onToggleEditTaskModal = useCallback(() => {
    setIsEditModalOpen((prevIsEditModalOpen) => !prevIsEditModalOpen);
  }, []);

  const userRoute = currentUser?.isLearner
    ? Routes.LearnerDetails
    : Routes.StaffDetails;

  const prevPageRoute = generatePath(
    userId ? userRoute : Routes.OrganizationLibrary,
    {
      slug,
      slugId,
      id: userId
    }
  );

  const {
    isError: isDeleteTaskError,
    mutateAsync: onDeleteTask,
    error: deleteTaskError
  } = useMutation<void, ApiError>(() => deleteTasks([task!.id]), {
    retry: 0,
    onSuccess: async () => {
      if (organizationData?.id && currentUser) {
        await queryClient.invalidateQueries(
          queryKeys.userAssessments(organizationData!.id, currentUser!.id)
        );
      }
      await queryClient.invalidateQueries(queryKeys.taskDetails(taskId));
      await queryClient.invalidateQueries(queryKeys.folders);
      await queryClient.invalidateQueries(queryKeys.tasksList);

      history.push(prevPageRoute);
    }
  });

  const onEditTask = () => {
    if (organizationData?.id && currentUser) {
      queryClient.invalidateQueries(
        queryKeys.userAssessments(organizationData!.id, currentUser!.id)
      );
    }
  };

  if (!task || (userId ? !currentUser : false) || !repertoireName) {
    return (
      <>
        <AbsoluteSpinner loading={isLoading} />
        <StatusSnackBar
          isError={isError}
          errorMessage={ErrorMessages.FailedGetRequest}
        />
        {!isLoading && <EmptyState />}
      </>
    );
  }

  const {
    id,
    type,
    title,
    image,
    imageUrl,
    parentId,
    stepsAmount,
    tasksAmount,
    description,
    assignedToId,
    createdById,
    repertoireId
  } = task;

  const folderQuery = {
    sort: ['title:ASC'],
    assignedToIdEq: assignedToId || undefined,
    createdByIdEq: userId && !assignedToId ? createdById : undefined,
    organizationIdEq: !userId ? organizationData!.id : undefined,
    assignedToIdIsNull: !userId ? !assignedToId : undefined,
    sharableEq: !userId ? false : undefined
  };

  return (
    <div className={styles['user-task-details-page']}>
      <StatusSnackBar
        isError={isDeleteTaskError}
        errorMessage={
          deleteTaskError?.errorMessage || ErrorMessages.FailedPostRequest
        }
      />
      <DeleteTaskModal
        data={{
          id,
          title,
          type,
          tasksAmount,
          assigneeName: assignedTo?.fullName
        }}
        onDeleteTask={onDeleteTask}
        isOpen={isDeleteTaskModalOpen}
        onCloseModal={onToggleDeleteTaskModal}
      />
      <EditTaskModal
        isOpen={isEditModalOpen}
        onEditTask={onEditTask}
        onCloseModal={onToggleEditTaskModal}
        taskQuery={folderQuery}
        data={{
          id,
          title,
          image,
          description,
          imageUrl,
          repertoireId,
          parentId
        }}
      />

      <div className={styles.navigation}>
        <IconButton
          color="secondary"
          aria-label="Back to user details"
          data-testid="back-button"
          className={styles.icon}
          onClick={() => history.push(prevPageRoute)}
        >
          <ArrowBackIcon />
        </IconButton>
        Back to&nbsp;
        <Link component={RouterLink} to={prevPageRoute} underline="hover">
          {userId ? currentUser!.fullName : 'library'}
        </Link>
      </div>

      <div className={styles.container}>
        <div className={styles.content}>
          <h3 className={styles['main-title']}>Lesson details</h3>
          <Grid container spacing={3}>
            <Grid item width="204px">
              <Avatar
                variant="square"
                className={styles.image}
                alt={`${title} lesson`}
                src={imageUrl || imagePlaceholder}
              />
            </Grid>
            <Grid item lg md sm={12}>
              <div className={styles['avatar-container']}>
                <span className={styles['avatar-title']}>Created by:</span>
                <IconCell
                  rounded
                  hasAvatarPlaceholder
                  imageURL={createdBy?.avatarUrl}
                  title={createdBy?.fullName || '-'}
                />
              </div>
              <h4 className={styles.title}>{title}</h4>
              <Chip
                size="small"
                label={repertoireName}
                className={styles['primary-chip']}
              />
              {description && (
                <p className={styles.description}>{description}</p>
              )}
              <div className={styles['avatar-container']}>
                <span className={styles['avatar-title']}>Assigned to:</span>
                <IconCell
                  rounded
                  hasAvatarPlaceholder
                  imageURL={assignedTo?.avatarUrl}
                  title={assignedTo?.fullName || 'Unassigned'}
                />
              </div>
              <p className={styles.description}>
                Steps <b>{stepsAmount}</b>
              </p>
              <div className={styles.actions}>
                <TaskPreview
                  taskId={id}
                  taskName={title}
                  stepsAmount={stepsAmount}
                  buttonWrapClassName={styles.button}
                />
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  className={styles.button}
                  startIcon={<ModeEditIcon />}
                  onClick={onToggleEditTaskModal}
                >
                  Edit details
                </Button>
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  className={styles['delete-button']}
                  onClick={onToggleDeleteTaskModal}
                >
                  Delete Lesson
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles['step-list']}>
          <StepsList taskId={id} />
        </div>
      </div>
    </div>
  );
};
