import React, { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, IconButton } from '@mui/material';
import openFolder from 'assets/open-folder.svg';
import { If } from 'components/If';

import styles from './TaskBackHeader.module.scss';

interface Props {
  title: string;
  isFolder?: boolean;
  handleBack?: () => void;
}

export const TaskBackHeader: FC<React.PropsWithChildren<Props>> = ({
  title,
  handleBack,
  isFolder
}) => (
  <div className={styles['task-back-header']}>
    <IconButton
      color="secondary"
      onClick={handleBack}
      aria-label="Back to root list"
      data-testid="back-button"
      className={styles.icon}
    >
      <ArrowBackIcon />
    </IconButton>
    <If condition={!!isFolder}>
      <Avatar
        variant="square"
        src={openFolder}
        alt="module image"
        className={styles['folder-icon']}
      />
    </If>
    <h3 className={styles.label} data-testid="step-header">
      {title}
    </h3>
  </div>
);
