import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';
import { createOrganization } from 'actions/Organization/organizationActions';
import { ConfirmationScreenTheme } from 'components/ConfirmationScreen';
import { ConfirmationScreen } from 'components/ConfirmationScreen/ConfirmationScreen';
import { Layout } from 'components/Layout';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Stepper } from 'components/Stepper';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { queryClient } from 'queryClient';
import { CreateOrganizationAdmin } from 'views/Organizations/CreateOrganizationAdmin/CreateOrganizationAdmin';
import { CreateOrganizationDetails } from 'views/Organizations/CreateOrganizationDetails';
import { CreateOrganizationLicenses } from 'views/Organizations/CreateOrganizationLicenses';

import styles from './CreateOrganizationPage.module.scss';

interface FormData {
  name: string;
  address: string;
  type: string;
  description?: string;
  identityOrgId?: string | null;
  managersAmountLimit: number;
  learnersAmountLimit: number;
  facilitatorsAmountLimit: number;
  passiveFacilitatorsAmountLimit: number;
  admin: {
    firstName: string;
    lastName: string;
    email: string;
  };
  image?: string | null;
}

export const CreateOrganizationPage: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [canMoveNext, setCanMoveNext] = useState(true);

  const methods = useForm<FormData>({
    defaultValues: {
      type: '',
      identityOrgId: ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const {
    isLoading,
    isError,
    error,
    mutateAsync,
    isSuccess,
    data: createdOrganization
  } = useMutation<Organization, ApiError, FormData>(
    (formData: FormData) =>
      createOrganization({
        name: formData.name,
        address: formData.address,
        type: formData.type,
        description: formData.description,
        identityOrgId: formData.identityOrgId || null,
        managersAmountLimit: formData.managersAmountLimit,
        learnersAmountLimit: formData.learnersAmountLimit,
        facilitatorsAmountLimit: formData.facilitatorsAmountLimit,
        passiveFacilitatorsAmountLimit: formData.passiveFacilitatorsAmountLimit,
        admin: formData.admin,
        image: formData.image,
        status: EntityStatus.Active
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.organizationsList);
      }
    }
  );

  const onBackToOrganizations = () => {
    history.push(Routes.Organizations);
  };

  const onViewOrganisation = () => {
    if (!createdOrganization) {
      return;
    }

    history.push(
      generatePath(Routes.OrganizationInformation, {
        slugId: createdOrganization!.slugId,
        slug: createdOrganization!.slug
      })
    );
  };

  const steps = [
    {
      label: 'Create new organization',
      component: (
        <CreateOrganizationDetails
          onIsLoading={(isLoading) => setCanMoveNext(!isLoading)}
        />
      )
    },
    {
      label: 'Manage licenses',
      component: <CreateOrganizationLicenses />
    },
    {
      label: 'Add administrator information',
      component: <CreateOrganizationAdmin />
    }
  ];

  return (
    <Layout>
      <StatusSnackBar
        isError={isError}
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <div className={styles['create-organization-page']}>
        {isSuccess ? (
          <ConfirmationScreen
            onSubmit={onViewOrganisation}
            onCancel={onBackToOrganizations}
            submitButtonTitle="View"
            cancelButtonTitle="Back to organizations"
            theme={ConfirmationScreenTheme.Primary}
            className={styles['confirmation-banner']}
          >
            <p className={styles['info-message']}>
              {createdOrganization?.name} organization has been created
              successfully.
            </p>
          </ConfirmationScreen>
        ) : (
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          <FormProvider {...methods}>
            <Stepper
              steps={steps}
              handleBack={handleBack}
              activeStep={activeStep}
              isLoading={isLoading}
              onSave={methods.handleSubmit((formData) => mutateAsync(formData))}
              onCancel={onBackToOrganizations}
              handleNext={methods.handleSubmit(() => {
                if (canMoveNext) {
                  handleNext();
                }
              })}
            />
          </FormProvider>
        )}
      </div>
    </Layout>
  );
};
