export enum Routes {
  Root = '/',
  Login = '/login',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Account = '/account',
  Organizations = '/organizations',
  Library = '/library',
  LibrarySettings = '/library-settings',
  TaskDetails = '/tasks/:id',
  CreateOrganization = '/organizations/create',
  OrganizationDetails = '/organizations/:slug/:slugId',
  OrganizationInformation = '/organizations/:slug/:slugId/info',
  OrganizationReport = '/organizations/:slug/:slugId/report',
  OrganizationPeople = '/organizations/:slug/:slugId/people',
  OrganizationTaskDetails = '/organizations/:slug/:slugId/library/tasks/:id',
  OrganizationStaff = '/organizations/:slug/:slugId/people/staff',
  OrganizationLearners = '/organizations/:slug/:slugId/people/learners',
  OrganizationLibrary = '/organizations/:slug/:slugId/library',
  LearnerDetails = '/organizations/:slug/:slugId/people/learners/:id',
  LearnerReport = '/organizations/:slug/:slugId/people/learners/:id/report',
  Journal = '/organizations/:slug/:slugId/people/learners/:userId/journal/:id',
  StaffDetails = '/organizations/:slug/:slugId/people/staff/:id',
  StaffTaskDetails = '/organizations/:slug/:slugId/people/staff/:userId/tasks/:id',
  LearnerTaskDetails = '/organizations/:slug/:slugId/people/learners/:userId/tasks/:id',
  ErrorPage = '/error',
  UserInactivePage = '/inactive',
  FailedRoleLoginPage = '/filed-role-login',
  UserSignedOut = '/signed-out',
  CareRedirect = '/welcome-to-care'
}

export const RoutesLabels = Object.freeze<Record<string, string>>({
  [Routes.Organizations]: 'Organizations',
  [Routes.Library]: 'CareCoordinator® Library'
});

const errorRoutes = [
  Routes.ErrorPage,
  Routes.UserInactivePage,
  Routes.FailedRoleLoginPage
];

export const isErrorRoute = (pathname: string) => {
  return errorRoutes.some((route) => pathname.includes(route));
};
