export enum UserScopes {
  AccessApp,
  ViewManagerReport,
  ViewOrganizations,
  ViewAvailLibrary,
  ViewTask,
  ViewStaffTab,
  EditLicenses,
  EditOrganizationInfo,
  RequestMoreLicenses,
  ChangeOrganizationAdmin,
  ArchiveUsers
}
