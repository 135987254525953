import React, { ChangeEvent, FC } from 'react';
import { CloseSharp, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { If } from 'components/If';
import { useSharedLibraryContext } from 'hooks/Task/useSharedLibraryContext';

import styles from './ModalHeader.module.scss';

interface Props {
  onCloseModal: () => void;
}

export const ModalHeader: FC<React.PropsWithChildren<Props>> = ({
  onCloseModal
}) => {
  const { search, activeTask, onChangeSearch } = useSharedLibraryContext();

  return (
    <div className={styles['modal-header']}>
      <h3 id="Avail library" className={styles.title} data-testid="modal-title">
        CareCoordinator® Library
      </h3>

      <If condition={!activeTask}>
        <TextField
          size="small"
          role="search"
          variant="outlined"
          label="Search"
          className={styles.search}
          id="outlined-shared-lib-search-input"
          data-testid="search"
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeSearch(e.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.icon} />
              </InputAdornment>
            )
          }}
          inputProps={{
            'aria-label': 'Search for lessons or templates'
          }}
        />
      </If>

      <IconButton
        data-testid="close-modal-button"
        aria-label="Close modal"
        className={styles['close-button']}
        onClick={onCloseModal}
      >
        <CloseSharp />
      </IconButton>
    </div>
  );
};
