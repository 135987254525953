export const getRedirectUrl = (path: string, search: string) => {
  const searchParams = new URLSearchParams(search);
  searchParams.delete('state');
  const qs = searchParams.toString();
  return `${path}${qs ? '?' : ''}${qs}`;
};

export const getStateFromUrl = (search: string) => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get('state') || '';
};
