import React, { FC } from 'react';
import { Avatar } from '@mui/material';
import { Step } from 'entities/Step.entity';
import {
  getStepContentIcon,
  getStepContentTypes
} from 'utils/helpers/stepHelpers';

import styles from './ReportStepCard.module.scss';

interface Props {
  step: Step;
}

export const ReportStepCard: FC<React.PropsWithChildren<Props>> = ({
  step
}) => {
  const { title, imageUrl, videoThumbnail } = step;

  const Icon = getStepContentIcon(step);

  return (
    <div className={styles['report-step-card']}>
      <div className={styles['title-container']}>
        <Icon className={styles['content-icon']} />
        <span className={styles.title}>{getStepContentTypes(step, ' + ')}</span>
      </div>
      {imageUrl || videoThumbnail ? (
        <Avatar
          variant="square"
          alt={`${title} step`}
          className={styles.image}
          src={imageUrl || videoThumbnail}
        />
      ) : (
        <div className={styles.icon} data-testid="step-icon">
          <Icon />
        </div>
      )}
    </div>
  );
};
