import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import { NavigationHeader } from 'components/NavigationHeader';
import { SSOKeepAlive } from 'components/SSOKeepAlive/SSOKeepAlive';
import { Routes } from 'enums/Routes.enum';
import { getRedirectUrl } from 'utils/helpers/urlHelper';

import { LoadingOverlay } from '../LoadingOverlay';

import styles from './Layout.module.scss';

interface Props {
  loading?: boolean;
}

export const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  loading = false
}) => {
  const auth = useAuth();
  const navigate = useHistory();
  useEffect(() => {
    auth.events.addUserSignedOut(() => {
      if (navigate.location.pathname === Routes.UserSignedOut) return;
      const state = getRedirectUrl(
        navigate.location.pathname,
        navigate.location.search
      );
      navigate.push(`${Routes.UserSignedOut}?state=${state}`);
    });
  }, [auth, navigate]);
  return (
    <>
      <LoadingOverlay loading={loading}>
        <div className={styles.layout}>
          <NavigationHeader />
          {children || 'Data is not loaded, sorry'}
        </div>
      </LoadingOverlay>
      {auth.isAuthenticated && <SSOKeepAlive />}
    </>
  );
};
