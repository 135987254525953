import React, { ReactElement, useCallback } from 'react';
import { format } from 'date-fns';
import { ActivityStatusLabel } from 'enums/ActivityStatus.enum';
import {
  formatSecondsToDays,
  formatSecondsToDuration
} from 'utils/helpers/timeHelpers';

import styles from './ExpandedActivityTableCell.module.scss';

interface Props<T> {
  activity: T;
  id: string;
  label?: string;
}

export const ExpandedActivityTableCell = <
  T extends {
    startTime: number | Date;
    durationSec: number;
    userLoggedOutTitle: string;
    taskStatus: string;
  }
>({
  id,
  activity,
  label
}: Props<T>): ReactElement<Props<T>> | null => {
  const buildExpandedCellValue = useCallback<
    (activity: T, id: string) => string
  >((activity, id) => {
    switch (id) {
      case 'month':
        return format(activity.startTime, 'd MMM yyyy, HH:mm:ss');
      case 'activitiesAmount':
        return (
          formatSecondsToDays(activity.durationSec) +
          formatSecondsToDuration(activity.durationSec)
        );
      case 'averageTimeSec':
        return (
          activity.userLoggedOutTitle ||
          ActivityStatusLabel[activity.taskStatus]
        );
      default:
        return '';
    }
  }, []);

  if (!label) {
    return null;
  }

  return (
    <div className={styles['expanded-activity-table-cell']}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>
        {buildExpandedCellValue(activity, id)}
      </span>
    </div>
  );
};
