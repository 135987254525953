import React, {
  FC,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import { useInfiniteQuery } from 'react-query';
import { Add } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { fetchLearners } from 'actions/Learner/learnerActions';
import { DataTable, DataTableHandle } from 'components/DataTable';
import { If } from 'components/If';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { TableActionBar } from 'components/TableActionsBar';
import { Learner } from 'entities/Learner.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { EntityStatus, entityStatusOptions } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useJournalSummaryNotification } from 'hooks/Notification/useNotification';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { queryClient } from 'queryClient';
import { useDebounce } from 'use-debounce';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';
import { canShowJournal } from 'utils/journalHelpers';
import { CreateLearnerModal } from 'views/People/CreateLearnerModal';
import { UserStatusBar } from 'views/People/UserStatusBar';

import { getColumns } from './columns';

import styles from './LearnersTable.module.scss';

interface Props {
  actionBarRef: MutableRefObject<HTMLDivElement | null>;
}

export const LearnersTable: FC<React.PropsWithChildren<Props>> = ({
  actionBarRef
}) => {
  const tableRef = useRef<DataTableHandle>(null);
  const { data: userData } = useLoadAuthUserData();

  const shouldShowJournal = canShowJournal(userData?.role as UserRoles);

  const { data: notifications } = useJournalSummaryNotification(
    userData?.id || '',
    shouldShowJournal
  );

  const caregiversWithUnreadJournals = useMemo(() => {
    return notifications?.caregiversIds ?? [];
  }, [notifications]);

  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 1000);

  const [status, setStatus] = useState<EntityStatus>(EntityStatus.All);
  const [selectedUsers, setSelectedUsers] = useState<Learner[]>([]);

  const { data: organizationData } = useOrganizationBySlug();

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isError,
    isRefetchError,
    isRefetching
  } = useInfiniteQuery(
    queryKeys.filteredLearners(organizationData!.id, {
      search: debouncedSearch,
      status
    }),
    ({ pageParam }) =>
      fetchLearners({
        sort: ['firstName:ASC'],
        statusEq: status === EntityStatus.All ? undefined : status,
        statusNe: EntityStatus.Archived,
        organizationIdEq: organizationData!.id,
        facilitatorIdsEq:
          userData?.role === UserRoles.Facilitator ? userData.id : undefined,
        limit: DEFAULT_PAGE_LIMIT,
        offset: pageParam?.offset || 0,
        search: debouncedSearch === '' ? undefined : debouncedSearch
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0,
      enabled: !!organizationData?.id,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < DEFAULT_PAGE_LIMIT) {
          return undefined;
        }

        return {
          offset: allPages.flat().length
        };
      }
    }
  );

  const isInitialLoading = useInitialLoading(isFetching);

  const [isCreateLearnerModalOpen, setIsCreateLearnerModalOpen] =
    useState(false);

  const onToggleCreateLearnerModal = useCallback(() => {
    setIsCreateLearnerModalOpen(
      (prevIsCreateLearnerModalOpen) => !prevIsCreateLearnerModalOpen
    );
  }, []);

  const onLearnersSelect = useCallback(
    (users: Learner[]) => setSelectedUsers(users),
    []
  );

  const onSelectionActionSuccess = useCallback(async () => {
    tableRef.current?.reset();
    await queryClient.invalidateQueries(
      queryKeys.learners(organizationData!.id)
    );

    setSelectedUsers([]);
  }, [organizationData]);

  const colDefs = useMemo(() => {
    return getColumns(
      caregiversWithUnreadJournals,
      userData?.id || '',
      userData?.role as UserRoles
    ).filter((col) => (col.id === 'journal' ? shouldShowJournal : true));
  }, [caregiversWithUnreadJournals, shouldShowJournal, userData]);

  return (
    <>
      <AbsoluteSpinner loading={isInitialLoading} />
      <StatusSnackBar
        isError={isError || isRefetchError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      {actionBarRef.current &&
        ReactDOM.createPortal(
          <TableActionBar
            searchValue={search}
            searchLabel="Search for Caregivers"
            onChangeSearchValue={setSearch}
            selectOptions={entityStatusOptions}
            onChangeSelectOption={(e) => {
              setStatus(e.target.value as EntityStatus);
            }}
          />,
          actionBarRef.current
        )}
      <CreateLearnerModal
        onCloseModal={onToggleCreateLearnerModal}
        isOpen={isCreateLearnerModalOpen}
      />
      <span className={styles.fabLink}>
        <Fab
          color="primary"
          variant="extended"
          className={styles.fab}
          onClick={onToggleCreateLearnerModal}
          aria-label="Add new caregiver"
        >
          <Add />
          Add new caregiver
        </Fab>
      </span>

      <If condition={!isInitialLoading}>
        <DataTable
          ref={tableRef}
          className={`${styles.table}, ${styles['cc-table']}`}
          columns={colDefs}
          data={data?.pages.flat() || []}
          hasNextPage={hasNextPage}
          isLoading={isFetching}
          onLoadMore={fetchNextPage}
          onRowSelect={onLearnersSelect}
          dndDisabled
          emptyMessage={
            debouncedSearch || status !== EntityStatus.All
              ? EmptyStateMessages.Search
              : undefined
          }
        />
      </If>
      <UserStatusBar
        selectedUsers={selectedUsers}
        isUpdating={isRefetching}
        onSuccess={onSelectionActionSuccess}
      />
    </>
  );
};
