import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal as MuiModal
} from '@mui/material';
import cn from 'classnames';

import styles from './JournalModal.module.scss';

interface Props {
  onCloseModal: () => void;
  isOpen: boolean;
  title?: string;
  modalClassName?: string;
}

export const JournalModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal,
  title,
  children,
  modalClassName
}) => (
  <MuiModal
    open={isOpen}
    onClose={onCloseModal}
    aria-labelledby="modal-title"
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}
  >
    <Fade in={isOpen}>
      <Box className={cn(styles.modal, modalClassName)}>
        <IconButton
          className={styles.customizedButton}
          size="small"
          onClick={onCloseModal}
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.content}>{children}</div>
      </Box>
    </Fade>
  </MuiModal>
);
