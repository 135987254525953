import React, { FC, useMemo } from 'react';
import deleteIcon from 'assets/delete.svg';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { Modal } from 'components/Modal';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  onJournalDelete: () => void;
}

export const JournalDeleteModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onJournalDelete,
  onCloseModal
}) => {
  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal}>
      <ConfirmationScreen
        icon={deleteIcon}
        onSubmit={onJournalDelete}
        onCancel={onCloseModal}
        cancelButtonTitle="Cancel"
        submitButtonTitle="Delete"
        theme={ConfirmationScreenTheme.Error}
      >
        <p>Are you sure you want to delete your comment?</p>
      </ConfirmationScreen>
    </Modal>
  );
};
