import React, { FC, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button } from '@mui/material';
import { fetchTaskSteps, moveTaskStep } from 'actions/Task/taskActions';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Step } from 'entities/Step.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'queryClient';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { CreateStepModal } from 'views/Task/CreateStepModal';
import { StepCard } from 'views/Task/StepCard';
import { StepsDnd } from 'views/Task/StepsDnd';

import styles from './StepsList.module.scss';

interface Props {
  taskId: Task['id'];
}

interface StepLists {
  activeSteps: Step[];
  inactiveSteps: Step[];
}

export const StepsList: FC<React.PropsWithChildren<Props>> = ({ taskId }) => {
  const [isCreateStepModalOpen, setIsCreateStepModalOpen] = useState(false);

  const { data, isError, isLoading } = useQuery<Step[], ApiError>(
    queryKeys.taskSteps(taskId),
    () => fetchTaskSteps(taskId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const onToggleCreateStepModal = useCallback(() => {
    setIsCreateStepModalOpen(
      (prevIsCreateStepModalOpen) => !prevIsCreateStepModalOpen
    );
  }, []);

  const onUpdateStep = useCallback(
    (updatedStep: Step) => {
      const updatedSteps = data?.map((step) =>
        step.id === updatedStep.id ? updatedStep : step
      );

      queryClient.setQueryData(queryKeys.taskSteps(taskId), updatedSteps);
      queryClient.invalidateQueries(queryKeys.taskDetails(taskId));
      queryClient.invalidateQueries(queryKeys.assessmentsList);
      queryClient.invalidateQueries(queryKeys.tasksList);
    },
    [data, taskId]
  );

  const { isError: isMoveTaskStepError, mutateAsync: onMoveTaskStep } =
    useMutation<Step[], ApiError, { id: string; moveIndex: number }>(
      ({ id, moveIndex }) => moveTaskStep(taskId, id, moveIndex),
      {
        onSuccess: (data) => {
          queryClient.setQueryData(queryKeys.taskSteps(taskId), data);
        }
      }
    );

  const { activeSteps, inactiveSteps }: StepLists = useMemo(() => {
    if (!data) {
      return { activeSteps: [], inactiveSteps: [] };
    }

    return data.reduce(
      (acc: StepLists, step) => {
        if (step.active) {
          acc.activeSteps.push(step);
        } else {
          acc.inactiveSteps.push(step);
        }

        return acc;
      },
      { activeSteps: [], inactiveSteps: [] }
    );
  }, [data]);

  const showEmptyScreen = useMemo(
    () => !data?.length && !isLoading,
    [data, isLoading]
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <StatusSnackBar
        isError={isMoveTaskStepError}
        errorMessage={ErrorMessages.FailedAction}
      />
      <CreateStepModal
        taskId={taskId}
        isOpen={isCreateStepModalOpen}
        onCloseModal={onToggleCreateStepModal}
      />

      <LoadingOverlay loading={isLoading}>
        <div className={styles['steps-list']}>
          <h3 className={styles.title}>Steps</h3>
          <Button
            color="primary"
            className={styles['add-button']}
            onClick={onToggleCreateStepModal}
          >
            Add step
          </Button>

          <If condition={!!activeSteps.length}>
            <StepsDnd
              steps={activeSteps}
              onUpdateStep={onUpdateStep}
              onMoveTaskStep={onMoveTaskStep}
            />
          </If>

          {inactiveSteps.map((step) => (
            <StepCard
              step={step}
              key={step.title}
              onUpdateStep={onUpdateStep}
            />
          ))}
          <If condition={showEmptyScreen}>
            <EmptyState title={EmptyStateMessages.Steps} />
          </If>
        </div>
      </LoadingOverlay>
    </>
  );
};
