import { ApiBase } from '../ApiBase';

export type UserNotification = {
  id: string;
  viewedAt: Date | null;
  type: 'newJournalComment' | 'journalEntryShared';
  createdAt: Date;
  journalComment: {
    id: string;
    authorId: string;
  };
  journalEntry: {
    id: string;
    title: string;
    ownerId: string;
  };
};

export type UnreadUserNotification = {
  notifications: UserNotification[];
};
class NotificationApi extends ApiBase {
  fetchUnreadNotifications() {
    return this.client.get<UnreadUserNotification>('/unread');
  }
}

const instance = new NotificationApi('/notifications');

export { instance as NotificationApi };
