import React, { FC } from 'react';

interface Props {
  condition: boolean;
}

export const If: FC<React.PropsWithChildren<Props>> = ({
  condition,
  children
}) => {
  if (condition) {
    return <>{children}</>;
  }

  return null;
};
