import React, { ReactElement } from 'react';
import { Column, UseExpandedRowProps, UseTableRowProps } from 'react-table';
import { Chip, LinearProgress } from '@mui/material';
import cn from 'classnames';
import { format } from 'date-fns';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { Routes } from 'enums/Routes.enum';
import { UserTableNavCell } from 'views/People/UserTableNavCell';

import styles from './StaffTaskLibrary.module.scss';

interface ExpandRow {
  row: UseExpandedRowProps<StaffTaskColumn> & UseTableRowProps<StaffTaskColumn>;
}
export type StaffTaskColumn = Task & { user?: User | null };

export const columns: Column<StaffTaskColumn>[] = [
  {
    id: 'task',
    Header: 'Lesson',
    Cell: ({ row }: ExpandRow): ReactElement => {
      const {
        depth,
        original: { title, imageUrl, id, tasksAmount, stepsAmount, isFolder }
      } = row;

      return (
        <div className={cn(depth && styles['sub-row-icon-bar'])}>
          <UserTableNavCell
            hasAvatarPlaceholder
            title={title}
            imageURL={imageUrl}
            params={{ id }}
            route={isFolder ? undefined : Routes.LearnerTaskDetails}
            task={{
              id,
              title,
              stepsAmount,
              isFolder,
              tasksAmount
            }}
          />
        </div>
      );
    },
    width: 3,
    minWidth: 200,
    maxWidth: 200
  },
  {
    id: 'user',
    Header: 'Assigned to',
    accessor: (task): StaffTaskColumn => task,
    Cell: ({ value }: { value: StaffTaskColumn }) =>
      value.user ? (
        <UserTableNavCell
          rounded
          title={value.user.fullName}
          imageURL={value.user.avatarUrl}
          params={{ id: value.user.id }}
          route={Routes.LearnerDetails}
        />
      ) : (
        <Chip size="small" label="Unassigned" className={styles.chip} />
      ),
    width: 3,
    minWidth: 150
  },
  {
    id: 'complitionPercentage',
    Header: 'Progress',
    accessor: (task): StaffTaskColumn => task,
    Cell: ({ value }: { value: StaffTaskColumn }) => (
      <span className={styles.progress}>
        <LinearProgress
          style={{ height: 7 }}
          className={styles['progress-bar']}
          key={value.id}
          variant="determinate"
          value={value.completionPercentage ?? 0}
        />
        <span>{value.completionPercentage}%</span>
      </span>
    ),
    width: 1,
    minWidth: 150
  },
  {
    id: 'firstCompletedDate',
    Header: 'Completed On',
    accessor: (row) =>
      row.firstCompletedDate
        ? format(row.firstCompletedDate, 'dd MMMM yyyy')
        : '',
    width: 1,
    minWidth: 100
  }
];
