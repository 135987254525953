import React, { FC } from 'react';
import infoIcon from 'assets/info.svg';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { Modal } from 'components/Modal';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  onSuccess: () => void;
}

export const CopyToSharedLibraryModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal,
  onSuccess
}) => {
  return (
    <Modal title="Agree to share" isOpen={isOpen} onCloseModal={onCloseModal}>
      <ConfirmationScreen
        icon={infoIcon}
        onCancel={onCloseModal}
        cancelButtonTitle="Cancel"
        submitButtonTitle="Confirm"
        theme={ConfirmationScreenTheme.Primary}
        onSubmit={() => {
          onSuccess();
          onCloseModal();
        }}
      >
        <p>
          I understand that all content in the shared library will be accessible
          to all users within my organization. <br />
          <br />I confirm that I wish to proceed.
        </p>
      </ConfirmationScreen>
    </Modal>
  );
};
