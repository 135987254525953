import { ApiError } from 'entities/ApiError.entity';
import { Comments } from 'entities/Comments.entity';
import { CommentsDTO } from 'entities/DTO/CommentsDTO.entity';
import { Journal } from 'entities/Journal.entity';
import { JournalApi } from 'services/API/Journal/JournalApi';

export const createJournalComments = async (
  journalId: Journal['id'],
  commentsDTO: CommentsDTO
): Promise<Comments> => {
  try {
    const response = await JournalApi.createJournalComments(
      journalId,
      commentsDTO
    );

    return Comments.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const deleteComments = async (
  journalId: Journal['id'],
  commentId: Comments['id'],
  deletedAt: string
): Promise<void> => {
  try {
    await JournalApi.deleteComments(journalId, commentId, deletedAt);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
