import React from 'react';
import { useMutation } from 'react-query';
import {
  overrideUserActivity as override,
  startUserActivity as start,
  stopUserActivity as stop,
  updateUserActivity as update
} from 'actions/UserActivities/userActivitiesActions';
import { differenceInMilliseconds, subMinutes } from 'date-fns';
import { ApiError } from 'entities/ApiError.entity';
import { UserActivity } from 'entities/UserActivity.entity';
import { ActivityContextProvider } from 'hooks/Auth/useActivityContext';
import { LocalStorage } from 'services/LocalStorage';
import {
  ACTIVITY_IDLE_TIME,
  AUTH_TOKEN_KEY,
  SESSION_TOKEN
} from 'utils/constants';

interface ActivityData {
  sessionToken: string;
  userLoggedOut?: boolean;
}

const IDLE_TIME_IN_MINUTES = 15;

export const ActivityProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const getUserSessionData = (): null | {
    sessionToken: string;
    latestActivity: Date;
  } => LocalStorage.getItem(SESSION_TOKEN);

  const { mutateAsync: stopActivity } = useMutation<
    UserActivity,
    ApiError,
    ActivityData
  >(
    ({ sessionToken, userLoggedOut }) => stop(sessionToken, { userLoggedOut }),
    {
      retry: 0,
      onSuccess: () => {
        LocalStorage.removeItem(SESSION_TOKEN);
      }
    }
  );

  const { mutateAsync: startActivity } = useMutation<UserActivity, ApiError>(
    () => start(),
    {
      retry: 0,
      onSuccess: (data) => {
        LocalStorage.setItem(SESSION_TOKEN, {
          sessionToken: data.sessionToken,
          latestActivity: Date.now()
        });
      }
    }
  );

  const { mutateAsync: updateActivity } = useMutation<
    UserActivity,
    ApiError,
    ActivityData
  >(({ sessionToken }) => update(sessionToken), {
    retry: 0,
    onSuccess: () => {
      const userSessionToken = getUserSessionData()?.sessionToken;

      if (userSessionToken) {
        LocalStorage.setItem(SESSION_TOKEN, {
          sessionToken: userSessionToken,
          latestActivity: Date.now()
        });
      }
    }
  });

  const { mutateAsync: overrideActivity } = useMutation<
    UserActivity,
    ApiError,
    { sessionToken: string; endTime: Date; userLoggedOut: boolean }
  >(
    ({ sessionToken, endTime, userLoggedOut }) =>
      override(sessionToken, {
        userLoggedOut,
        endTime: new Date(endTime).toISOString()
      }),
    {
      retry: 0,
      onSuccess: async (data) => {
        await LocalStorage.removeItem(SESSION_TOKEN);

        if (!data.userLoggedOut) {
          await startActivity();
        }
      }
    }
  );

  const onActivityAction = async (
    action: ({ sessionToken, userLoggedOut }: ActivityData) => void,
    userLoggedOut?: boolean
  ) => {
    const userSessionToken = getUserSessionData()?.sessionToken;

    if (userSessionToken) {
      await action({ sessionToken: userSessionToken, userLoggedOut });
    }
  };

  const refreshActivity = () => {
    if (LocalStorage.getItem(AUTH_TOKEN_KEY)) {
      const userSessionData = getUserSessionData();

      if (!userSessionData) {
        startActivity();
      } else {
        const { latestActivity, sessionToken } = userSessionData;
        const milliseconds = differenceInMilliseconds(
          Date.now(),
          latestActivity
        );

        if (milliseconds > ACTIVITY_IDLE_TIME) {
          overrideActivity({
            endTime: latestActivity,
            userLoggedOut: false,
            sessionToken
          });
        }
      }
    }
  };

  const onOverrideActivity = () => {
    const userSessionData = getUserSessionData();

    if (userSessionData) {
      const { latestActivity, sessionToken } = userSessionData;

      overrideActivity({
        endTime: latestActivity,
        userLoggedOut: true,
        sessionToken
      });
    }
  };

  const onStopActivity = (userLoggedOut: boolean) => {
    const userSessionData = getUserSessionData();

    if (userLoggedOut) {
      onActivityAction(stopActivity, userLoggedOut);
    } else if (userSessionData) {
      overrideActivity({
        userLoggedOut: false,
        endTime: subMinutes(Date.now(), IDLE_TIME_IN_MINUTES),
        sessionToken: userSessionData.sessionToken
      });
    }
  };

  return (
    <ActivityContextProvider
      value={{
        startActivity,
        refreshActivity,
        overrideActivity: onOverrideActivity,
        updateActivity: () => onActivityAction(updateActivity),
        stopActivity: (userLoggedOut: boolean) => onStopActivity(userLoggedOut)
      }}
    >
      {children}
    </ActivityContextProvider>
  );
};
