import { JournalEntryStatus } from 'enums/JournalEntryStatus.enum';
import {
  date,
  deserialize,
  list,
  object,
  primitive,
  serializable
} from 'serializr';

import { Comments } from './Comments.entity';
import { Content } from './Content.entity';
import { FileDTO } from './DTO/FileDTO.entity';
import { JournalTemplateDTO } from './DTO/JournalTemplateDTO.entity';

export class Journal {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt?: Date = new Date();

  @serializable(date())
  deletedAt?: Date = new Date();

  @serializable
  ownerId = '';

  @serializable(object(Content))
  content?: Content = new Content();

  @serializable(list(primitive()))
  sharedWith?: string[] = [];

  @serializable(date())
  sharedOn: Date = new Date();

  @serializable(list(object(FileDTO)))
  files?: FileDTO[] | [];

  @serializable(list(object(Comments)))
  comments?: Comments[] | null;

  @serializable(list(primitive()))
  tags?: string[] | null;

  @serializable
  status: JournalEntryStatus = JournalEntryStatus.Draft;

  @serializable
  userFullName?: string;

  @serializable
  commentCount?: number;

  @serializable(object(JournalTemplateDTO))
  journalTemplate?: JournalTemplateDTO;

  static deserialize(json: Object | string): Journal {
    return deserialize(Journal, json);
  }

  static deserializeAsList(list: Journal[]): Journal[] {
    return list.map(Journal.deserialize);
  }
}
