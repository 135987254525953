import React, {
  Dispatch,
  ForwardedRef,
  forwardRef,
  LegacyRef,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  OnDragStartResponder
} from 'react-beautiful-dnd';
import {
  Column,
  Row as TableRow,
  useExpanded,
  useFlexLayout,
  useRowSelect,
  useTable
} from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import cn from 'classnames';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import {
  DndDroppableContainerIds,
  TableDnDType
} from 'enums/TableDnDType.enum';
import { useTasksContext } from 'hooks/Task/useTasksContext';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { reorderData } from 'utils/helpers/listsHelpers';
import { prepareOnMoveRowActions } from 'utils/helpers/prepareOnMoveRowActions';

import { ListElementType } from './ListElementType';
import { Data, OnMoveActionProps, Row, SubRows } from './Row';
import { TableLoadingRow } from './TableLoadingRow';

import styles from './DataTable.module.scss';

const ROW_HEIGHT = 76;
const HEADER_HEIGHT = 40;
const DEFAULT_THRESHOLD = 10;

interface Props<T extends Data> {
  columns: Column<T>[];
  data: T[];
  dndDisabled?: boolean;
  reorderRows?: typeof reorderData;
  hasNextPage?: boolean;
  isLoading?: boolean;
  onLoadMore?: () => void;
  className?: string;
  emptyMessage?: string;
  onRowSelect?: (selections: T[]) => void;
  renderSubRows?: (props: SubRows) => void;
  getRowSize?: GetItemSizeCallback;
  onCustomDragEnd?: (value: OnCustomDraEndValue<T>) => OnDragEnd;
  isCombineEnabled?: boolean;
  isPresentDragDropContext?: boolean;
  cancelDndStyles?: boolean;
  onMoveRow?: (
    value: Pick<OnCustomDraEndValue<T>, 'records' | 'setRecords'>
  ) => (value: OnMoveActionProps) => void;
}

interface OnCustomDraEndValue<T> {
  records: T[];
  setRecords: Dispatch<SetStateAction<T[]>>;
  updateListItemSize: () => void;
}

type OnDragEnd = (result: DropResult) => void;
type GetItemSize = (index: number) => number;
export type GetItemSizeCallback = (value: {
  expandedRows: string[];
  rows: TableRow[];
  ROW_HEIGHT: number;
}) => GetItemSize;

export interface DataTableHandle {
  reset: () => void;
}

const emptyFunction = () => {};

export const DataTableInner = <T extends Data>(
  {
    columns,
    data,
    dndDisabled,
    reorderRows = reorderData,
    hasNextPage = false,
    isLoading = false,
    onLoadMore = emptyFunction,
    className,
    emptyMessage,
    onRowSelect,
    renderSubRows,
    getRowSize,
    onCustomDragEnd,
    onMoveRow,
    isCombineEnabled = false,
    isPresentDragDropContext = true,
    cancelDndStyles = false
  }: Props<T>,
  ref: ForwardedRef<DataTableHandle>
): ReactElement<Props<T>> => {
  const [records, setRecords] = useState<T[]>(data);
  const [isCombineRow, setIsCombineRow] = useState<boolean>(false);
  const listRef = useRef<LegacyRef<VariableSizeList>>();

  const { activeFolder } = useTasksContext();

  useEffect(() => {
    setRecords(data);
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleAllRowsExpanded,
    state
  } = useTable(
    {
      columns,
      data: records,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      getRowId: ({ id }) => id
    },
    useFlexLayout,
    useExpanded,
    useRowSelect
  );

  const expandedRows = useMemo(
    () => Object.keys(state.expanded || {}),
    [state.expanded]
  );

  const updateListItemSize = () => {
    (listRef.current as unknown as VariableSizeList).resetAfterIndex(0);
  };

  useEffect(() => {
    if (listRef.current) {
      updateListItemSize();
    }
  }, [expandedRows, listRef, records]);

  const selectedItems = useMemo(
    () => selectedFlatRows.map((row) => row.original),
    [selectedFlatRows]
  );

  useDeepCompareEffect(() => {
    onRowSelect?.(selectedItems);
  }, [selectedItems]);

  useImperativeHandle(ref, () => ({
    reset: () => {
      toggleAllRowsSelected(false);
      toggleAllRowsExpanded(false);
    }
  }));

  const onMove = useMemo(() => {
    return onMoveRow && onMoveRow({ records, setRecords });
  }, [onMoveRow, records]);

  const onDragStart = useCallback(
    (({ type, draggableId }) => {
      if (type === TableDnDType.Default) {
        setIsCombineRow(draggableId?.startsWith('row'));
      }
    }) as OnDragStartResponder,
    []
  );

  const onDefaultDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      if (result.source.index === result.destination.index) {
        return;
      }

      const newRecords = reorderRows<T>(
        records,
        result.source.index,
        result.destination.index
      );
      setRecords(newRecords);
      updateListItemSize();
    },
    // eslint-disable-next-line
    [records, reorderRows]
  );

  const onDragEnd = useMemo(() => {
    return onCustomDragEnd
      ? onCustomDragEnd({
          records,
          setRecords,
          updateListItemSize: () => {
            setIsCombineRow(false);
            updateListItemSize();
          }
        })
      : onDefaultDragEnd;
  }, [onCustomDragEnd, onDefaultDragEnd, records]);

  const renderRow = useCallback(
    ({
      index,
      style,
      data
    }: {
      index: number;
      style: React.CSSProperties;
      data: React.CSSProperties;
    }) => {
      const row = rows[index];

      if (!row) {
        return <TableLoadingRow />;
      }

      prepareRow(row);

      const { onMoveUp, onMoveDown } = prepareOnMoveRowActions({
        rowId: row.id,
        index,
        onMove,
        recordsLength: records.length
      });

      return (
        <Draggable
          draggableId={`${row.original?.isFolder ? 'row-' : ''}${row.id}`}
          index={index}
          key={row.id}
          isDragDisabled={
            dndDisabled || (row.isExpanded && !!row.original.tasksAmount)
          }
          disableInteractiveElementBlocking={dndDisabled}
        >
          {(provided, snapshot) => {
            return (
              <Row
                provided={provided}
                item={row}
                userRowProps={{
                  onMoveUp,
                  onMoveDown
                }}
                cancelDndStyles={cancelDndStyles}
                onMove={onMove}
                isCombiningHover={
                  !!snapshot.combineTargetFor && isCombineEnabled
                }
                style={{ ...style, width: data.width }}
                renderSubRows={renderSubRows}
              />
            );
          }}
        </Draggable>
      );
    },
    [
      cancelDndStyles,
      dndDisabled,
      isCombineEnabled,
      onMove,
      prepareRow,
      records.length,
      renderSubRows,
      rows
    ]
  );

  const getItemSize = useMemo(() => {
    if (getRowSize) {
      return getRowSize({
        expandedRows,
        rows: rows as unknown as TableRow[],
        ROW_HEIGHT
      });
    }

    return () => ROW_HEIGHT;
  }, [expandedRows, getRowSize, rows]);

  const checkItemLoaded = useCallback<(index: number) => boolean>(
    (index) => !hasNextPage || index < rows.length,
    [hasNextPage, rows.length]
  );

  const rowsCount = hasNextPage ? rows.length + 1 : rows.length;

  const rowWidth = totalColumnsWidth + columns.length * 26;

  return (
    <div className={cn(styles.container, className)} data-testid="data-table">
      <div className={styles.wrapper}>
        <LoadingOverlay loading={isLoading && !!activeFolder}>
          <If condition={!rows.length && isPresentDragDropContext}>
            <EmptyState title={emptyMessage} />
          </If>
          <If condition={!!rows.length || !isPresentDragDropContext}>
            <ConditionalWrapper
              condition={isPresentDragDropContext}
              wrapper={(children) => (
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onDragStart={onDragStart}
                >
                  <>{children}</>
                </DragDropContext>
              )}
            >
              <div {...getTableProps()} className={styles.table}>
                <div>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className={cn(styles.tr, styles.header)}
                    >
                      {headerGroup.headers.map((column) => (
                        // eslint-disable-next-line react/jsx-key
                        <div
                          {...column.getHeaderProps()}
                          className={styles.th}
                          data-testid="th"
                        >
                          {column.render('Header')}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <Droppable
                  droppableId={DndDroppableContainerIds.DataTable}
                  mode="virtual"
                  type={TableDnDType.Default}
                  isCombineEnabled={isCombineEnabled && !isCombineRow}
                  isDropDisabled={dndDisabled}
                  renderClone={(provided, snapshot, rubric) => {
                    const row = rows[rubric.source.index];
                    prepareRow(row);

                    const isCopyDragging =
                      snapshot.draggingOver ===
                      DndDroppableContainerIds.SharedLibrary;

                    return (
                      <div className={styles['drag-container']}>
                        <Row
                          provided={provided}
                          isCombining={snapshot.combineWith?.startsWith('row')}
                          isDragging={snapshot.isDragging}
                          isCopyDragging={isCopyDragging}
                          item={row}
                        />
                      </div>
                    );
                  }}
                >
                  {(droppableProvided) => (
                    <div
                      {...getTableBodyProps({
                        style: { height: `calc(100% - ${HEADER_HEIGHT}px)` }
                      })}
                    >
                      <If condition={!rowsCount && !isPresentDragDropContext}>
                        <div className={styles['empty-state']}>
                          <EmptyState title={emptyMessage} />
                        </div>
                      </If>

                      <AutoSizer>
                        {({ height, width }) => (
                          <InfiniteLoader
                            isItemLoaded={checkItemLoaded}
                            itemCount={rowsCount}
                            loadMoreItems={
                              isLoading ? emptyFunction : onLoadMore
                            }
                            threshold={DEFAULT_THRESHOLD}
                          >
                            {({ onItemsRendered, ref: infiniteLoaderRef }) => (
                              <VariableSizeList
                                height={height}
                                style={{
                                  willChange: 'auto',
                                  overflowX: 'hidden'
                                }}
                                itemCount={rowsCount}
                                onItemsRendered={onItemsRendered}
                                itemSize={getItemSize}
                                estimatedItemSize={ROW_HEIGHT}
                                ref={(ref) => {
                                  listRef.current =
                                    ref as unknown as LegacyRef<VariableSizeList>;

                                  if (infiniteLoaderRef) {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    infiniteLoaderRef(ref);
                                  }
                                }}
                                outerRef={droppableProvided.innerRef}
                                innerElementType={ListElementType}
                                width={width > rowWidth ? width : rowWidth}
                                itemData={{ width }}
                              >
                                {renderRow}
                              </VariableSizeList>
                            )}
                          </InfiniteLoader>
                        )}
                      </AutoSizer>
                    </div>
                  )}
                </Droppable>
              </div>
            </ConditionalWrapper>
          </If>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export const DataTable = forwardRef(DataTableInner) as <T extends Data>(
  props: Props<T> & { ref?: React.ForwardedRef<DataTableHandle> }
) => ReturnType<typeof DataTableInner>;
