import React, { FC, SyntheticEvent, useRef, useState } from 'react';
import {
  generatePath,
  Redirect,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { TabContext, TabList } from '@mui/lab';
import { Grid, Tab } from '@mui/material';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Routes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useHasPermission } from 'services/Permissions';

import { LearnersTable } from '../LearnersTable';
import { StaffTable } from '../StaffTable';

import styles from './PeoplePage.module.scss';

export const PeoplePage: FC<React.PropsWithChildren<unknown>> = () => {
  const { slugId, slug } = useParams<{ slugId: string; slug: string }>();

  const history = useHistory();

  const routeMatch = useRouteMatch([
    Routes.OrganizationLearners,
    Routes.OrganizationStaff
  ]);

  const [activeTab, setActiveTab] = useState(
    routeMatch?.path || Routes.OrganizationLearners
  );
  const actionBarRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (_e: SyntheticEvent, newValue: Routes) => {
    history.push(
      generatePath(newValue, {
        slugId,
        slug
      })
    );

    setActiveTab(newValue);
  };

  const canOpenStaff = useHasPermission([UserScopes.ViewStaffTab]);

  return (
    <div className={styles.container}>
      <TabContext value={activeTab}>
        <Grid
          container
          alignItems="center"
          className={styles.filters}
          justifyContent="space-between"
        >
          <Grid item xs={12} md={4}>
            <TabList
              onChange={handleChange}
              aria-label="Organization people tabs"
            >
              {canOpenStaff && (
                <Tab label="Staff" value={Routes.OrganizationStaff} />
              )}
              <Tab label="Caregivers" value={Routes.OrganizationLearners} />
            </TabList>
          </Grid>
          <Grid item xs={12} md={7} lg={5} ref={actionBarRef} />
        </Grid>
      </TabContext>
      <Switch>
        <ProtectedRoute exact path={Routes.OrganizationLearners}>
          <LearnersTable actionBarRef={actionBarRef} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Routes.OrganizationStaff}
          scopes={[UserScopes.ViewStaffTab]}
          redirectPath={
            generatePath(Routes.OrganizationLearners, {
              slugId,
              slug
            }) as Routes
          }
        >
          <StaffTable actionBarRef={actionBarRef} />
        </ProtectedRoute>
        <Redirect
          to={generatePath(Routes.OrganizationLearners, {
            slugId,
            slug
          })}
          from={Routes.OrganizationPeople}
        />
      </Switch>
    </div>
  );
};
