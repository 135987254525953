import React, { FC } from 'react';
import { Grid } from '@mui/material';
import cn from 'classnames';

import styles from './InfoRow.module.scss';

interface Props {
  label: string;
  className?: string;
}

export const InfoRow: FC<React.PropsWithChildren<Props>> = ({
  children,
  label,
  className
}) => (
  <Grid container className={cn(styles['info-row'], className)}>
    <Grid item xs={3} className={styles.label}>
      {label}
    </Grid>
    <Grid item xs={9} className={styles.value}>
      {children}
    </Grid>
  </Grid>
);
