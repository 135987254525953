import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
  generatePath,
  Link as RouterLink,
  useHistory,
  useParams
} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Link,
  List
} from '@mui/material';
import { deleteComments } from 'actions/Journal/JournalActions';
import { JournalModal } from 'components/JournalModal';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { format } from 'date-fns';
import { ApiError } from 'entities/ApiError.entity';
import { FileDTO } from 'entities/DTO/FileDTO.entity';
import { Journal } from 'entities/Journal.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { JournalTemplateType } from 'enums/JournalTemplateType.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { UserTabs } from 'enums/UserTabs.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useNotification } from 'hooks/Notification/useNotification';
import {
  useErrorRedirectToPeople,
  useUnauthorizedRedirectToPeople
} from 'hooks/useHandleErrorRedirect';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { queryClient } from 'queryClient';
import { JournalApi } from 'services/API/Journal/JournalApi';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

import { JournalCommentList } from '../JournalCommentList';
import { JournalDetailsCommentForm } from '../JournalDetailsCommentForm/JournalDetailsCommentForm';
import { JournalCheckIn } from './JournalCheckIn';

import styles from './JournalDetails.module.scss';

const fetch = async (
  caregiverId: string,
  journalId: string
): Promise<Journal> => {
  try {
    const response = await JournalApi.fetchSingleJournal(
      caregiverId,
      journalId
    );
    const ret = Journal.deserialize(response.data);
    return ret;
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export interface DeleteData {
  journalId: string;
  commentId: string;
}

export const JournalDetails: FC<React.PropsWithChildren<unknown>> = () => {
  const { slug, slugId, userId, id } = useParams<{
    userId: string;
    slugId: string;
    slug: string;
    id: string;
  }>();
  const history = useHistory();

  const [displayComments, setDisplayComments] = useState(true);

  const { data: authUserData } = useLoadAuthUserData();
  const staffUserId = authUserData?.id ?? '';

  const { refetch } = useNotification(staffUserId, true);

  const {
    isError,
    error,
    isFetching,
    data,
    refetch: refreshJournal
  } = useQuery<Journal, ApiError>(
    queryKeys.userJournalDetails(userId, id),
    () => fetch(userId, id),
    {
      retry: 0,
      refetchInterval: 30000,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      onSuccess() {
        queryClient.invalidateQueries(queryKeys.learnerJournals(userId));
        queryClient.invalidateQueries(queryKeys.userNotifications(staffUserId));
        setDisplayComments(true);
        refetch();
      }
    }
  );

  useErrorRedirectToPeople(
    () => isError && error?.errorName === 'journal.notFound'
  );
  useUnauthorizedRedirectToPeople(error);

  useEffect(() => {
    setDisplayComments(false);
    refreshJournal();
  }, [id, refreshJournal]);

  const {
    control,
    handleSubmit,
    getValues: getFormValues,
    formState: { errors, isDirty }
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const [journal, setJournal] = useState<Journal>();
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<FileDTO>();
  const mediaRef = useRef<HTMLVideoElement>(null);

  const formatNullableDate = (dateData?: Date, dateFormat = 'MM/dd/yyyy') => {
    if (!dateData) {
      return '';
    }
    return format(dateData, dateFormat);
  };

  const openModal = (fileDTO: FileDTO) => {
    if (!fileDTO || !fileDTO.type || !fileDTO.fullUrl) return;
    setCurrentRecord(fileDTO);
    setIsMediaModalOpen(true);
  };

  const closeModal = () => {
    setIsMediaModalOpen(false);
  };

  const prevPageRoute = () => {
    const path = generatePath(Routes.LearnerDetails, {
      slug,
      slugId,
      id: userId
    });
    const qs = new URLSearchParams();
    qs.append('tab', UserTabs.Journal);
    return `${path}?${qs.toString()}`;
  };

  const isInitialLoading = useInitialLoading(isFetching);

  const hasComments = (data?: Journal): boolean => {
    if (
      typeof data !== 'undefined' &&
      data!.comments !== null &&
      typeof data!.comments !== 'undefined' &&
      data!.comments.length !== null &&
      data!.comments.length > 0
    ) {
      return true;
    }
    return false;
  };

  const { mutateAsync: mutateOnDelete } = useMutation<
    void,
    ApiError,
    DeleteData
  >(
    ({ journalId, commentId }) =>
      deleteComments(journalId, commentId, new Date().toJSON()),
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeys.userJournalDetails(userId, data!.id)
        );
      }
    }
  );

  const handleDelete = (journalId: string, commentId: string) => {
    mutateOnDelete({ journalId, commentId });
  };

  useEffect(() => {
    setJournal(data);
  }, [data]);

  return (
    <div>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <JournalModal isOpen={isMediaModalOpen} onCloseModal={closeModal}>
        {currentRecord?.type === 'image' ? (
          <img
            src={currentRecord.fullUrl}
            alt="test"
            loading="lazy"
            className={styles['journal-image']}
          />
        ) : (
          <video
            ref={mediaRef}
            autoPlay
            controls
            className={styles['journal-video']}
            data-testid="preview-video"
          >
            <source src={currentRecord?.fullUrl} />
            <track kind="captions" />
          </video>
        )}
      </JournalModal>
      <main>
        <div>
          <div className={styles.navigation}>
            <IconButton
              color="secondary"
              aria-label="Back to library"
              data-testid="back-button"
              className={styles.icon}
              onClick={() => history.push(prevPageRoute())}
            >
              <ArrowBackIcon />
            </IconButton>
            Back to&nbsp;
            <Link component={RouterLink} to={prevPageRoute()} underline="hover">
              {data?.userFullName}
            </Link>
          </div>
          <AbsoluteSpinner loading={isInitialLoading || !displayComments} />
          {!isInitialLoading && (
            <div className={styles['journal-details']}>
              <div className={styles['journal-pane']}>
                <div className={styles['journal-title-area']}>
                  <span className={styles['journal-title-heading']}>
                    Journal Entry:
                  </span>
                  <span className={styles['journal-title']}>
                    {data?.content?.title}
                  </span>
                </div>
                <div className={styles['date-area']}>
                  <span className={styles['date-title']}>Shared on:</span>
                  <span className={styles['date-value']}>
                    {data?.sharedOn &&
                      data?.sharedOn.toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      })}
                  </span>
                  <span className={styles['date-bar']}>|</span>
                  <span className={styles['date-title']}>Last Updated:</span>
                  <span className={styles['date-value']}>
                    {data?.updatedAt &&
                      data?.updatedAt.toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      })}
                  </span>
                </div>
                <div>
                  {data?.journalTemplate?.type ===
                    JournalTemplateType.Checkin &&
                    data?.journalTemplate?.checkin?.map((checkin) => (
                      <JournalCheckIn
                        key={checkin.prompt}
                        prompt={checkin.prompt}
                        responses={checkin.responses}
                      />
                    ))}
                </div>
                <div>
                  {data?.journalTemplate?.type ===
                    JournalTemplateType.Suggestion && (
                    <div>
                      <div className={styles['journal-suggestion-title']}>
                        Journal Suggestion Prompt:
                      </div>
                      <div className={styles['journal-suggestion-text']}>
                        {data?.journalTemplate?.description}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles['journal-description']}>
                  <p>{data?.content?.body}</p>
                </div>
                <div className={styles['journal-thumbnails']}>
                  {data?.content?.files &&
                    data?.content?.files[0] &&
                    data?.content?.files.map((file) => (
                      <div key={file.key} className="journal-media">
                        <span key={file.id}>
                          <Card
                            sx={{ maxWidth: 250, margin: 1 }}
                            variant="outlined"
                          >
                            <CardActionArea onClick={() => openModal(file)}>
                              {file?.type === 'image' && (
                                <CardMedia
                                  component="img"
                                  sx={{ width: 250, alignContent: 'center' }}
                                  image={file.fullUrl}
                                  alt={`${file.type} image`}
                                />
                              )}
                              {file?.type === 'video' && (
                                <CardMedia
                                  component="img"
                                  sx={{ width: 250, alignContent: 'center' }}
                                  image={file.thumbnailUrl}
                                  alt={`${file.type} image`}
                                />
                              )}
                            </CardActionArea>
                          </Card>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles['comment-pane']}>
                <div className={styles['comment-heading']}>
                  Comments
                  <span className={styles['comment-count']}>
                    {hasComments(data) &&
                      typeof data!.comments !== 'undefined' &&
                      data!.comments !== null &&
                      data!.commentCount != null &&
                      data!.commentCount > 0 &&
                      ` (${data!.commentCount})`}
                  </span>
                </div>
                <JournalDetailsCommentForm data={data} />
                <List className={styles['comment-list']}>
                  {displayComments &&
                    hasComments(data) &&
                    typeof data!.comments !== 'undefined' &&
                    data!.comments !== null &&
                    data!.comments.map((comment) => {
                      return (
                        <JournalCommentList
                          key={comment.id}
                          name={comment.userFullName}
                          timestamp={new Date(comment.createdAt)}
                          isDeleteAllowed={authUserData!.id === comment.ownerId}
                          isDeleted={comment.deletedAt !== null}
                          isCommentUnread={comment.isCommentUnread}
                          comment={comment.body}
                          onDelete={() => handleDelete(data!.id, comment.id)}
                        />
                      );
                    })}
                </List>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};
