import React, { FC, useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem } from '@mui/material';
import { deleteTasks, duplicateTask } from 'actions/Task/taskActions';
import { IconMenu } from 'components/IconMenu';
import { If } from 'components/If';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskType } from 'enums/TaskType.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useMessagesContext } from 'hooks/Auth/useMessagesContext';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { TasksContext, useTasksCache } from 'hooks/Task/useTasksCache';
import { TaskColumn, useTasksContext } from 'hooks/Task/useTasksContext';
import { queryClient } from 'queryClient';
import { TaskDuplicateOptions, TasksQuery } from 'services/API/Task/TaskApi';
import { EditFolderModal } from 'views/Library/EditFolderModal';
import { MoveToFolderModal } from 'views/People/TaskLibrary/MoveToFolderModal';
import { CopyToSharedLibraryModal } from 'views/SharedLibrary/CopyToSharedLibraryModal';
import { AssignTaskModal } from 'views/Task/AssignTaskModal';
import { DeleteTaskModal } from 'views/Task/DeleteTaskModal';
import { EditTaskModal } from 'views/Task/EditTaskModal';

import styles from './OrganizationLibraryActionMenu.module.scss';

interface Props {
  task: TaskColumn;
}

export const OrganizationLibraryActionMenu: FC<
  React.PropsWithChildren<Props>
> = ({ task }) => {
  const {
    id,
    type,
    title,
    image,
    imageUrl,
    tasksAmount,
    description,
    repertoireId,
    parentId,
    assignedTo,
    isFolder
  } = task;

  const { showSnackBar } = useMessagesContext();
  const { activeFolder, search, status, sort, onChangeActiveFolder } =
    useTasksContext();
  const { data: organizationData } = useOrganizationBySlug();
  const { data: userData } = useLoadAuthUserData();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [isCopyToSharedLibraryModalOpen, setIsCopyToSharedLibraryModalOpen] =
    useState(false);
  const [isAssignTaskModalOpen, setIsAssignTaskModalOpen] = useState(false);
  const [isMoveToFolderModalOpen, setIsMoveToFolderModalOpen] = useState(false);

  const onToggleDeleteTaskModal = useCallback(() => {
    setIsDeleteTaskModalOpen(
      (prevIsDeleteTaskModalOpen) => !prevIsDeleteTaskModalOpen
    );
  }, []);

  const onToggleCopyToSharedLibraryModal = useCallback(() => {
    setIsCopyToSharedLibraryModalOpen(
      (prevIsCopyToSharedLibraryModalOpen) =>
        !prevIsCopyToSharedLibraryModalOpen
    );
  }, []);

  const onToggleEditModal = useCallback(() => {
    setIsEditModalOpen((prevIsEditModalOpen) => !prevIsEditModalOpen);
  }, []);

  const onToggleAssignModal = useCallback(() => {
    setIsAssignTaskModalOpen(
      (prevIsAssignTaskModalOpen) => !prevIsAssignTaskModalOpen
    );
  }, []);

  const onToggleMoveToFolderModal = useCallback(() => {
    setIsMoveToFolderModalOpen(
      (prevIsMoveToFolderModalOpen) => !prevIsMoveToFolderModalOpen
    );
  }, []);

  const options = useMemo(
    () =>
      activeFolder
        ? {
            search,
            status,
            sort,
            parentIdEq: activeFolder!.id,
            parentIdIsNull: undefined
          }
        : { search, status, sort, parentIdIsNull: true, parentIdEq: undefined },
    [activeFolder, search, sort, status]
  );

  const { prevTasksList, updateCache, resetToPrevCache } =
    useTasksCache(options);

  const showError = (apiError?: ApiError) => {
    showSnackBar({
      isError: true,
      errorMessage: apiError?.message ?? ErrorMessages.FailedPostRequest
    });
  };

  const { mutateAsync: onDeleteTask } = useMutation<
    void,
    ApiError,
    Task['id'],
    TasksContext
  >((taskId: Task['id']) => deleteTasks([taskId]), {
    retry: 0,
    onMutate: async (taskId) => {
      await queryClient.cancelQueries(queryKeys.tasksList);

      if (prevTasksList) {
        updateCache(
          prevTasksList.pages.map((page) =>
            page.filter((task) => task.id !== taskId)
          )
        );
      }

      return { prevTasksList };
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.tasksList);
      await queryClient.invalidateQueries(queryKeys.taskDetails(id));
      await queryClient.invalidateQueries(queryKeys.folders);

      if (activeFolder?.id === task.id) {
        onChangeActiveFolder(null);
      }

      onToggleDeleteTaskModal();
    },
    onError: (_errors, _vars, context) => {
      showError();
      resetToPrevCache(context);
    }
  });

  const { mutateAsync: onDuplicateTask } = useMutation<
    Task,
    ApiError,
    TaskDuplicateOptions & { id: Task['id'] }
  >(
    ({ id, sharable, parentId, organizationId, publishStatus }) =>
      duplicateTask(id, {
        assignedToId: null,
        sharable,
        parentId,
        publishStatus,
        organizationId
      }),
    {
      retry: 0,
      onSuccess: ({ sharable, organizationId }) => {
        showSnackBar({
          isSuccess: true,
          successMessage: `${
            task.isFolder ? 'Module' : 'Lesson'
          } successfully copied`
        });

        queryClient.invalidateQueries(
          sharable && organizationId
            ? queryKeys.sharedOrgTasksList
            : queryKeys.tasksList
        );
        queryClient.invalidateQueries(queryKeys.folders);
      },
      onError: (apiError) => showError(apiError)
    }
  );

  const queryFilter: TasksQuery = {
    sort: ['title:ASC'],
    organizationIdEq: organizationData!.id,
    assignedToIdEq: task.assignedToId || undefined,
    assignedToIdIsNull: !task.assignedToId,
    sharableEq: false
  };

  const additionalDeleteFolderMessage = tasksAmount
    ? 'If you delete the module, the lessons will get deleted automatically.'
    : '';
  const additionalDeleteTaskMessage = assignedTo
    ? 'Deleting it will remove it from the caregiver library.'
    : '';

  return (
    <div className={styles.action}>
      <DeleteTaskModal
        data={{
          id,
          title,
          type,
          tasksAmount,
          assigneeName: assignedTo?.fullName
        }}
        onDeleteTask={() => onDeleteTask(id)}
        isOpen={isDeleteTaskModalOpen}
        onCloseModal={onToggleDeleteTaskModal}
        additionalTitle={
          isFolder ? additionalDeleteFolderMessage : additionalDeleteTaskMessage
        }
      />
      <CopyToSharedLibraryModal
        onSuccess={() =>
          onDuplicateTask({
            id,
            sharable: true,
            parentId: null,
            organizationId: organizationData!.id,
            publishStatus: TaskStatus.Published
          })
        }
        isOpen={isCopyToSharedLibraryModalOpen}
        onCloseModal={onToggleCopyToSharedLibraryModal}
      />
      <AssignTaskModal
        isOpen={isAssignTaskModalOpen}
        onCloseModal={onToggleAssignModal}
        tasks={[task]}
      />
      <MoveToFolderModal
        onCloseModal={onToggleMoveToFolderModal}
        isOpen={isMoveToFolderModalOpen}
        tasks={[task]}
        queryFilter={queryFilter}
      />
      {type === TaskType.Group ? (
        <EditFolderModal
          isOpen={isEditModalOpen}
          onCloseModal={onToggleEditModal}
          data={{
            id,
            title,
            image,
            description,
            imageUrl,
            repertoireId
          }}
        />
      ) : (
        <EditTaskModal
          isOpen={isEditModalOpen}
          onEditTask={() => queryClient.invalidateQueries(queryKeys.tasksList)}
          onCloseModal={onToggleEditModal}
          taskQuery={queryFilter}
          data={{
            id,
            title,
            description,
            imageUrl,
            image,
            repertoireId,
            parentId
          }}
        />
      )}
      <IconMenu
        className={styles.menu}
        icon={<MoreVertIcon />}
        buttonLabel="Kabob Menu"
      >
        <If condition={!task.assignedToId}>
          <MenuItem onClick={onToggleAssignModal}>Assign to Caregiver</MenuItem>
        </If>
        <MenuItem
          onClick={() =>
            onDuplicateTask({
              id,
              sharable: false,
              parentId: task.assignedToId ? null : task.parentId,
              publishStatus: TaskStatus.Published,
              organizationId: organizationData!.id
            })
          }
        >
          Duplicate in My Lessons
        </MenuItem>
        <MenuItem onClick={onToggleEditModal}>{`Edit ${
          task.isFolder ? 'Module' : 'Lesson'
        }`}</MenuItem>
        <If condition={!task.isFolder}>
          <MenuItem onClick={onToggleMoveToFolderModal}>
            Move to Module
          </MenuItem>
        </If>
        <MenuItem onClick={onToggleCopyToSharedLibraryModal}>
          Copy to Shared Library
        </MenuItem>
        <If condition={userData?.role === UserRoles.SuperAdmin}>
          <MenuItem
            onClick={() =>
              onDuplicateTask({
                id,
                organizationId: null,
                parentId: null,
                sharable: true,
                publishStatus: TaskStatus.Draft
              })
            }
          >
            Copy to CareCoordinator® Library
          </MenuItem>
        </If>
        <MenuItem
          className={styles['delete-button']}
          onClick={onToggleDeleteTaskModal}
        >
          Delete
        </MenuItem>
      </IconMenu>
    </div>
  );
};
