import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { updateTaskDetails } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useFolders } from 'hooks/Task/useFolders';
import { queryClient } from 'queryClient';
import { TasksQuery } from 'services/API/Task/TaskApi';

import styles from './MoveToFolderModal.module.scss';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  tasks: Task[];
  queryFilter?: Partial<TasksQuery>;
  onSuccess?: () => void;
}

interface Option {
  title: string;
  id: string;
}

export const MoveToFolderModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal,
  tasks,
  queryFilter = {},
  onSuccess
}) => {
  const [selectedFolder, setSelectedFolder] = useState<Option | null>(null);

  const {
    data: folderData,
    isError: isFolderError,
    isLoading: isFolderLoading
  } = useFolders({
    filters: queryFilter,
    options: {
      enabled: isOpen
    }
  });

  const folders = folderData
    ? [{ title: 'None', id: 'none' }, ...folderData]
    : [];

  const { isLoading, error, isError, mutateAsync } = useMutation<
    Task,
    ApiError,
    Task['id']
  >(
    (taskId: Task['id']) =>
      updateTaskDetails(taskId, {
        parentId: selectedFolder?.id === 'none' ? null : selectedFolder?.id
      }),
    {
      onSuccess: async (data: Task) => {
        await queryClient.setQueryData(queryKeys.taskDetails(data.id), data);
        await queryClient.invalidateQueries(
          data.parentId ? queryKeys.taskDetails(data.parentId) : undefined
        );
      }
    }
  );

  const handleUpdateTasksList = () => {
    const requests = tasks.map(({ id }) => mutateAsync(id));

    Promise.all(requests).then(async () => {
      await queryClient.invalidateQueries(queryKeys.tasksList);
      await queryClient.invalidateQueries(queryKeys.folders);

      if (onSuccess) {
        onSuccess();
      }

      onCloseModal();
    });
  };

  return (
    <>
      <StatusSnackBar
        isError={isFolderError || isError}
        errorMessage={error?.errorMessage || ErrorMessages.FailedGetRequest}
      />

      <Modal title="Move to Module" isOpen={isOpen} onCloseModal={onCloseModal}>
        <div className={styles.container}>
          <Autocomplete
            id="choose-folder"
            aria-label="Choose module"
            options={folders}
            getOptionLabel={(option) => (option as Option).title}
            renderInput={(params) => (
              <TextField {...params} label="Module option" />
            )}
            onChange={(_e, value) => {
              if (typeof value !== 'string') {
                setSelectedFolder(value || null);
              }
            }}
            freeSolo
            open
            className={styles.autocomplete}
            classes={{
              paper: styles.paper,
              listbox: styles.listbox
            }}
          />
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!selectedFolder || isLoading}
                onClick={handleUpdateTasksList}
                loading={isFolderLoading || isLoading}
              >
                Move
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
