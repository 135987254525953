import React, { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Snackbar, SnackbarContent } from '@mui/material';
import {
  activateOrganizations,
  deactivateOrganizations
} from 'actions/Organization/organizationActions';
import cn from 'classnames';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Organization } from 'entities/Organization.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'queryClient';
import { checkForPlural } from 'utils/helpers/stringHelpers';
import { DeactivateOrganizationModal } from 'views/Organizations/DeactivateOrganizationModal';

import styles from './OrganizationsStatusBar.module.scss';

interface Props {
  selectedOrganization: Organization[];
  onSuccess: () => void;
}

interface OrganizationIds {
  activeOrganizations: Organization[];
  inactiveOrganizations: Organization[];
}

export const OrganizationsStatusBar: FC<React.PropsWithChildren<Props>> = ({
  selectedOrganization,
  onSuccess
}) => {
  const [isOpenDeactivateModal, setIsOpenDeactivateModal] =
    useState<boolean>(false);

  const onToggleOpenDeactivateModal = useCallback(
    () => setIsOpenDeactivateModal((state) => !state),
    []
  );

  const { activeOrganizations, inactiveOrganizations } =
    selectedOrganization.reduce(
      (acc: OrganizationIds, item) => {
        if (item.status === EntityStatus.Active) {
          acc.activeOrganizations.push(item);
        } else {
          acc.inactiveOrganizations.push(item);
        }

        return acc;
      },
      {
        activeOrganizations: [],
        inactiveOrganizations: []
      }
    );

  const {
    isError: isActivateError,
    isSuccess: isActivateSuccess,
    isLoading: isActivateLoading,
    mutate: activate,
    data: activateResponse
  } = useMutation(
    () => activateOrganizations(inactiveOrganizations.map((item) => item.id)),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(queryKeys.organizationsList);
        onSuccess();
      }
    }
  );

  const {
    isError: isDeactivateError,
    isSuccess: isDeactivateSuccess,
    isLoading: isDeactivateLoading,
    mutate: deactivate,
    data: deactivateResponse
  } = useMutation(
    () => deactivateOrganizations(activeOrganizations.map((item) => item.id)),
    {
      async onSuccess() {
        onToggleOpenDeactivateModal();

        await queryClient.invalidateQueries(queryKeys.organizationsList);
        onSuccess();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isDeactivateError}
        isSuccess={isDeactivateSuccess}
        errorMessage={ErrorMessages.FailedPostRequest}
        successMessage={`${
          deactivateResponse?.modifiedCount
        } organization${checkForPlural(
          deactivateResponse?.modifiedCount || 0
        )} successfully deactivated`}
      />
      <StatusSnackBar
        isError={isActivateError}
        isSuccess={isActivateSuccess}
        errorMessage={ErrorMessages.FailedPostRequest}
        successMessage={`${
          activateResponse?.modifiedCount
        } organization${checkForPlural(
          activateResponse?.modifiedCount || 0
        )} successfully activated`}
      />

      <Snackbar
        open={!!selectedOrganization.length}
        className={cn(styles.container, styles.snackbar)}
      >
        <SnackbarContent
          message={`You've selected ${
            selectedOrganization.length
          } organization${checkForPlural(selectedOrganization.length)}`}
          action={
            <>
              <LoadingButton
                color="primary"
                variant="contained"
                className={styles['action-button']}
                loading={isActivateLoading}
                loadingPosition="center"
                onClick={() => activate()}
                disabled={
                  (!!activeOrganizations.length &&
                    !inactiveOrganizations.length) ||
                  isDeactivateLoading
                }
              >
                Activate
              </LoadingButton>
              <LoadingButton
                color="primary"
                variant="contained"
                className={styles['action-button']}
                loading={isDeactivateLoading}
                loadingPosition="center"
                onClick={onToggleOpenDeactivateModal}
                disabled={
                  (!activeOrganizations.length &&
                    !!inactiveOrganizations.length) ||
                  isActivateLoading
                }
              >
                Deactivate
              </LoadingButton>
            </>
          }
          className={styles['snackbar-content']}
        />
      </Snackbar>

      <DeactivateOrganizationModal
        isOpen={isOpenDeactivateModal}
        onCloseModal={onToggleOpenDeactivateModal}
        onDeactivateOrganizations={deactivate}
        organizations={activeOrganizations}
      />
    </>
  );
};
