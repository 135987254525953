import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import cn from 'classnames';
import { Step } from 'entities/Step.entity';
import { reorderData } from 'utils/helpers/listsHelpers';
import { StepCard } from 'views/Task/StepCard';

interface Props {
  steps: Step[];
  onUpdateStep: (step: Step) => void;
  onMoveTaskStep: (props: { id: string; moveIndex: number }) => void;
}

export const StepsDnd: FC<React.PropsWithChildren<Props>> = ({
  steps,
  onUpdateStep,
  onMoveTaskStep
}) => {
  const [stepsList, setStepsList] = useState<Step[]>(steps);

  useEffect(() => {
    setStepsList(steps);
  }, [steps]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.source.index === result.destination.index) {
        return;
      }

      const newStepsList = reorderData<Step>(
        stepsList,
        result.source.index,
        result.destination.index
      );

      setStepsList(newStepsList);

      onMoveTaskStep({
        id: result.draggableId,
        moveIndex: result.destination.index
      });
    },
    [onMoveTaskStep, stepsList]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={cn(snapshot.isDraggingOver && 'draggingOver')}
            {...provided.droppableProps}
          >
            {stepsList.map((step, index) => (
              <Draggable
                key={step.id}
                index={index}
                draggableId={step.id}
                isDragDisabled={!step.active}
              >
                {(provided, snapshot) => (
                  <StepCard
                    step={step}
                    index={index + 1}
                    onUpdateStep={onUpdateStep}
                    draggableProvided={provided}
                    isDragging={snapshot.isDragging}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
