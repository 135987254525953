import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { Column } from 'react-table';
import { NotificationCircle } from 'components/NotificationCircle';
import { format } from 'date-fns';
import { Routes } from 'enums/Routes.enum';
import { JournalCaregiverNotification } from 'hooks/Notification/useNotification';

import styles from './JournalEntries.module.scss';

export type JournalEntriesColumn = {
  id: string;
  title: string;
  commentsCount: number;
  sharedOn: Date;
  lastUpdated: Date;
};
type EntryColProps = {
  id: string;
};

type TitleProps = EntryColProps & {
  title: string;
};

type CommentsProps = EntryColProps & {
  commentsCount: number;
};

type StringProps = EntryColProps & {
  value: string;
};

const buildPath = (
  journalId: string,
  slug: string,
  slugId: string,
  caregiverId: string
) => {
  const generatedPth = generatePath(Routes.Journal, {
    slug,
    slugId,
    userId: caregiverId,
    id: journalId
  });
  return generatedPth;
};

const getContentStyle = (journalId: string, journalsToHighlight: string[]) =>
  journalsToHighlight.includes(journalId) ? styles.unread : styles.read;

export const getColumnDefinitions = (
  slug: string,
  slugId: string,
  caregiverId: string,
  notifications: JournalCaregiverNotification | undefined,
  journalsToHighlight: string[]
) => {
  const cols: Column<JournalEntriesColumn>[] = [
    {
      id: 'title',
      Header: 'Title',
      accessor: ({ title, id }): TitleProps => ({
        title,
        id
      }),
      Cell: ({ value }: { value: TitleProps }) => (
        <NavLink to={buildPath(value.id, slug, slugId, caregiverId)}>
          <span className={getContentStyle(value.id, journalsToHighlight)}>
            {notifications &&
              notifications.journals.some((n) => n === value.id) && (
                <NotificationCircle />
              )}
            {value.title}
          </span>
        </NavLink>
      ),
      width: 3,
      minWidth: 200
    },
    {
      id: 'comments',
      Header: 'Comments',
      accessor: ({ commentsCount, id }): CommentsProps => ({
        commentsCount,
        id
      }),
      Cell: ({ value }: { value: CommentsProps }) => (
        <span className={getContentStyle(value.id, journalsToHighlight)}>
          {value.commentsCount}
          {notifications &&
            notifications.comments.some((n) =>
              n.journalId.includes(value.id)
            ) && <NotificationCircle />}
        </span>
      ),
      width: 3,
      minWidth: 150
    },
    {
      id: 'sharedOn',
      Header: 'Shared on',
      accessor: ({ sharedOn, id }): StringProps => ({
        value: sharedOn && format(sharedOn, 'MM/dd/yyyy'),
        id
      }),
      Cell: ({ value }: { value: StringProps }) => (
        <span className={getContentStyle(value.id, journalsToHighlight)}>
          {value.value}
        </span>
      ),
      width: 3,
      minWidth: 150
    },
    {
      id: 'lastUpdated',
      Header: 'Last Updated',
      accessor: ({ lastUpdated, id }): StringProps => ({
        value: lastUpdated && format(lastUpdated, 'MM/dd/yyyy'),
        id
      }),
      Cell: ({ value }: { value: StringProps }) => (
        <span className={getContentStyle(value.id, journalsToHighlight)}>
          {value.value}
        </span>
      ),
      width: 3,
      minWidth: 150
    }
  ];

  return cols;
};
