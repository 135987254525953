import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchOrganizations } from 'actions/Organization/organizationActions';
import { duplicateTaskToOrganizations } from 'actions/Task/taskActions';
import { EmptyState } from 'components/EmptyState';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'queryClient';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';
import { checkForPlural } from 'utils/helpers/stringHelpers';

import styles from './CopyToOrganizationModal.module.scss';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  taskIds: string[];
  onSuccess?: () => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CopyToOrganizationModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal,
  taskIds,
  onSuccess
}) => {
  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization[]
  >([]);

  const {
    data: organizationData,
    isError: isOrganizationError,
    isLoading: isOrganizationLoading,
    error: organizationError
  } = useQuery<Organization[], ApiError>(
    queryKeys.organizationsList,
    () =>
      fetchOrganizations({
        sort: ['name:ASC'],
        limit: MAX_PAGE_LIMIT
      }),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      enabled: isOpen
    }
  );

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    void,
    ApiError
  >(
    () =>
      duplicateTaskToOrganizations(taskIds, {
        sharable: true,
        organizationIds: selectedOrganization.map((item) => item.id)
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.sharedOrgTasksList);

        if (onSuccess) {
          onSuccess();
        }
        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage={`Item${checkForPlural(
          taskIds.length
        )} successfully copied`}
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <StatusSnackBar
        isError={isOrganizationError}
        errorMessage={
          organizationError?.errorMessage || ErrorMessages.FailedGetRequest
        }
      />

      <Modal
        title="Copy to organization shared library"
        isOpen={isOpen}
        onCloseModal={onCloseModal}
      >
        <div className={styles.container}>
          <Autocomplete
            multiple
            id="choose-organizations"
            aria-label="Choose organizations"
            options={organizationData || []}
            getOptionLabel={(option) => (option as Organization).name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  className={styles.checkbox}
                  checked={selected}
                  inputProps={{
                    'aria-label': 'Check organizations'
                  }}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Organization(s)"
                placeholder="Search"
              />
            )}
            onChange={(_e, value) => {
              setSelectedOrganization(value as Organization[]);
            }}
            freeSolo
            open={!!organizationData?.length || isOrganizationLoading}
            className={styles.autocomplete}
            classes={{
              paper: styles.paper,
              listbox: styles.listbox
            }}
          />
          <div className={styles['empty-box']}>
            {!organizationData?.length && !isOrganizationLoading && (
              <EmptyState />
            )}
          </div>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={9}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!selectedOrganization.length || isLoading}
                onClick={() => mutateAsync()}
                loading={isOrganizationLoading || isLoading}
              >
                Copy to organization library
              </LoadingButton>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
