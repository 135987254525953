import * as React from 'react';
import { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { Box, Grid } from '@mui/material';
import logo from 'assets/logo.svg';
import { InfoMessage, InfoMessageTheme } from 'components/InfoMessage';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { NavigationHeader } from 'components/NavigationHeader';

import styles from './ProblemPage.module.scss';

interface Props {
  message: string;
}

export const ProblemPage: FC<React.PropsWithChildren<Props>> = ({
  message
}) => {
  const auth = useAuth();
  if (auth.isLoading) {
    return <AbsoluteSpinner loading={auth.isLoading} />;
  }

  return (
    <div className={styles.layout}>
      <NavigationHeader />
      <Grid
        container
        sx={{ pl: 2, pr: 2 }}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          className={styles['error-page']}
          style={{ minWidth: '25rem', maxWidth: '25rem' }}
          sx={{ mb: 10, mt: 8 }}
        >
          <Box className={styles['logo-page']}>
            <img src={logo} alt="CareCoordinator logo" />
          </Box>
          <br />
          <InfoMessage theme={InfoMessageTheme.Error} message={message} />
        </Grid>
      </Grid>
    </div>
  );
};
