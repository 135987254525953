import React, { FC, useCallback, useState } from 'react';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { Button, Grid } from '@mui/material';
import { User } from 'entities/User.entity';
import { MoveToOrganizationModal } from 'views/People/MoveToOrganizationModal';

import styles from './MoveToOrganization.module.scss';

interface Props {
  userId: User['id'];
  fullName: User['fullName'];
}

export const MoveToOrganization: FC<React.PropsWithChildren<Props>> = ({
  userId,
  fullName
}) => {
  const [isMoveToOrganizationModalOpen, setIsMoveToOrganizationModalOpen] =
    useState<boolean>(false);

  const onToggleMoveToOrganizationModal = useCallback(() => {
    setIsMoveToOrganizationModalOpen(
      (prevIsMoveToOrganizationModalOpen) => !prevIsMoveToOrganizationModalOpen
    );
  }, []);

  return (
    <>
      <Grid container>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={styles.container}
        >
          <DriveFileMoveOutlinedIcon className={styles.icon} />
          <span className={styles.title}>Move account to new organization</span>
          <Button
            color="primary"
            variant="contained"
            onClick={onToggleMoveToOrganizationModal}
          >
            Move to new organization
          </Button>
        </Grid>
      </Grid>
      <MoveToOrganizationModal
        userId={userId}
        fullName={fullName}
        isOpen={isMoveToOrganizationModalOpen}
        onCloseModal={onToggleMoveToOrganizationModal}
      />
    </>
  );
};
