import React, { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import { If } from 'components/If';
import { Task } from 'entities/Task.entity';
import { SharedLibraryActionMenu } from 'views/Organizations/OrganizationLibrary/SharedLibraryActionMenu';
import { UserTableNavCell } from 'views/People/UserTableNavCell';

import styles from './SharedLibraryListItem.module.scss';

interface Props {
  task: Task;
  onSetActiveFolder: () => void;
  isActiveFolder?: boolean;
  isDragging?: boolean;
}

export const SharedLibraryListItem: FC<React.PropsWithChildren<Props>> = ({
  task,
  onSetActiveFolder,
  isActiveFolder,
  isDragging
}) => {
  const {
    id,
    title,
    stepsAmount,
    isFolder,
    tasksAmount,
    imageUrl,
    availLibraryTaskReflection
  } = task;

  return (
    <div
      className={cn(
        styles['shared-library-list-item'],
        isDragging && styles.dragging,
        isActiveFolder && styles['active-folder']
      )}
    >
      <If condition={!!isActiveFolder}>
        <IconButton
          id="back-button"
          data-testid="back-button"
          className={styles['back-button']}
          onClick={onSetActiveFolder}
        >
          <ArrowBackIcon />
        </IconButton>
      </If>

      <div className={styles['icon-cell-box']}>
        <UserTableNavCell
          isDragIconVisible={!isActiveFolder}
          hasAvatarPlaceholder
          title={title}
          imageURL={imageUrl}
          onTitleClick={
            !isActiveFolder && isFolder ? onSetActiveFolder : undefined
          }
          task={{
            id,
            title,
            stepsAmount,
            isFolder,
            tasksAmount
          }}
          showCareIcon={availLibraryTaskReflection}
        />
      </div>

      <If condition={!isActiveFolder}>
        <SharedLibraryActionMenu task={task} />
      </If>
    </div>
  );
};
