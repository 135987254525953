import { File } from 'entities/File.entity';
import { Step } from 'entities/Step.entity';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskType } from 'enums/TaskType.enum';
import { date, deserialize, list, object, serializable } from 'serializr';

export class Task {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  slugId = '';

  @serializable
  type = '';

  @serializable
  sharable = false;

  @serializable
  sequenceId = '';

  @serializable
  parentId: string | null = null;

  @serializable(object(File))
  image?: File | null;

  @serializable
  title = '';

  @serializable
  repertoireId = '';

  @serializable
  description = '';

  @serializable
  publishStatus: TaskStatus = TaskStatus.Draft;

  @serializable
  createdById = '';

  @serializable
  assignedToId: string | null = null;

  @serializable
  organizationId: string | null = null;

  @serializable
  stepsAmount: number | null = 0;

  @serializable
  tasksAmount: number | null = 0;

  @serializable
  availLibraryTaskReflection = false;

  @serializable(list(object(Step)))
  steps?: Step[] = [];

  @serializable(date())
  firstCompletedDate: Date | null = null;

  @serializable
  completionPercentage: number | null = 0;

  get imageUrl(): string {
    return this.image?.url || '';
  }

  get isFolder(): boolean {
    return this.type === TaskType.Group;
  }

  static deserialize(json: Object | string): Task {
    return deserialize(Task, json);
  }

  static deserializeAsList(list: Task[]): Task[] {
    return list.map(Task.deserialize);
  }
}
