import React, { FC, useCallback, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { NotificationCircle } from 'components/NotificationCircle';

import { JournalDeleteModal } from '../JournalDeleteModal';

import styles from './JournalCommentList.module.scss';

interface Props {
  name: string;
  timestamp: Date;
  isDeleteAllowed: boolean;
  isDeleted: boolean;
  isCommentUnread: boolean;
  comment: string;
  onDelete: () => void;
}

export interface FormData {
  comment: string;
}

export const JournalCommentList: FC<React.PropsWithChildren<Props>> = ({
  name,
  timestamp,
  isDeleteAllowed,
  isDeleted,
  isCommentUnread,
  comment,
  onDelete
}) => {
  const [isOpenJournalDeleteModal, setIsOpenJournalDeleteModal] =
    useState(false);
  const onToggleJournalDeleteModal = useCallback(() => {
    setIsOpenJournalDeleteModal(
      (prevIsJournalDeleteModalOpen) => !prevIsJournalDeleteModalOpen
    );
  }, []);

  return (
    <div className={styles['comment-container']}>
      <div className={styles['comment-details']}>
        <span className="comment-info">
          <span className={styles['comment-name']}>{name}</span>
          <span className={styles['comment-name']}>
            {timestamp.toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            })}
          </span>
          <span className={styles['comment-name']}>
            {timestamp.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit'
            })}
          </span>
          <span className={styles.circle}>
            {isCommentUnread && !isDeleted && <NotificationCircle />}
          </span>
        </span>
        {!isDeleted && isDeleteAllowed && (
          <>
            <span className={styles['comment-delete']}>
              <IconButton
                aria-label="delete"
                onClick={onToggleJournalDeleteModal}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
            <JournalDeleteModal
              isOpen={isOpenJournalDeleteModal}
              onCloseModal={onToggleJournalDeleteModal}
              onJournalDelete={onDelete}
            />
          </>
        )}
      </div>
      <div className={styles['comment-text']}>
        {!isDeleted && <div>{comment}</div>}
        {isDeleted && (
          <div className={styles['comment-deleted']}>
            {name} deleted their comment.
          </div>
        )}
      </div>
    </div>
  );
};
