import { useRef } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import {
  fetchNotifications,
  UserNotification
} from 'actions/Notification/NotificationActions';
import { ApiError } from 'entities/ApiError.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'queryClient';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

export const useNotification = (
  userId: string,
  enabled: boolean
): UseQueryResult<UserNotification, ApiError> => {
  const previousDataRef = useRef<Set<string>>(new Set());

  return useQuery<UserNotification, ApiError>(
    queryKeys.userNotifications(userId),
    () => fetchNotifications(),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      enabled,
      refetchInterval: 30000,
      onSuccess: (data) => {
        const newData = data.notificationIds;

        // If new notifications, refresh the queries

        const isDataChanged =
          newData.size !== previousDataRef.current.size ||
          [...newData].some((id) => !previousDataRef.current.has(id));

        if (
          isDataChanged &&
          (data.comments.length > 0 || data.journals.length > 0)
        ) {
          previousDataRef.current = newData;

          queryClient.invalidateQueries({
            predicate: (query) => {
              return query.queryKey.includes('journals');
            },
            refetchActive: true,
            refetchInactive: false
          });

          queryClient.invalidateQueries({
            predicate: (query) => {
              return query.queryKey.includes('learners');
            },
            refetchActive: true,
            refetchInactive: false
          });
        }
      }
    }
  );
};

export type JournalSummaryNotification = {
  commentsCount: number;
  journalsCount: number;
  caregiversIds: string[];
};

export const useJournalSummaryNotification = (
  userId: string,
  enabled: boolean
): {
  data: JournalSummaryNotification | undefined;
  isFetching: boolean;
  isError: boolean;
} => {
  const {
    data: notifications,
    isFetching,
    isError
  } = useNotification(userId, enabled);
  const commentCaregiverIds =
    notifications?.comments.map((c) => c.journalOwnerId) ?? [];
  const journalCaregiverIds =
    notifications?.journals.map((j) => j.ownerId) ?? [];
  const caregiversIds = [
    ...new Set([...commentCaregiverIds, ...journalCaregiverIds])
  ];
  const summary: JournalSummaryNotification | undefined = notifications
    ? {
        commentsCount: notifications.comments.length,
        journalsCount: notifications.journals.length,
        caregiversIds
      }
    : undefined;

  return {
    data: summary,
    isFetching,
    isError
  };
};

interface JournalComments {
  journalId: string;
  comments: string[];
}

export type JournalCaregiverNotification = {
  journals: string[];
  comments: JournalComments[];
};

export const useJournalCaregiverNotification = (
  userId: string,
  caregiverId: string
): {
  data: JournalCaregiverNotification | undefined;
  isFetching: boolean;
  isError: boolean;
} => {
  const {
    data: notifications,
    isFetching,
    isError
  } = useNotification(userId, true);
  const journalIds = notifications?.journals
    .filter((j) => j.ownerId === caregiverId)
    .map((j) => j.id);

  const comments = notifications?.comments
    .filter((c) => c.journalOwnerId === caregiverId)
    .reduce((acc, c) => {
      const journal = acc.find((jc) => jc.journalId === c.journalId);
      if (journal) {
        journal.comments.push(c.id);
      } else {
        acc.push({ journalId: c.journalId, comments: [c.id] });
      }
      return acc;
    }, [] as JournalComments[]);

  const result = {
    journals: journalIds || [],
    comments: comments || []
  };

  return {
    data: result,
    isFetching,
    isError
  };
};
