import React, { FC, useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Snackbar, SnackbarContent } from '@mui/material';
import { duplicateTasks } from 'actions/Task/taskActions';
import cn from 'classnames';
import { If } from 'components/If';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { useMessagesContext } from 'hooks/Auth/useMessagesContext';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { queryClient } from 'queryClient';
import { checkForPlural } from 'utils/helpers/stringHelpers';
import { MoveToFolderModal } from 'views/People/TaskLibrary/MoveToFolderModal';
import { AssignTaskModal } from 'views/Task/AssignTaskModal';

import styles from './OrganizationLibraryStatusBar.module.scss';

interface Props {
  selectedTasks: Task[];
  onSuccess: () => void;
}

export const OrganizationLibraryStatusBar: FC<
  React.PropsWithChildren<Props>
> = ({ selectedTasks, onSuccess }) => {
  const { showSnackBar } = useMessagesContext();
  const { data: organizationData } = useOrganizationBySlug();

  const [isMoveToFolderModalOpen, setIsMoveToFolderModalOpen] = useState(false);
  const [isAssignTaskModalOpen, setIsAssignTaskModalOpen] = useState(false);

  const onToggleMoveToFolderModal = useCallback(() => {
    setIsMoveToFolderModalOpen(
      (prevIsMoveToFolderModalOpen) => !prevIsMoveToFolderModalOpen
    );
  }, []);

  const onToggleAssignModal = useCallback(() => {
    setIsAssignTaskModalOpen(
      (prevIsAssignTaskModalOpen) => !prevIsAssignTaskModalOpen
    );
  }, []);

  const firstTask = selectedTasks[0];

  const isMoveActive = useMemo(
    () =>
      selectedTasks.every(
        (task) => task.assignedToId === firstTask.assignedToId && !task.isFolder
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTasks]
  );

  const isAssignActive = useMemo(
    () => selectedTasks.every(({ assignedToId }) => !assignedToId),
    [selectedTasks]
  );

  const {
    mutateAsync: onDuplicateTasks,
    isError,
    isLoading: isDuplicateTasksLoading
  } = useMutation<Task[], ApiError>(
    () =>
      duplicateTasks(
        selectedTasks.map((task) => task.id),
        {
          sharable: false,
          organizationId: organizationData!.id,
          parentId: null,
          publishStatus: TaskStatus.Published
        }
      ),
    {
      retry: 0,
      onSuccess: () => {
        showSnackBar({
          isSuccess: true,
          successMessage: `Item${checkForPlural(
            selectedTasks.length
          )} successfully copied`
        });

        queryClient.invalidateQueries(queryKeys.tasksList);
        queryClient.invalidateQueries(queryKeys.folders);

        onSuccess();
      }
    }
  );

  if (!firstTask) {
    return null;
  }

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedPostRequest}
      />
      <Snackbar open className={cn(styles.container, styles.snackbar)}>
        <SnackbarContent
          message={`You've selected ${
            selectedTasks.length
          } item${checkForPlural(selectedTasks.length)}`}
          action={
            <Grid container>
              <If condition={isMoveActive}>
                <Button
                  color="primary"
                  variant="contained"
                  className={styles['action-button']}
                  onClick={onToggleMoveToFolderModal}
                >
                  Move
                </Button>
              </If>
              <LoadingButton
                color="primary"
                variant="contained"
                className={styles['action-button']}
                loadingPosition="center"
                loading={isDuplicateTasksLoading}
                onClick={() => onDuplicateTasks()}
              >
                Duplicate
              </LoadingButton>
              <If condition={isAssignActive}>
                <Button
                  color="primary"
                  variant="contained"
                  className={styles['action-button']}
                  onClick={onToggleAssignModal}
                >
                  Assign
                </Button>
              </If>
            </Grid>
          }
          className={styles['snackbar-content']}
        />
      </Snackbar>
      <MoveToFolderModal
        onCloseModal={onToggleMoveToFolderModal}
        onSuccess={onSuccess}
        isOpen={isMoveToFolderModalOpen}
        tasks={selectedTasks}
        queryFilter={{
          sort: ['title:ASC'],
          organizationIdEq: organizationData!.id,
          assignedToIdEq: firstTask.assignedToId || undefined,
          assignedToIdIsNull: !firstTask.assignedToId,
          sharableEq: false
        }}
      />
      <AssignTaskModal
        isOpen={isAssignTaskModalOpen}
        onCloseModal={onToggleAssignModal}
        tasks={selectedTasks}
        onSuccess={onSuccess}
      />
    </>
  );
};
