import React, { FC } from 'react';
import { useMutation } from 'react-query';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { requestMoreLicenses } from 'actions/Organization/organizationActions';
import { PermissionsGate } from 'components/PermissionsGate';
import { PieChart } from 'components/PieChart';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { UserScopes } from 'enums/UserScopes.enum';

import styles from './DetailsInformationHeader.module.scss';

interface Props {
  data: Organization;
  onOpenLicensesModal: () => void;
}

const MEMBERS_COLORS = [
  styles.Blue,
  styles.PurpleGraph,
  styles.GreenGraph,
  styles.OrangeGraph
];

const LEARNERS_COLORS = [styles.HoverBlue, styles.Blue];

export const DetailsInformationHeader: FC<React.PropsWithChildren<Props>> = ({
  data,
  onOpenLicensesModal
}) => {
  const {
    learnersAmount,
    managersAmount,
    facilitatorsAmount,
    passiveFacilitatorsAmount,
    learnersAmountLimit,
    id
  } = data;

  const {
    isLoading: isLoadingRequestLicenses,
    isError: isErrorRequestLicenses,
    isSuccess: isSuccessRequestLicenses,
    error: requestLicensesError,
    mutateAsync: requestLicenses
  } = useMutation<void, ApiError>(() => requestMoreLicenses(id));

  const usedLicenses = [
    { name: 'Caregivers', value: learnersAmount },
    { name: 'Managers', value: managersAmount },
    { name: 'Facilitators', value: facilitatorsAmount },
    { name: 'Passive facilitators', value: passiveFacilitatorsAmount }
  ];

  const learnerTotalLicenses = [
    { name: 'Caregivers', value: learnersAmount },
    { name: 'Total', value: learnersAmountLimit }
  ];

  const renderMemberRow = (rowIndex: number) => (
    <div className={styles.row}>
      <span
        className={styles['color-indicator']}
        style={{ backgroundColor: MEMBERS_COLORS[rowIndex] }}
      />
      <span className={styles.label}>{usedLicenses[rowIndex].name}</span>
      <span className={styles.counter} data-testid={`member-row-${rowIndex}`}>
        {usedLicenses[rowIndex].value}
      </span>
    </div>
  );

  const renderLearnerRow = (rowIndex: number) => (
    <div className={styles.row}>
      <span
        className={styles['color-indicator']}
        style={{ backgroundColor: LEARNERS_COLORS[rowIndex] }}
      />
      <span className={styles.label}>
        {learnerTotalLicenses[rowIndex].name}
      </span>
    </div>
  );

  return (
    <>
      <StatusSnackBar
        isError={isErrorRequestLicenses}
        isSuccess={isSuccessRequestLicenses}
        errorMessage={
          requestLicensesError?.errorMessage || ErrorMessages.FailedPostRequest
        }
        successMessage="Request successfully sent"
      />

      <div className={styles['details-information-header']}>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={12} lg={6}>
            <h2 className={styles.title}>Staff / Caregivers</h2>
            <Grid container alignItems="stretch">
              <Grid item sm={12} md={2}>
                <PieChart data={usedLicenses} colors={MEMBERS_COLORS} />
              </Grid>
              <Grid item sm={12} md={5} className={styles['column-container']}>
                {renderMemberRow(0)}
                {renderMemberRow(2)}
              </Grid>
              <Grid item sm={12} md={5} className={styles['column-container']}>
                {renderMemberRow(1)}
                {renderMemberRow(3)}
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} lg={4}>
            <h2 className={styles.title}>Caregiver licenses</h2>
            <Grid container alignItems="stretch">
              <Grid item sm={12} md={3}>
                <PieChart
                  data={learnerTotalLicenses}
                  colors={LEARNERS_COLORS}
                />
              </Grid>
              <Grid item sm={12} md={9} className={styles['learner-row']}>
                <div className={styles.value}>
                  {`${learnersAmount} / ${learnersAmountLimit}`}
                </div>
                <div className={styles['rows-container']}>
                  {renderLearnerRow(0)}
                  <span className={styles.separator}>/</span>
                  {renderLearnerRow(1)}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <PermissionsGate scopes={[UserScopes.EditLicenses]}>
            <Grid item md={12} lg={2} className={styles['button-box']}>
              <Button
                size="small"
                aria-label="Edit Licenses"
                color="secondary"
                variant="contained"
                onClick={onOpenLicensesModal}
                startIcon={<ModeEditIcon />}
              >
                Edit
              </Button>
            </Grid>
          </PermissionsGate>

          <PermissionsGate scopes={[UserScopes.RequestMoreLicenses]}>
            <Grid item md={12} lg={2} className={styles['button-box']}>
              <LoadingButton
                size="small"
                color="secondary"
                variant="contained"
                loading={isLoadingRequestLicenses}
                onClick={() => requestLicenses()}
              >
                Request more licenses
              </LoadingButton>
            </Grid>
          </PermissionsGate>
        </Grid>
      </div>
    </>
  );
};
