import { ApiError } from '../../entities/ApiError.entity';
import { ModuleApi } from '../../services/API/Modules/ModuleApi';

export const fetchPreassignedLessons = async (
  moduleId: string,
  userId: string
) => {
  try {
    const { data } = await ModuleApi.fetchPreassignedLessons(moduleId, userId);

    return data;
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
