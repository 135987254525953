import React, { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import {
  generatePath,
  Link as RouterLink,
  useHistory,
  useParams
} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Avatar, Button, Chip, Grid, IconButton, Link } from '@mui/material';
import { deleteTasks } from 'actions/Task/taskActions';
import folder from 'assets/folder.png';
import imagePlaceholder from 'assets/image-placeholder.png';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { Layout } from 'components/Layout';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { useTaskDetails } from 'hooks/Task/useTaskDetails';
import { queryClient } from 'queryClient';
import { DeleteTaskModal } from 'views/Task/DeleteTaskModal';
import { EditTaskModal } from 'views/Task/EditTaskModal';
import { StepsList } from 'views/Task/StepsList';
import { TaskPreview } from 'views/Task/TaskPreview';

import styles from './TaskDetailsPage.module.scss';

export const TaskDetailsPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<{ id: Task['id'] }>();
  const history = useHistory();

  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { task, folderName, repertoireName, error, isError, isLoading } =
    useTaskDetails(id);

  const onToggleDeleteTaskModal = useCallback(() => {
    setIsDeleteTaskModalOpen(
      (prevIsDeleteTaskModalOpen) => !prevIsDeleteTaskModalOpen
    );
  }, []);

  const onToggleEditTaskModal = useCallback(() => {
    setIsEditModalOpen((prevIsEditModalOpen) => !prevIsEditModalOpen);
  }, []);

  const {
    isError: isDeleteTaskError,
    mutateAsync: onDeleteTask,
    error: deleteTaskError
  } = useMutation<void, ApiError>(() => deleteTasks([id]), {
    retry: 0,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.tasksList);
      await queryClient.invalidateQueries(queryKeys.taskDetails(id));
      await queryClient.invalidateQueries(queryKeys.folders);

      history.push(generatePath(Routes.Library));
      onToggleDeleteTaskModal();
    }
  });

  if (!task || (task?.parentId && !folderName) || !repertoireName) {
    return (
      <Layout loading={isLoading}>
        <StatusSnackBar
          isError={isError}
          errorMessage={error?.errorMessage || ErrorMessages.FailedGetRequest}
        />
        {!isLoading && <EmptyState />}
      </Layout>
    );
  }

  const {
    id: taskId,
    stepsAmount,
    type,
    title,
    image,
    imageUrl,
    parentId,
    tasksAmount,
    description,
    repertoireId
  } = task;

  return (
    <Layout>
      <StatusSnackBar
        isError={isDeleteTaskError}
        errorMessage={
          deleteTaskError?.errorMessage || ErrorMessages.FailedPostRequest
        }
      />
      <DeleteTaskModal
        data={{
          id: taskId,
          title,
          type,
          tasksAmount
        }}
        onDeleteTask={onDeleteTask}
        isOpen={isDeleteTaskModalOpen}
        onCloseModal={onToggleDeleteTaskModal}
      />
      <EditTaskModal
        taskQuery={{
          sort: [TaskSort.ByName],
          organizationIdIsNull: true
        }}
        isOpen={isEditModalOpen}
        onEditTask={() => queryClient.invalidateQueries(queryKeys.tasksList)}
        onCloseModal={onToggleEditTaskModal}
        data={{
          id,
          title,
          image,
          description,
          imageUrl,
          repertoireId,
          parentId
        }}
      />

      <div className={styles.navigation}>
        <IconButton
          color="secondary"
          aria-label="Back to library"
          data-testid="back-button"
          className={styles.icon}
          onClick={() => history.push(generatePath(Routes.Library))}
        >
          <ArrowBackIcon />
        </IconButton>
        Back to&nbsp;
        <Link component={RouterLink} to={Routes.Library} underline="hover">
          CareCoordinator® Library
        </Link>
      </div>

      <div className={styles['task-details-page']}>
        <div className={styles.content}>
          <h3 className={styles['main-title']}>Lesson details</h3>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item sm={3}>
              <Avatar
                variant="square"
                className={styles.image}
                alt={`${title} lesson`}
                src={imageUrl || imagePlaceholder}
              />
            </Grid>
            <Grid item sm={6}>
              <div className={styles['folder-container']}>
                <If condition={!!folderName}>
                  <img
                    src={folder}
                    className={styles.image}
                    alt={`${folderName} module`}
                  />
                  <span className={styles['folder-title']}>{folderName}</span>
                </If>
              </div>
              <h4 className={styles.title}>{title}</h4>
              <Chip
                size="small"
                label={repertoireName}
                className={styles['primary-chip']}
              />
              <p className={styles.description}>{description}</p>
              <div>
                <TaskPreview
                  taskId={id}
                  taskName={title}
                  stepsAmount={stepsAmount}
                  buttonWrapClassName={styles.button}
                />
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  startIcon={<ModeEditIcon />}
                  onClick={onToggleEditTaskModal}
                >
                  Edit details
                </Button>
              </div>
            </Grid>
            <Grid item sm={2} className={styles['delete-container']}>
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={onToggleDeleteTaskModal}
              >
                Delete Lesson
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={styles['step-list']}>
          <StepsList taskId={id} />
        </div>
      </div>
    </Layout>
  );
};
