import { deserialize, list, object, serializable } from 'serializr';

import { FileDTO } from './DTO/FileDTO.entity';

export class Content {
  @serializable
  title = '';

  @serializable
  body = '';

  @serializable(list(object(FileDTO)))
  files?: FileDTO[] | [];

  static deserialize(json: Object | string): Content {
    return deserialize(Content, json);
  }

  static deserializeAsList(list: Content[]): Content[] {
    return list.map(Content.deserialize);
  }
}
