import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { FileUploader } from 'components/FileUploader';
import { If } from 'components/If';
import { User } from 'entities/User.entity';
import { EntityStatus, EntityStatusLabels } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { isEmailValid, isUsernameValid } from 'utils/helpers/validators';
import { staffRolesOptions } from 'views/People/CreateStaffForm';

import styles from './EditPersonalInformationForm.module.scss';

const userStatusOptions = [EntityStatus.Inactive, EntityStatus.Active].map(
  (value) => ({
    value,
    label: EntityStatusLabels[value]
  })
);

export interface FormData {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  username: string;
  status: EntityStatus;
  phoneNumber?: string;
  image?: string | null;
}

interface Props {
  data: User;
  isLoading: boolean;
  onCloseModal: () => void;
  onSubmit: (formData: FormData) => void;
}

export const EditPersonalInformationForm: FC<
  React.PropsWithChildren<Props>
> = ({ data, onSubmit, isLoading, onCloseModal }) => {
  const {
    role,
    email,
    status,
    avatar,
    avatarUrl,
    firstName,
    lastName,
    username,
    phoneNumber,
    isLearner
  } = data;

  // eslint-disable-next-line no-nested-ternary
  const defaultValues = {
    role,
    email,
    phoneNumber: phoneNumber || undefined
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<FormData>({
    defaultValues: {
      firstName,
      lastName,
      username,
      status,
      image: avatarUrl,
      ...defaultValues
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  return (
    <form
      className={styles['edit-personal-information-form']}
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
    >
      <Controller
        control={control}
        name="image"
        render={({ field: { onChange } }) => {
          return (
            <FileUploader
              fileData={avatar}
              label="Profile picture"
              onChangeFile={onChange}
            />
          );
        }}
      />
      <Controller
        rules={{ required: true }}
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="First name"
            margin="normal"
            inputRef={field.ref}
            error={!!errors?.firstName}
            helperText={errors?.firstName && ErrorMessages.FailedRequiredField}
            id="outlined-first-name-input"
          />
        )}
      />
      <Controller
        rules={{ required: true }}
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Last name"
            margin="normal"
            error={!!errors?.lastName}
            aria-invalid={!!errors?.lastName}
            helperText={errors?.lastName && ErrorMessages.FailedRequiredField}
            id="outlined-last-name-input"
          />
        )}
      />
      {isLearner && (
        <>
          <Controller
            rules={{
              validate: (value) => {
                if (!value) {
                  return ErrorMessages.FailedRequiredField;
                }
                if (value && !isEmailValid(value)) {
                  return ErrorMessages.NotValidEmail;
                }
                return true;
              }
            }}
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                label="Email"
                margin="normal"
                error={!!errors?.email}
                aria-invalid={!!errors?.email}
                helperText={errors?.email?.message}
                id="outlined-email-input"
              />
            )}
          />
        </>
      )}
      {!isLearner && (
        <>
          <Controller
            name="role"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <FormControl className={styles.select} error={!!errors?.role}>
                <InputLabel htmlFor="user-role-outlined-select-label">
                  Role
                </InputLabel>
                <Select
                  {...field}
                  data-testid="user-role-select"
                  input={
                    <OutlinedInput
                      label="Role"
                      id="user-role-outlined-select-label"
                    />
                  }
                >
                  {staffRolesOptions.map(({ value, label }) => (
                    <MenuItem
                      key={value}
                      value={value}
                      data-testid="user-role-option"
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <If condition={!!errors?.role}>
                  <FormHelperText>
                    {ErrorMessages.FailedRequiredField}
                  </FormHelperText>
                </If>
              </FormControl>
            )}
          />
          <Controller
            name="status"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <FormControl className={styles.select} error={!!errors?.status}>
                <InputLabel htmlFor="user-status-outlined-select-label">
                  Status
                </InputLabel>
                <Select
                  {...field}
                  data-testid="user-status-select"
                  input={
                    <OutlinedInput
                      label="Status"
                      id="user-status-outlined-select-label"
                    />
                  }
                >
                  {userStatusOptions.map(({ value, label }) => (
                    <MenuItem
                      key={value}
                      value={value}
                      data-testid="user-status-option"
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <If condition={!!errors?.status}>
                  <FormHelperText>
                    {ErrorMessages.FailedRequiredField}
                  </FormHelperText>
                </If>
              </FormControl>
            )}
          />
          <Controller
            rules={{
              validate: (value) => {
                if (!value) {
                  return ErrorMessages.FailedRequiredField;
                }
                if (!isEmailValid(value)) {
                  return ErrorMessages.NotValidEmail;
                }

                return true;
              }
            }}
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                disabled
                label="Email"
                margin="normal"
                error={!!errors?.email}
                aria-invalid={!!errors?.email}
                helperText={errors?.email?.message}
                id="outlined-email-input"
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                label="Phone number"
                margin="normal"
                id="outlined-phone-number-input"
              />
            )}
          />
        </>
      )}
      <div className={styles.footer}>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={!isDirty}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
