import React, { FC, useEffect, useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import cn from 'classnames';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Repertoire } from 'entities/Repertoire.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { useRepertoires } from 'hooks/Task/useRepertoires';
import { useSharedLibraryContext } from 'hooks/Task/useSharedLibraryContext';

import styles from './RepertoireAccordion.module.scss';

export const RepertoireAccordion: FC<React.PropsWithChildren<unknown>> = () => {
  const { activeRepertoire, onChangeActiveRepertoire, onChangeActiveTask } =
    useSharedLibraryContext();
  const { data, isError, isLoading } = useRepertoires();

  const repertoires = useMemo(() => {
    const allRepertoireOption = Repertoire.deserialize({
      title: 'All',
      id: undefined
    });

    return [allRepertoireOption, ...(data || [])];
  }, [data]);

  useEffect(() => {
    if (!repertoires?.length || activeRepertoire) {
      return;
    }

    onChangeActiveRepertoire(repertoires[0]);
  }, [activeRepertoire, onChangeActiveRepertoire, repertoires]);

  const repertoireList = useMemo(
    () =>
      repertoires?.map((repertoire) => {
        const { id, title } = repertoire;
        const isActive = activeRepertoire?.id === id;

        const onSelectRepertoire = () => {
          onChangeActiveRepertoire(repertoire);
          onChangeActiveTask(null);
        };

        return (
          <ListItem
            button
            key={id + title}
            className={cn(
              styles['accordion-list-item'],
              isActive && styles.active
            )}
            onClick={onSelectRepertoire}
            data-testid="accordion-list-item"
          >
            <ListItemText primary={title} />
          </ListItem>
        );
      }),
    [
      activeRepertoire,
      onChangeActiveRepertoire,
      onChangeActiveTask,
      repertoires
    ]
  );

  return (
    <div className={styles['repertoire-accordion']}>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />

      <Accordion
        className={styles.accordion}
        defaultExpanded={!isLoading}
        classes={{
          root: styles['accordion-root']
        }}
      >
        <AccordionSummary
          className={styles['accordion-summary']}
          expandIcon={<ExpandMoreIcon className={styles['accordion-icon']} />}
          aria-controls="repertoire-content"
          id="accordion-header"
          data-testid="accordion-summary"
        >
          Content Area
        </AccordionSummary>
        <AccordionDetails
          className={styles['accordion-details']}
          data-testid="accordion-details"
        >
          <List
            aria-label="content area options"
            className={styles['accordion-list']}
          >
            {repertoireList}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
