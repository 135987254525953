export enum ApiMatchErrors {
  'user.emailNotFound' = "There doesn't seem to be an account with that email. Please enter a valid email, or if you need more help reach out to our customer team",
  'user.isDeactivated' = 'You do not have access to CR CareCoordinator. Please contact your administrator with questions.',
  'user.isInactive' = 'You do not have access to CR CareCoordinator. Please contact your administrator with questions.',
  'user.isMigrated' = 'User Migrated',
  'user.oldPasswordIsNotValid' = 'Old password is not valid',
  'user.passwordIsEqualToSomeFields' = 'The password must be different from your username and previous password.',
  'user.usernameIsNotUnique' = 'Username is already taken',
  'user.phoneNumberIsNotValid' = 'Phone number is not valid',
  'user.cannotDeactivateYourself' = 'Sorry, you can not deactivate yourself. Deselect yourself and try again.',
  'emailIsNotUnique' = 'Email is already taken',
  'organization.admin.emailIsNotUnique' = 'Email is already taken',
  'taskSchedule.scheduledTimeConflict' = "It's not allowed to schedule the same lesson twice to the same time and date",
  'auth.userCredentialsAreNotValid' = 'The email, username or password you entered is invalid',
  'auth.userWithEmailNotFound' = 'The provided email address does not exist in our system. Please enter a valid email, or contact our support team for assistance.',
  'validationFailed' = 'Sorry, your input fields are not valid',
  'requestNotFound' = 'This password link is no longer valid.',
  'requestIsExpired' = 'This password link is no longer valid.',
  'requestAlreadyUsed' = 'This password link is no longer valid.',
  'requestIsNotActive' = 'This password link is no longer valid.',
  'task.notFound' = 'Lesson not found',
  'organization.outOfLimits' = "You don't have enough licenses to create another user. Please reach out to CareCoordinator team to add more licenses.",
  'organization.IdentityOrganizationAlreadyAssociated' = 'This organization is already associated with a CareCoordinator organization.',
  'organization.identityNotFound' = 'No organization found for this ID in the Identity system',
  'user.organization.isDeactivated' = 'It looks like you are trying to log in to an account with an inactive organization. Please reach out to us so that we can help you get set back up.',
  'file.videoDurationLimit' = 'The duration of the video should not be more than 5 mins',
  'user.verifyEmailRequestNotFound' = 'This link is not valid.',
  'user.verifyEmailUsernameNotFound' = 'This username does not match the account for this email.\nPlease try again or contact your organization for assistance.',
  'user.verifyEmailRequestExpired' = 'The account verification link is not valid anymore.',
  'user.resendEmailVerificationUserIdNotFound' = 'This user id is invalid. Please contact your organization for assistance.',
  'user.invalidPhoneNumber' = 'Invalid Phone number'
}
