import {
  custom,
  date,
  deserialize,
  optional,
  serializable,
  SKIP
} from 'serializr';
// eslint-disable-next-line import/no-cycle
export class Comments {
  @serializable
  id: string = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt?: Date = new Date();

  @serializable(date())
  deletedAt?: Date = new Date();

  @serializable
  journalId: string = '';

  @serializable
  ownerId: string = '';

  @serializable
  body: string = '';

  @serializable
  userFullName: string = '';

  @serializable
  isCommentUnread: boolean = false;

  @serializable(
    optional(
      custom(
        (value) => value?.name,
        () => SKIP
      )
    )
  )
  files?: string[] | null;

  @serializable(
    optional(
      custom(
        (value) => value?.name,
        () => SKIP
      )
    )
  )
  tags?: string[] | null;

  static deserialize(json: Object | string): Comments {
    return deserialize(Comments, json);
  }

  static deserializeAsList(list: Comments[]): Comments[] {
    return list.map(Comments.deserialize);
  }
}
