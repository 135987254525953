import React, { FC } from 'react';

import styles from './JournalCheckIn.module.scss';

interface Props {
  prompt: string;
  responses: Array<string> | null | undefined;
}

export const JournalCheckIn: FC<React.PropsWithChildren<Props>> = ({
  prompt,
  responses
}) => {
  return (
    <div key={prompt}>
      <div className={styles['checkin-prompt']}>{prompt}</div>
      {responses && responses.length > 1 && (
        <p className={styles['checkin-answer']}>
          Response(s): {responses.join(', ')}
        </p>
      )}
      {responses && responses.length === 1 && (
        <p className={styles['checkin-answer']}>
          Response: {responses.join(', ')}
        </p>
      )}
      {responses && responses.length === 0 && (
        <p className={styles['checkin-answer']}>Response: </p>
      )}
    </div>
  );
};
