import React, { FC } from 'react';
import { Row } from 'react-table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton
} from '@mui/material';
import CareIcon from 'assets/Favicon.png';
import folder from 'assets/folder.png';
import cn from 'classnames';
import { If } from 'components/If';
import { Task } from 'entities/Task.entity';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskColumn, useTasksContext } from 'hooks/Task/useTasksContext';

import styles from './FolderCell.module.scss';

interface Props {
  data: TaskColumn;
  row: Row<Task>;
  isDragIconVisible?: boolean;
  showCareIcon?: boolean;
  showRepertoire?: boolean;
}

export const FolderCell: FC<React.PropsWithChildren<Props>> = ({
  data,
  row,
  showCareIcon,
  isDragIconVisible = false,
  showRepertoire = false
}) => {
  const { onChangeActiveFolder, activeFolder, onChangeSearch, onChangeStatus } =
    useTasksContext();

  const resetFilters = () => {
    onChangeSearch('');
    onChangeStatus(TaskStatus.All);
  };

  const isCurrentActiveFolder = activeFolder?.id === data.id;

  return (
    <Grid
      container
      flexWrap="nowrap"
      alignItems="center"
      className={styles['folder-cell']}
    >
      {isCurrentActiveFolder ? (
        <IconButton
          id="back-button"
          data-testid="back-button"
          aria-label="Back to root table"
          className={styles['back-button']}
          onClick={() => {
            resetFilters();
            onChangeActiveFolder(null);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      ) : (
        <>
          <Checkbox
            checked={row.isSelected}
            data-testid="select-checkbox"
            onChange={row.getToggleRowSelectedProps().onChange}
            inputProps={{
              'aria-label': 'Select module'
            }}
          />
          <If condition={isDragIconVisible}>
            <DragIndicatorIcon className={styles['drag-icon']} />
          </If>
        </>
      )}
      <span className={styles['info-wrap']}>
        <Grid
          container
          spacing={1}
          flexWrap="nowrap"
          alignItems="center"
          flexDirection="row"
        >
          <Grid item className={styles.image}>
            <Avatar src={folder} alt={`${data.title} module`} />
            <If condition={!!showCareIcon && data.availLibraryTaskReflection}>
              <img
                alt="CareCoordinator library"
                src={CareIcon}
                className={styles.icon}
              />
            </If>
          </Grid>
          <Grid item className={styles.info}>
            {isCurrentActiveFolder ? (
              <>
                <h5 className={styles.title} title={data.title}>
                  {data.title}
                </h5>
                <If condition={showRepertoire}>
                  <Chip
                    size="small"
                    className={styles.chip}
                    label={data.repertoireName}
                  />
                  <span className={styles.subtitle}>
                    {`${data.tasksAmount} lessons`}
                  </span>
                </If>
              </>
            ) : (
              <>
                <Button
                  className={cn(styles.title, styles.button)}
                  onClick={() => {
                    resetFilters();
                    onChangeActiveFolder(data);
                  }}
                  title={data.title}
                  disableRipple
                >
                  <span>{data.title}</span>
                </Button>
                <If condition={showRepertoire}>
                  <Chip
                    size="small"
                    className={styles.chip}
                    label={data.repertoireName}
                  />
                </If>
                <span className={styles.subtitle}>
                  {`${data.tasksAmount} lessons`}
                </span>
              </>
            )}
          </Grid>
        </Grid>
      </span>
    </Grid>
  );
};
