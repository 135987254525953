import React, { FC, useCallback } from 'react';
import { useQuery } from 'react-query';
import { fetchTaskActivitiesGroup } from 'actions/Task/taskActions';
import { SubRows } from 'components/DataTable/Row';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { SimpleTable } from 'components/SimpleTable';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { endOfDay } from 'date-fns';
import { ActivityGroup } from 'entities/ActivityGroup.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';

import { collapsableColumns, reportColumns } from './columns';
import { DurationTabSubRows } from './DurationTabSubRows';

import styles from './DurationTab.module.scss';

interface Props {
  taskId: Task['id'];
  isRowExpanded?: boolean;
  hideEmptyState?: boolean;
  fromTime?: Date;
  toTime?: Date;
}

export const DurationTab: FC<React.PropsWithChildren<Props>> = ({
  taskId,
  isRowExpanded,
  hideEmptyState,
  fromTime,
  toTime
}) => {
  const toTimeFilter = toTime
    ? endOfDay(toTime).toISOString()
    : new Date().toISOString();

  const { data, isLoading, isError } = useQuery(
    fromTime
      ? queryKeys.taskActivityGroupDateRange(taskId, toTimeFilter)
      : queryKeys.taskActivityGroup(taskId),
    async () => {
      const activityGroups: ActivityGroup[] = await fetchTaskActivitiesGroup({
        taskId,
        limit: MAX_PAGE_LIMIT,
        fromTime: toTimeFilter
      });

      return activityGroups.map((activityGroup) => ({
        id: `${activityGroup.month}-${activityGroup.year}`,
        ...activityGroup
      }));
    },
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  const filterData = () => {
    if (!data) return [];
    return fromTime
      ? data.filter(
          (ta) =>
            new Date(ta.year, ta.month) >=
            new Date(fromTime?.getFullYear(), fromTime?.getMonth())
        )
      : data;
  };

  const renderSubRows = useCallback(
    ({ row }: SubRows) => (
      <DurationTabSubRows
        row={row}
        taskId={taskId}
        hideEmptyState={hideEmptyState}
        fromTime={fromTime}
        toTime={toTime}
      />
    ),
    [hideEmptyState, taskId, fromTime, toTime]
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles.container}>
        <LoadingOverlay loading={isLoading}>
          <If condition={!isLoading}>
            <SimpleTable
              isLoading={isLoading}
              data={filterData()}
              isRowExpanded={isRowExpanded}
              renderSubRows={renderSubRows}
              columns={isRowExpanded ? reportColumns : collapsableColumns}
              emptyMessage={EmptyStateMessages.Activities}
            />
          </If>
        </LoadingOverlay>
      </div>
    </>
  );
};
