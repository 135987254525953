import React, { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper as StepperComponent
} from '@mui/material';
import { If } from 'components/If';

import styles from './Stepper.module.scss';

interface Step {
  label: string;
  component: JSX.Element;
}

interface Props {
  steps: Step[];
  activeStep: number;
  onSave: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  handleNext: () => void;
  handleBack: () => void;
}

export const Stepper: FC<React.PropsWithChildren<Props>> = ({
  steps,
  onSave,
  onCancel,
  handleNext,
  handleBack,
  isLoading = false,
  activeStep
}) => {
  return (
    <div className={styles.stepper}>
      <StepperComponent
        alternativeLabel
        data-testid="stepper"
        activeStep={activeStep}
      >
        {steps.map(({ label }) => (
          <Step key={label}>
            <StepLabel data-testid="step-label">{label}</StepLabel>
          </Step>
        ))}
      </StepperComponent>
      <div className={styles.content}>
        <div className={styles.header}>
          <If condition={activeStep !== 0}>
            <IconButton
              color="secondary"
              onClick={handleBack}
              aria-label="Go to prev step"
              data-testid="back-button"
              className={styles.icon}
            >
              <ArrowBackIcon />
            </IconButton>
          </If>
          <h1 className={styles.label} data-testid="step-header">
            {steps[activeStep].label}
          </h1>
        </div>
        {steps[activeStep].component}
        <div className={styles.footer}>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              {activeStep === steps.length - 1 ? (
                <LoadingButton
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={onSave}
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleNext}
                >
                  Next step
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
