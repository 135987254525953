import React, { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import {
  generatePath,
  NavLink,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LaunchIcon from '@mui/icons-material/Launch';
import { Avatar, Link, MenuItem } from '@mui/material';
import logo from 'assets/main-logo.svg';
import { IconMenu } from 'components/IconMenu';
import { PermissionsGate } from 'components/PermissionsGate';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { Routes, RoutesLabels } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { OIDC_CONFIG } from 'utils/oidcConfig';

import { NavTab } from '../NavTab';

import styles from './NavigationHeader.module.scss';

export const NavigationHeader: FC<React.PropsWithChildren<unknown>> = () => {
  const { logOut } = useAuthContext();
  const { data } = useLoadAuthUserData();

  const auth = useAuth();

  const routeMatch = useRouteMatch([Routes.Organizations, Routes.Library]);
  const currentTab = routeMatch?.path;

  const history = useHistory();

  const shouldDisplayChangeActions = data?.status === EntityStatus.Active;

  return (
    <header className={styles['navigation-header']}>
      <img
        src={logo}
        alt="CareCoordinator logo"
        data-testid="logo"
        className={styles.logo}
      />
      <nav className={styles.tabs} data-testid="navigation">
        <PermissionsGate scopes={[UserScopes.ViewOrganizations]}>
          <NavTab
            exact
            component={NavLink}
            to={Routes.Organizations}
            className={styles.tab}
            label={RoutesLabels[Routes.Organizations]}
            active={currentTab === Routes.Organizations}
          />
        </PermissionsGate>
        <PermissionsGate scopes={[UserScopes.ViewAvailLibrary]}>
          <NavTab
            exact
            component={NavLink}
            to={Routes.Library}
            className={styles.tab}
            label={RoutesLabels[Routes.Library]}
            active={currentTab === Routes.Library}
          />
        </PermissionsGate>
      </nav>
      {auth.isAuthenticated && (
        <div className={styles['user-info']}>
          <Avatar src={data?.avatarUrl} alt="user-avatar" />
          <span className={styles.name}>{data?.fullName}</span>
          <span className={styles.divider} />
          <IconMenu
            icon={<KeyboardArrowDownIcon className={styles['icon-down']} />}
            buttonLabel="Open Dropdown Menu to Access Account Info or Logout"
          >
            {shouldDisplayChangeActions && (
              <PermissionsGate scopes={[UserScopes.AccessApp]}>
                {data && (
                  <MenuItem
                    onClick={() => history.push(generatePath(Routes.Account))}
                  >
                    My Account
                  </MenuItem>
                )}
                <MenuItem role="none">
                  <Link
                    role="menuitem"
                    underline="none"
                    href={`${OIDC_CONFIG.authority}/profile/user/security`}
                    target="_blank"
                    style={{ color: 'initial' }}
                  >
                    Change Password
                    <LaunchIcon
                      titleAccess="Change password, open in new tab"
                      fontSize="small"
                      style={{
                        verticalAlign: 'sub',
                        display: 'inline',
                        marginLeft: '5px'
                      }}
                    />
                  </Link>
                </MenuItem>
                <MenuItem role="none">
                  <Link
                    role="menuitem"
                    underline="none"
                    href={`${OIDC_CONFIG.authority}/profile/user/change-email`}
                    target="_blank"
                    style={{ color: 'initial' }}
                  >
                    Change Email
                    <LaunchIcon
                      titleAccess="Change email, open in new tab"
                      fontSize="small"
                      style={{
                        verticalAlign: 'sub',
                        display: 'inline',
                        marginLeft: '5px'
                      }}
                    />
                  </Link>
                </MenuItem>
                <div
                  role="none"
                  className={styles.divider}
                  style={{
                    paddingLeft: '1rem',
                    paddingRight: '1rem'
                  }}
                >
                  <hr aria-hidden="true" />
                </div>
              </PermissionsGate>
            )}
            <MenuItem onClick={logOut}>Logout</MenuItem>
          </IconMenu>
        </div>
      )}
    </header>
  );
};
