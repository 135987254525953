import React, { FC } from 'react';
import { Avatar, Badge } from '@mui/material';
import journalCommentNotificationImage from 'assets/journal-comment-notification.svg';
import journalNotificationImage from 'assets/journal-notification.svg';

import styles from './JournalNotificationPanel.module.scss';

interface Props {
  countJournals?: number;
  countComments?: number;
}
export const JournalNotificationPanel: FC<React.PropsWithChildren<Props>> = ({
  countJournals,
  countComments
}) => {
  const JournalIcon = (image: string, value?: number, alt?: string) => (
    <Badge
      badgeContent={value}
      color="primary"
      sx={{
        '& .MuiBadge-badge': {
          color: 'white',
          backgroundColor: '#b3261e',
          visibility: value && value > 0 ? 'visible' : 'hidden'
        }
      }}
      max={99}
    >
      <Avatar
        alt={alt}
        variant="square"
        src={image}
        style={{ width: '20px', height: '20px', marginLeft: '1rem' }}
        sx={{
          visibility: value && value > 0 ? 'visible' : 'hidden'
        }}
      />
    </Badge>
  );

  return (
    <>
      {JournalIcon(
        journalNotificationImage,
        countJournals,
        `${countJournals} new journal entries`
      )}
      {JournalIcon(
        journalCommentNotificationImage,
        countComments,
        `${countComments} new journal comments`
      )}
    </>
  );
};
