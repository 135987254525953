import React, { FC } from 'react';
import { useQuery } from 'react-query';
import {
  generatePath,
  NavLink,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { Circle } from '@mui/icons-material';
import FlagIcon from '@mui/icons-material/Flag';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Badge, Breadcrumbs, Button, Chip, Tab } from '@mui/material';
import { fetchUserData } from 'actions/User/userActions';
import cn from 'classnames';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { UserRoleLabels, UserRoles } from 'enums/UserRoles.enum';
import { UserTabs, UserTabsLabels } from 'enums/UserTabs.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useJournalSummaryNotification } from 'hooks/Notification/useNotification';
import {
  useErrorRedirectToPeople,
  useUnauthorizedRedirectToPeople
} from 'hooks/useHandleErrorRedirect';
import * as queryString from 'query-string';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { isTabVisible } from 'utils/helpers/userTabHelpers';
import { canShowJournal } from 'utils/journalHelpers';
import { Assessments } from 'views/People/Assessments';
import { LearnerAssociations } from 'views/People/Associations/LearnerAssociations';
import { StaffAssociations } from 'views/People/Associations/StaffAssociations';
import { JournalEntries } from 'views/People/JournalEntries/JournalEntries';
import { PersonalInformation } from 'views/People/PersonalInformation';
import { LearnerTaskLibrary } from 'views/People/TaskLibrary/LearnerTaskLibrary';
import { StaffTaskLibrary } from 'views/People/TaskLibrary/StaffTaskLibrary';

import styles from './UserDetails.module.scss';

export const UserDetails: FC<React.PropsWithChildren<unknown>> = () => {
  const { slugId, slug, id } = useParams<{
    id: string;
    slugId: string;
    slug: string;
  }>();

  const history = useHistory();

  const query = queryString.parse(history.location.search);
  const parseTabFromQueryString = (): UserTabs | undefined => {
    if (query.tab) {
      const tab = query.tab as UserTabs;
      if (Object.values(UserTabs).includes(tab)) {
        return tab;
      }
    }
    return undefined;
  };

  const [activeTab, setActiveTab] = React.useState<UserTabs>(
    parseTabFromQueryString() ?? UserTabs.PersonalInformation
  );

  const [canFetch, setCanFetch] = React.useState(false);

  const { data: userData } = useLoadAuthUserData();
  const routeMatch = useRouteMatch({
    path: Routes.OrganizationStaff,
    strict: true,
    sensitive: true
  });

  useErrorRedirectToPeople(() => {
    if (
      userData?.role === UserRoles.Facilitator ||
      userData?.role === UserRoles.PassiveFacilitator
    ) {
      if (routeMatch) {
        setCanFetch(false);
        return true;
      }
    }
    setCanFetch(true);
    return false;
  });

  const handleChange = (_e: React.SyntheticEvent, newValue: UserTabs) => {
    setActiveTab(newValue);

    const params = new URLSearchParams(history.location.search);
    params.set('tab', newValue);
    history.push({
      search: params.toString()
    });
  };

  const { isError, error, isFetching, data } = useQuery<User, ApiError>(
    queryKeys.user(id),
    () => fetchUserData(id),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      enabled: canFetch
    }
  );

  useUnauthorizedRedirectToPeople(error);

  const shouldShowJournal = canShowJournal(userData?.role as UserRoles);

  const { data: notifications } = useJournalSummaryNotification(
    userData?.id || '',
    shouldShowJournal
  );

  if (!data) {
    return isFetching ? (
      <AbsoluteSpinner loading={isFetching} />
    ) : (
      <EmptyState />
    );
  }

  const anyJournalNotifications =
    notifications?.caregiversIds?.includes(data.id) ?? false;

  const { fullName, role, formattedCreatedAt, isLearner, id: userId } = data;

  const getVisibleTabs = () => {
    if (!shouldShowJournal) {
      return Object.values(UserTabs).filter((tab) => tab !== UserTabs.Journal);
    }
    return Object.values(UserTabs);
  };

  const breadcrumbs = [
    <NavLink
      key="users"
      to={generatePath(
        isLearner ? Routes.OrganizationLearners : Routes.OrganizationStaff,
        {
          slugId,
          slug
        }
      )}
    >
      {isLearner ? 'Caregivers' : 'Staff'}
    </NavLink>,
    <div key="user">
      {fullName}{' '}
      {!isLearner && (
        <Chip
          size="small"
          label={UserRoleLabels[role]}
          className={styles.chip}
        />
      )}
    </div>
  ];
  return (
    <div className={styles['user-details']}>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles['additional-info']}>
        <Breadcrumbs
          separator="›"
          aria-label="user breadcrumb"
          className={styles.breadcrumbs}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <div>
          <span className={styles['date-title']}>
            Account created:
            <span className={styles.date}>{` on ${formattedCreatedAt}`}</span>
          </span>
          <If condition={isLearner}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              target="_blank"
              href={generatePath(Routes.LearnerReport, {
                slug,
                slugId,
                id
              })}
              startIcon={<FlagIcon />}
            >
              Generate report
            </Button>
          </If>
        </div>
      </div>
      <TabContext value={activeTab}>
        <TabList
          scrollButtons
          variant="scrollable"
          aria-label="User tabs"
          className={styles.tabs}
          onChange={handleChange}
        >
          {getVisibleTabs().map((value) => (
            <Tab
              key={value}
              label={
                <>
                  {UserTabsLabels[value]}
                  <If
                    condition={
                      value === UserTabs.Journal && anyJournalNotifications
                    }
                  >
                    <Badge color="primary">
                      <Circle className={styles.circle} />
                    </Badge>
                  </If>
                </>
              }
              data-testid="user-tab"
              value={value}
              className={cn(
                styles.tab,
                !isTabVisible(role, value as UserTabs) && styles.hidden
              )}
            />
          ))}
        </TabList>
        <TabPanel
          value={UserTabs.PersonalInformation}
          className={styles['tab-panel']}
        >
          <PersonalInformation data={data} />
        </TabPanel>
        <TabPanel value={UserTabs.Associations} className={styles['tab-panel']}>
          {isLearner ? (
            <LearnerAssociations userId={data.id} />
          ) : (
            <StaffAssociations userId={data.id} />
          )}
        </TabPanel>
        <TabPanel value={UserTabs.TaskLibrary} className={styles['tab-panel']}>
          {isLearner ? (
            <LearnerTaskLibrary user={data} />
          ) : (
            <StaffTaskLibrary user={data} />
          )}
        </TabPanel>
        <TabPanel value={UserTabs.Assessments} className={styles['tab-panel']}>
          <Assessments user={data} />
        </TabPanel>
        {shouldShowJournal && (
          <TabPanel value={UserTabs.Journal} className={styles['tab-panel']}>
            <JournalEntries caregiverId={data.id} />
          </TabPanel>
        )}
      </TabContext>
    </div>
  );
};
