import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Chip } from '@mui/material';
import { fetchTaskSteps } from 'actions/Task/taskActions';
import cn from 'classnames';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Step } from 'entities/Step.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { DurationTab } from 'views/People/TaskLibrary/DurationTab';
import { EvidenceTab } from 'views/People/TaskLibrary/EvidenceTab';
import { ReductionTab } from 'views/People/TaskLibrary/ReductionTab';
import { ReportStepCard } from 'views/Reports/ReportStepCard';

import styles from './ReportTask.module.scss';

interface Props {
  task: Task;
  fromTime?: Date;
  toTime?: Date;
}

export const ReportTask: FC<React.PropsWithChildren<Props>> = ({
  task,
  fromTime,
  toTime
}) => {
  const { id, title, stepsAmount } = task;

  const hideXAxisTick = true;
  const {
    data: steps,
    isError,
    isLoading,
    error
  } = useQuery<Step[], ApiError>(
    queryKeys.taskSteps(id),
    () => fetchTaskSteps(id),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  if (!stepsAmount) {
    return null;
  }

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={error?.errorMessage || ErrorMessages.FailedGetRequest}
      />

      <div className={styles['report-task']}>
        <div className={cn(styles['overview-section'], styles.print)}>
          <h4 className={styles.title}>
            {`Lesson overview: ${title}`}
            <Chip
              size="small"
              className={styles.chip}
              label={`${stepsAmount} steps`}
            />
          </h4>

          <LoadingOverlay loading={isLoading}>
            <div className={styles.steps} data-testid="task-steps">
              {(steps || [])
                .filter((step) => step.active)
                .map((step) => (
                  <ReportStepCard key={step.id} step={step} />
                ))}
            </div>
          </LoadingOverlay>
        </div>

        <div className={styles.section}>
          <EvidenceTab isReport taskId={id} stickyHeader={false} />
        </div>

        <div className={cn(styles.section, styles.print)}>
          <h4 className={styles.title}>Prompt reduction</h4>
          <ReductionTab
            taskId={id}
            className={styles['reduction-tab']}
            hideXAxisTick={hideXAxisTick}
          />
        </div>

        <div className={styles.section}>
          <h4 className={styles.title}>Duration</h4>
          <DurationTab
            hideEmptyState
            isRowExpanded
            taskId={id}
            fromTime={fromTime}
            toTime={toTime}
          />
        </div>
      </div>
    </>
  );
};
