import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { createTask } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskType } from 'enums/TaskType.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { queryClient } from 'queryClient';
import { FolderForm, FormData } from 'views/Library/FolderForm';

interface Options {
  createdById?: string;
  assignedToEq?: string;
  organizationIdEq?: string | null;
  publishStatus?: TaskStatus;
}

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  options?: Options;
  hideIconField?: boolean;
  hideDescriptionField?: boolean;
}

export const CreateFolderModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal,
  options,
  hideIconField,
  hideDescriptionField
}) => {
  const { data: userData } = useLoadAuthUserData();

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    Task,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      createTask({
        title: formData.title,
        repertoireId: formData.repertoireId,
        description: formData.description || '',
        image: formData.image,
        type: TaskType.Group,
        sharable: false,
        publishStatus: options?.publishStatus || TaskStatus.Published,
        createdById: options?.createdById || userData!.id,
        organizationId: options?.organizationIdEq,
        assignedToId: options?.assignedToEq
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.folders);
        await queryClient.invalidateQueries(queryKeys.tasksList);

        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Module successfully created"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal title="Create Module" isOpen={isOpen} onCloseModal={onCloseModal}>
        <FolderForm
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
          isLoading={isLoading}
          saveButtonText="Create Module"
          hideIconField={hideIconField}
          hideDescriptionField={hideDescriptionField}
        />
      </Modal>
    </>
  );
};
