import React, { ChangeEvent } from 'react';
import { Search } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { If } from 'components/If';
import {
  TaskAssignStatus,
  taskAssignStatusOptions
} from 'enums/TaskAssignStatus.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';

import styles from './OrganizationLibraryActionsBar.module.scss';

interface Props {
  searchValue: string;
  searchLabel?: string;
  assignStatus: TaskAssignStatus;
  isOnlyMyStuff: boolean;
  onChangeSearchValue: (value: string) => void;
  onChangeAssignStatus: (e: SelectChangeEvent<string>) => void;
  onChangeIsOnlyMyStuff: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const OrganizationLibraryActionsBar: React.FC<
  React.PropsWithChildren<Props>
> = ({
  assignStatus,
  searchValue,
  searchLabel,
  isOnlyMyStuff,
  onChangeSearchValue,
  onChangeAssignStatus,
  onChangeIsOnlyMyStuff
}) => {
  const { data: userData } = useLoadAuthUserData();

  return (
    <Grid container className={styles.container}>
      <Grid item xs={2}>
        <FormControl className={styles.select}>
          <InputLabel htmlFor="task-status-filter">Filter</InputLabel>
          <Select
            size="small"
            value={assignStatus}
            data-testid="action-bar-select"
            onChange={onChangeAssignStatus}
            defaultValue={taskAssignStatusOptions[0]?.value}
            input={<OutlinedInput label="Filter" id="task-status-filter" />}
          >
            {taskAssignStatusOptions.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
                data-testid="action-bar-option"
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <TextField
        size="small"
        role="search"
        label="Search"
        variant="outlined"
        value={searchValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChangeSearchValue(e.target.value)
        }
        className={styles.search}
        id="outlined-search-input"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search className={styles.icon} />
            </InputAdornment>
          )
        }}
        inputProps={{
          'aria-label': `${searchLabel || 'Search'}`
        }}
      />

      <If condition={!!userData?.hasAdminRights}>
        <Grid container alignItems="center" xs="auto">
          <Typography className={styles.label}>Only my stuff</Typography>
          <Switch
            color="primary"
            checked={isOnlyMyStuff}
            onChange={onChangeIsOnlyMyStuff}
            inputProps={{
              'aria-label': 'Only my stuff'
            }}
          />
        </Grid>
      </If>
    </Grid>
  );
};
