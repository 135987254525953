import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { loadMe, logout } from 'actions/Auth/authActions';
import { fetchOrganizationDetails } from 'actions/Organization/organizationActions';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { AuthEvents } from 'enums/AuthEvents.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useActivityContext } from 'hooks/Auth/useActivityContext';
import { AuthContextProvider, UserData } from 'hooks/Auth/useAuthContext';
import { LocalStorage } from 'services/LocalStorage';
import { hasPermission, usePermissions } from 'services/Permissions';
import { AUTH_TOKEN_KEY } from 'utils/constants';
import { clearStorage } from 'utils/helpers/authHelpers';
import { getRedirectUrl } from 'utils/helpers/urlHelper';
import { OIDC_CONFIG } from 'utils/oidcConfig';

import { AuthPublisher } from './AuthPublisher';

declare global {
  interface Window {
    pendo: {
      initialize: (data: object) => void;
    };
  }
}

const { pendo } = window;

export const AppAuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const token = LocalStorage.getItem(AUTH_TOKEN_KEY);
  const permissions = usePermissions();
  const queryClient = useQueryClient();

  const navigate = useHistory();

  const auth = useAuth();

  const { startActivity, stopActivity, overrideActivity } =
    useActivityContext();

  const [isAuthenticated, setAuthenticated] = useState<boolean>(!!token);

  const onReset = useCallback(() => {
    clearStorage();
    queryClient.removeQueries();
    setAuthenticated(false);
  }, [queryClient]);

  const onExpireToken = useCallback(() => {
    overrideActivity();
    onReset();
  }, [onReset, overrideActivity]);

  useEffect(() => {
    const tokenExpiredObserver = {
      event: AuthEvents.TokenExpired,
      update: onExpireToken
    };

    AuthPublisher.subscribe(tokenExpiredObserver);

    return () => {
      AuthPublisher.unsubscribe(tokenExpiredObserver);
    };
    // eslint-disable-next-line
  }, []);

  const {
    error,
    isLoading,
    mutate: logIn
  } = useMutation<User, ApiError, UserData>(
    async () => {
      const meData = await loadMe();
      const organizationData = meData.organizationId
        ? await fetchOrganizationDetails(meData.organizationId || '')
        : null;

      if (!hasPermission(permissions[meData.role], [UserScopes.AccessApp])) {
        throw ApiError.deserialize({
          message: ErrorMessages.FailedRoleLogin,
          statusCode: 401
        });
      }
      queryClient.setQueryData(queryKeys.authenticatedUserData, meData);

      if (
        typeof meData !== 'undefined' &&
        typeof organizationData !== 'undefined' &&
        meData.organizationId &&
        meData.role &&
        meData.role !== 'super_admin' &&
        organizationData?.name != null
      ) {
        const visitorId = `${process.env.REACT_APP_ENVIRONMENT}-${meData.id}`;
        const accountId = `${process.env.REACT_APP_ENVIRONMENT}-${meData.organizationId}`;
        const totalStaffLicenses =
          organizationData.managersAmountLimit +
          organizationData.facilitatorsAmountLimit +
          organizationData.passiveFacilitatorsAmountLimit;

        pendo.initialize({
          visitor: {
            id: visitorId,
            role: meData.role,
            name: meData.fullName,
            organizationId: meData.organizationId,
            email: meData.email
          },

          account: {
            id: accountId,
            name: organizationData?.name,
            totalLicenses: organizationData?.totalUsersAmountLimit,
            staffLicenses: totalStaffLicenses,
            learnersLicenses: organizationData?.learnersAmountLimit,
            status: organizationData?.status,
            type: organizationData?.type,
            description: organizationData?.description,
            address: organizationData?.address
          }
        });
      } else {
        global.console.error('No Pendo data');
      }

      return meData;
    },
    {
      onSuccess() {
        setAuthenticated(true);
        startActivity();
      },
      onError() {
        onReset();
      }
    }
  );

  const { isLoading: logOutIsLoading, mutateAsync: logOut } = useMutation<
    void,
    ApiError
  >(logout, {
    onSettled: () => {
      onReset();
    }
  });

  const onLogout = async () => {
    await stopActivity(true);
    if (!auth.activeNavigator && !auth.isAuthenticated) {
      navigate.push('/');
    } else if (!auth.activeNavigator) {
      const state = getRedirectUrl(
        navigate.location.pathname,
        navigate.location.search
      );
      await auth.signoutRedirect({
        state,
        post_logout_redirect_uri: `${OIDC_CONFIG.post_logout_redirect_uri}?state=${state}`
      });
    }
  };

  return (
    <AuthContextProvider
      value={{
        isAuthenticated,
        isLoading: isLoading || logOutIsLoading,
        error: error?.errorMessage,
        logIn,
        logOut: onLogout
      }}
    >
      {children}
    </AuthContextProvider>
  );
};
