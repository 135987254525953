import React, { FC } from 'react';
import cn from 'classnames';

import styles from './AssociationSection.module.scss';

interface Props {
  label: string;
  className?: string;
}

export const AssociationSection: FC<React.PropsWithChildren<Props>> = ({
  children,
  label,
  className
}) => (
  <div className={cn(styles['association-section'], className)}>
    <p className={styles.label} data-testid="association-title">
      {label}
    </p>
    <div className={styles.content}>{children}</div>
  </div>
);
