import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { verifyIdentityOrgId } from 'actions/Organization/organizationActions';
import { FileUploader } from 'components/FileUploader';
import { If } from 'components/If';
import { ApiError } from 'entities/ApiError.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import {
  OrganizationType,
  OrganizationTypeLabels
} from 'enums/OrganizationType.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { VerifyIdentityAssociationResponse } from 'services/API/Organization/OrganizationApi';

import styles from './CreateOrganizationDetails.module.scss';

const organizationTypes = Object.values(OrganizationType).map((value) => ({
  value,
  label: OrganizationTypeLabels[value]
}));

interface Props {
  onIsLoading: (status: boolean) => void;
}
export const CreateOrganizationDetails: FC<React.PropsWithChildren<Props>> = ({
  onIsLoading
}) => {
  const {
    control,
    setError,
    clearErrors,
    watch,
    formState: { errors }
  } = useFormContext();

  const identityOrgId = watch('identityOrgId');

  const guidPattern =
    /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;

  const {
    error: fetchingError,
    isFetching,
    data: identityOrg,
    refetch
  } = useQuery<VerifyIdentityAssociationResponse, ApiError>(
    queryKeys.verifyIdentityOrgId(identityOrgId),
    () => verifyIdentityOrgId(identityOrgId),
    {
      retry: 0,
      enabled: false,
      staleTime: 1000
    }
  );

  const validateIdentityOrgId = useCallback(
    (value: string) => {
      if (!!value && !guidPattern.test(value)) {
        return ErrorMessages.NotValidIdIdentityOrgId;
      }
      if (value === '') {
        return true;
      }
      return undefined;
    },
    [guidPattern]
  );

  useEffect(() => {
    onIsLoading(isFetching || !!fetchingError);
    if (isFetching) {
      clearErrors('identityOrgId');
    } else if (fetchingError) {
      setError('identityOrgId', {
        type: 'manual',
        message:
          fetchingError.errorMessage ?? ErrorMessages.NotValidIdIdentityOrgId
      });
    }
  }, [isFetching, onIsLoading, fetchingError, setError, clearErrors]);

  const onBlurIdentityId = () => {
    const inValid = validateIdentityOrgId(identityOrgId);
    clearErrors('identityOrgId');
    if (identityOrgId && !inValid) {
      refetch();
    } else if (identityOrgId && inValid) {
      setError('identityOrgId', {
        type: 'manual',
        message: ErrorMessages.NotValidIdIdentityOrgId
      });
    }
  };

  return (
    <>
      <Controller
        control={control}
        name="image"
        render={({ field: { onChange, value } }) => {
          return (
            <FileUploader
              fileData={value}
              onChangeFile={onChange}
              label="Organization photo"
            />
          );
        }}
      />
      <div className={styles['create-organization-details']}>
        <Controller
          rules={{ required: true }}
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              variant="outlined"
              margin="normal"
              inputRef={field.ref}
              error={!!errors?.name}
              label="Organization name"
              aria-invalid={!!errors?.name}
              inputProps={{ maxLength: 30 }}
              helperText={errors?.name && ErrorMessages.FailedRequiredField}
              id="outlined-organization-name-input"
            />
          )}
        />
        <Controller
          rules={{
            validate: (value) => validateIdentityOrgId(value)
          }}
          name="identityOrgId"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="Identity Organization ID (SSO)"
              margin="normal"
              inputRef={field.ref}
              variant="outlined"
              error={!!errors?.identityOrgId}
              id="outlined-identityOrgId-input"
              aria-invalid={!!errors?.identityOrgId}
              helperText={
                errors?.identityOrgId &&
                (fetchingError?.errorMessage ??
                  ErrorMessages.NotValidIdIdentityOrgId)
              }
              onBlur={onBlurIdentityId}
            />
          )}
        />
        <div>
          <If condition={isFetching}>
            <div>Validating...</div>
          </If>
          <If condition={!isFetching && !!identityOrg}>
            <em>Identity Name: {identityOrg?.identityOrganizationName}</em>
          </If>
        </div>
        <Controller
          rules={{ required: true }}
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="Address"
              margin="normal"
              variant="outlined"
              error={!!errors?.address}
              id="outlined-address-input"
              aria-invalid={!!errors?.address}
              helperText={errors?.address && ErrorMessages.FailedRequiredField}
            />
          )}
        />
        <Controller
          name="type"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <FormControl className={styles.form} error={!!errors?.type}>
              <InputLabel htmlFor="organization-type-outlined-select-label">
                Type of organization
              </InputLabel>
              <Select
                {...field}
                data-testid="organization-type-select"
                input={
                  <OutlinedInput
                    label="Type of organization"
                    id="organization-type-outlined-select-label"
                  />
                }
              >
                {organizationTypes.map(({ value, label }) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-testid="organization-type-option"
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <If condition={!!errors?.type}>
                <FormHelperText>
                  {ErrorMessages.FailedRequiredField}
                </FormHelperText>
              </If>
            </FormControl>
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              minRows={2}
              maxRows={4}
              type="text"
              margin="normal"
              variant="outlined"
              label="Description"
              aria-label="Description"
              inputProps={{ maxLength: 500 }}
              id="outlined-description-input"
            />
          )}
        />
      </div>
    </>
  );
};
