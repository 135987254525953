import React, { FC, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { isErrorRoute } from 'enums/Routes.enum';

export const AuthCallback: FC = () => {
  const loading = true;
  const navigate = useHistory();
  const auth = useAuth();
  useEffect(() => {
    auth.events.addUserLoaded((e) => {
      if (e.state && !isErrorRoute(e.state.toString())) {
        navigate.push(e.state.toString() || '/');
      } else {
        navigate.push('/');
      }
    });
    auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
    auth.events.addSilentRenewError(() => {
      auth.signoutRedirect();
    });
  }, [auth.events, auth.signinSilent, navigate, auth]);
  return <AbsoluteSpinner loading={loading} />;
};
