import React, { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem } from '@mui/material';
import { deleteTasks, duplicateTask } from 'actions/Task/taskActions';
import { IconMenu } from 'components/IconMenu';
import { If } from 'components/If';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useMessagesContext } from 'hooks/Auth/useMessagesContext';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { useTasksContext } from 'hooks/Task/useTasksContext';
import { queryClient } from 'queryClient';
import { DeleteTaskModal } from 'views/Task/DeleteTaskModal';

import styles from './SharedLibraryActionMenu.module.scss';

interface Props {
  task: Task;
}

export const SharedLibraryActionMenu: FC<React.PropsWithChildren<Props>> = ({
  task
}) => {
  const { showSnackBar } = useMessagesContext();
  const { id: taskId, type, title, tasksAmount } = task;

  const { data: organizationData } = useOrganizationBySlug();
  const { data: userData } = useLoadAuthUserData();
  const { activeFolder, onChangeActiveFolder } = useTasksContext();

  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);

  const onToggleDeleteTaskModal = useCallback(() => {
    setIsDeleteTaskModalOpen(
      (prevIsDeleteTaskModalOpen) => !prevIsDeleteTaskModalOpen
    );
  }, []);

  const {
    mutateAsync: onCopyToMyTasks,
    isError: isCopyToMyTasksError,
    error: copyToMyTasksError
  } = useMutation<Task, ApiError, Task['id']>(
    (taskId) =>
      duplicateTask(taskId, {
        sharable: false,
        organizationId: organizationData!.id,
        parentId: null,
        publishStatus: TaskStatus.Published
      }),
    {
      retry: 0,
      onSuccess: () => {
        showSnackBar({
          isSuccess: true,
          successMessage: 'Item successfully copied'
        });
        queryClient.invalidateQueries(queryKeys.tasksList);
        queryClient.invalidateQueries(queryKeys.folders);
      }
    }
  );

  const { mutateAsync: onDeleteTask } = useMutation<void, ApiError, Task['id']>(
    (taskId: Task['id']) => deleteTasks([taskId]),
    {
      retry: 0,
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.sharedOrgTasksList);
        await queryClient.invalidateQueries(queryKeys.taskDetails(taskId));
        await queryClient.invalidateQueries(queryKeys.folders);

        if (activeFolder?.id === taskId) {
          onChangeActiveFolder(null);
        }

        onToggleDeleteTaskModal();
      }
    }
  );

  const deleteModalTitle = (
    <>
      Are you sure you want to delete this lesson or module from your
      organization&apos;s <b>Shared Library?</b>
    </>
  );

  return (
    <>
      <StatusSnackBar
        isError={isCopyToMyTasksError}
        errorMessage={
          copyToMyTasksError?.errorMessage || ErrorMessages.FailedPostRequest
        }
        className={styles.message}
      />
      <IconMenu
        className={styles['shared-library-action-menu']}
        icon={
          <MoreVertIcon
            className={styles['more-icon']}
            data-testid="menu-button"
          />
        }
        buttonLabel="Kabob Menu"
      >
        <MenuItem onClick={() => onCopyToMyTasks(taskId)}>
          Copy to My Lessons
        </MenuItem>
        <If condition={!!userData?.hasAdminRights}>
          <MenuItem
            className={styles['delete-button']}
            onClick={onToggleDeleteTaskModal}
          >
            Delete
          </MenuItem>
        </If>
      </IconMenu>
      <DeleteTaskModal
        data={{
          id: taskId,
          title,
          type,
          tasksAmount
        }}
        customDeleteTitle={deleteModalTitle}
        onDeleteTask={() => onDeleteTask(taskId)}
        isOpen={isDeleteTaskModalOpen}
        onCloseModal={onToggleDeleteTaskModal}
      />
    </>
  );
};
