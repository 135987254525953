import React from 'react';
import { Column, Row } from 'react-table';
import { Checkbox, Chip, Grid } from '@mui/material';
import cn from 'classnames';
import { IconCell } from 'components/IconCell';
import { format } from 'date-fns';
import { User } from 'entities/User.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';

import styles from './StaffTable.module.scss';

type TitleProps = {
  fullName: string;
  avatarUrl?: string | null;
  username: string;
  id: string;
};

export const columns: Column<User>[] = [
  {
    id: 'fullName',
    Header: 'Full name',
    accessor: (row): TitleProps => ({
      fullName: row.fullName,
      avatarUrl: row.avatarUrl,
      username: row.username,
      id: row.id
    }),
    Cell: ({ value, row }: { value: TitleProps; row: Row<User> }) => (
      <Grid container>
        <Checkbox
          checked={row.isSelected}
          onChange={row.getToggleRowSelectedProps().onChange}
          inputProps={{
            'aria-label': 'Select staff user'
          }}
        />
        <IconCell
          rounded
          title={value.fullName}
          imageURL={value.avatarUrl}
          hasAvatarPlaceholder={false}
          navTo={`staff/${value.id}`}
        />
      </Grid>
    ),
    width: 3,
    minWidth: 300
  },
  {
    id: 'role',
    Header: 'Role',
    accessor: 'formattedRole',
    width: 1,
    minWidth: 100
  },
  {
    id: 'email',
    Header: 'Email',
    accessor: 'email',
    width: 2,
    minWidth: 270
  },
  {
    id: 'formattedLastActive',
    Header: 'Last activity',
    accessor: 'formattedLastActive',
    width: 1,
    minWidth: 130
  },
  {
    id: 'dateCreated',
    Header: 'Date created',
    accessor: (row) =>
      row.createdAt ? format(row.createdAt, 'dd MMMM yyyy') : '-',
    width: 1,
    minWidth: 130
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <Chip
        size="small"
        label={value}
        className={cn(
          styles.chip,
          value === EntityStatus.Active && styles.primary
        )}
      />
    ),
    width: 1,
    minWidth: 100
  }
];
