import React, { FC, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { fetchAssessments, fetchTasks } from 'actions/Task/taskActions';
import { fetchUsers } from 'actions/User/userActions';
import { DataTable } from 'components/DataTable';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Assessment } from 'entities/Assessment.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';

import { AssessmentColumn, learnerColumns, staffColumns } from './columns';

import styles from './Assessments.module.scss';

interface Props {
  user: User;
}

export const Assessments: FC<React.PropsWithChildren<Props>> = ({ user }) => {
  const { data: organizationData } = useOrganizationBySlug();

  const options = useMemo(
    () =>
      user?.isLearner
        ? {
            assessorIdEq: undefined,
            evaluatedIdEq: user.id
          }
        : { assessorIdEq: user.id, evaluatedIdEq: undefined },
    [user.id, user.isLearner]
  );

  const { data, hasNextPage, fetchNextPage, isFetching, isError } =
    useInfiniteQuery(
      queryKeys.userAssessments(organizationData!.id, user.id),
      async ({ pageParam }): Promise<AssessmentColumn[]> => {
        const assessmentsList: Assessment[] = await fetchAssessments({
          ...options,
          sort: ['createdAt:ASC'],
          limit: DEFAULT_PAGE_LIMIT,
          offset: pageParam?.offset || 0
        });

        const tasksIds = (assessmentsList || []).map(({ taskId }) => taskId);

        const usersIds = (assessmentsList || []).map(
          ({ evaluatedId, assessorId }) =>
            user.isLearner ? assessorId : evaluatedId
        );

        const users: User[] = await fetchUsers({
          sort: ['firstName:ASC'],
          limit: DEFAULT_PAGE_LIMIT,
          idIn: [...new Set(usersIds)]
        });

        const tasks: Task[] = await fetchTasks({
          sort: ['title:ASC'],
          limit: DEFAULT_PAGE_LIMIT,
          exclude: ['steps'],
          idIn: [...new Set(tasksIds)],
          withDeleted: true
        });

        const usersMap: Map<string, User> = users.reduce((acc, entity) => {
          acc.set(entity.id, entity);

          return acc;
        }, new Map());

        const tasksMap: Map<string, Task> = tasks.reduce((acc, entity) => {
          acc.set(entity.id, entity);

          return acc;
        }, new Map());

        return assessmentsList.map((assessment) => ({
          ...assessment,
          user:
            usersMap.get(
              user.isLearner ? assessment.assessorId : assessment.evaluatedId
            ) || new User(),
          task: tasksMap.get(assessment.taskId) || new Task()
        }));
      },
      {
        staleTime: ACCESS_TOKEN_EXPIRATION,
        retry: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.length < DEFAULT_PAGE_LIMIT) {
            return undefined;
          }

          return {
            offset: allPages.flat().length
          };
        }
      }
    );

  const isInitialLoading = useInitialLoading(isFetching);

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles.container}>
        <LoadingOverlay loading={isInitialLoading}>
          <If condition={!isInitialLoading}>
            <DataTable
              dndDisabled
              isLoading={isFetching}
              hasNextPage={hasNextPage}
              onLoadMore={fetchNextPage}
              data={data?.pages.flat() || []}
              columns={user.isLearner ? learnerColumns : staffColumns}
              emptyMessage={EmptyStateMessages.Assessments}
            />
          </If>
        </LoadingOverlay>
      </div>
    </>
  );
};
