import React, { FC } from 'react';
import { Backdrop, Box, Fade, Modal as MuiModal } from '@mui/material';
import cn from 'classnames';

import styles from './Modal.module.scss';

interface Props {
  onCloseModal: () => void;
  isOpen: boolean;
  title?: string;
  modalClassName?: string;
}

export const Modal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal,
  title,
  children,
  modalClassName
}) => (
  <MuiModal
    open={isOpen}
    onClose={onCloseModal}
    aria-labelledby="modal-title"
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}
  >
    <Fade in={isOpen}>
      <Box className={cn(styles.modal, modalClassName)}>
        {title && (
          <h1
            id="modal-title"
            className={styles.title}
            data-testid="modal-title"
          >
            {title}
          </h1>
        )}
        <div className={styles.content}>{children}</div>
      </Box>
    </Fade>
  </MuiModal>
);
