import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { WebStorageStateStore } from 'oidc-client-ts';
import { queryClient } from 'queryClient';
import { PermissionsProvider } from 'services/Permissions';
import { theme } from 'utils/helpers/theme';
import { OIDC_CONFIG } from 'utils/oidcConfig';
import { ActivityProvider } from 'views/Auth/ActivityProvider';
import { AppAuthProvider } from 'views/Auth/AuthProvider';
import { MassagesProvider } from 'views/Auth/MessagesProvider';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

import './index.css';

const oidcConfig = {
  ...OIDC_CONFIG,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <PermissionsProvider>
          <BrowserRouter>
            <ActivityProvider>
              <MassagesProvider>
                <AuthProvider {...oidcConfig}>
                  <AppAuthProvider>
                    <App />
                  </AppAuthProvider>
                </AuthProvider>
              </MassagesProvider>
            </ActivityProvider>
          </BrowserRouter>
        </PermissionsProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
