import React, { FC, useCallback } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Row as TableRow, UseTableCellProps } from 'react-table';
import ArticleIcon from '@mui/icons-material/Article';
import cn from 'classnames';
import { OnMoveActionProps } from 'components/DataTable/Row';
import { Task } from 'entities/Task.entity';
import { TableDnDType } from 'enums/TableDnDType.enum';
import { prepareOnMoveRowActions } from 'utils/helpers/prepareOnMoveRowActions';

import { LearnerTaskColumn } from './columns';

import styles from './LearnerTaskLibrary.module.scss';

const ROW_HEIGHT = 76;

interface Props {
  row: TableRow & { isCombiningHover?: boolean };
  onMove?: (value: OnMoveActionProps) => void;
}

export const LearnerTaskLibrarySubRows: FC<React.PropsWithChildren<Props>> = ({
  row,
  onMove
}) => {
  const {
    getRowProps,
    original: { tasks, isFolder },
    cells
  } = row as unknown as TableRow<LearnerTaskColumn>;

  const renderCells = useCallback<
    (task: { id: string }, i?: number) => React.ReactNode
  >(
    (task, i = 0) => {
      const { onMoveUp, onMoveDown } = prepareOnMoveRowActions({
        rowId: task.id,
        index: i,
        parentId: row.id,
        onMove,
        recordsLength: tasks?.length
      });

      return cells.map((cell: UseTableCellProps<LearnerTaskColumn>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { accessor } = cell.column;

        return (
          // eslint-disable-next-line react/jsx-key
          <div {...cell.getCellProps()} className={styles.td}>
            {cell.render('Cell', {
              value: accessor && accessor(task, i),
              row: { ...row, original: task, depth: 1, isExpanded: false },
              onMoveUp,
              onMoveDown
            })}
          </div>
        );
      });
    },
    [cells, onMove, row, tasks.length]
  );

  return (
    <Droppable
      key={`row-${row.id}`}
      droppableId={`row-${row.id}`}
      mode="virtual"
      type={`${TableDnDType.Folder}-${row.id}`}
      renderClone={(provided, snapshot, rubric) => {
        const task = tasks[rubric.source.index];

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style
            }}
            className={cn(!snapshot.draggingOver && styles['sub-row-dragging'])}
            key={task?.id}
          >
            {!snapshot.draggingOver && (
              <ArticleIcon className={styles['combine-icon']} />
            )}
            <div
              className={cn(
                styles['sub-row'],
                snapshot.isDragging && styles.dragging
              )}
            >
              {renderCells(task)}
            </div>
          </div>
        );
      }}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{
            minHeight: `${ROW_HEIGHT * tasks.length}px`
          }}
          className={cn(
            styles['learner-task-library-sub-rows'],
            snapshot.isDraggingOver && 'draggingOver'
          )}
        >
          {tasks?.map((task: Task, i: number) => (
            <Draggable draggableId={task.id} index={i} key={task.id}>
              {(draggableProvided) => (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  {...getRowProps()}
                  style={{
                    ...draggableProvided.draggableProps.style,
                    position: 'absolute',
                    top: `${i * ROW_HEIGHT}px`
                  }}
                  key={task.id}
                  className={cn(
                    styles['sub-row'],
                    row.isCombiningHover && isFolder && styles['combine-hover']
                  )}
                >
                  {renderCells(task, i)}
                </div>
              )}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  );
};
