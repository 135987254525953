import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

import styles from './NotificationCircle.module.scss';

export const NotificationCircle = () => (
  <CircleIcon
    className={styles.circle}
    style={{ width: '0.6em', height: '0.6em' }}
  />
);
