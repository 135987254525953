import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { AuthEvents } from 'enums/AuthEvents.enum';
import { Routes } from 'enums/Routes.enum';
import { User } from 'oidc-client-ts';
import { OIDC_CONFIG } from 'utils/oidcConfig';
import { AuthPublisher } from 'views/Auth/AuthProvider/AuthPublisher';

function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${OIDC_CONFIG.authority}:${OIDC_CONFIG.client_id}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}

export const requestSuccessInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const requestConfig = config;

  const user = getUser();

  const token = user?.access_token;
  if (token) {
    requestConfig.headers.Authorization = `Bearer ${token}`;
  }

  requestConfig.headers['User-Type'] = 'Staff';

  return requestConfig;
};

export const responseSuccessInterceptor = (
  response: AxiosResponse
): AxiosResponse => {
  return response;
};

const HandleRedirect = (error: AxiosError) => {
  if (error?.response?.data.context.redirectTo) {
    window.location.assign(error.response.data.context.redirectTo);
  }
};

export const responseErrorInterceptor =
  (client: AxiosInstance) =>
  (error: AxiosError): Promise<AxiosResponse<AxiosInstance | AxiosError>> => {
    if (error?.response?.status === 403) {
      HandleRedirect(error);
    }

    if (error?.response?.status !== 401 || !error.response) {
      return Promise.reject(error);
    }

    const user = getUser();
    if (error.response.status === 401 && !user) {
      window.location.assign(
        `${Routes.UserSignedOut}?state=${window.location.pathname}`
      );
    }

    AuthPublisher.publish(AuthEvents.TokenExpired);
    return Promise.reject(error);
  };

export abstract class ApiBase {
  protected readonly client: AxiosInstance;

  constructor(prefix = '') {
    const client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL + prefix,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Timezone-Offset': new Date().getTimezoneOffset()
      }
    });

    client.interceptors.request.use(requestSuccessInterceptor);
    client.interceptors.response.use(
      responseSuccessInterceptor,
      responseErrorInterceptor(client)
    );

    this.client = client;
  }
}
