import React, { useState } from 'react';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { MessagesContextProvider } from 'hooks/Auth/useMessagesContext';

export interface MessageData {
  isError?: boolean;
  isSuccess?: boolean;
  errorMessage?: string;
  successMessage?: string;
}

export const MassagesProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const [message, setMessage] = useState<MessageData | null>(null);

  return (
    <MessagesContextProvider
      value={{
        showSnackBar: (data) => setMessage(data)
      }}
    >
      <>
        {message && (
          <StatusSnackBar
            isError={message.isError}
            isSuccess={message.isSuccess}
            errorMessage={message.errorMessage}
            successMessage={message.successMessage}
            onClose={() => setMessage(null)}
          />
        )}
        {children}
      </>
    </MessagesContextProvider>
  );
};
