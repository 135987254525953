import React, { useEffect, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import {
  generatePath,
  Redirect,
  Route,
  RouteProps,
  useHistory
} from 'react-router-dom';
import { fetchOrganizationDetails } from 'actions/Organization/organizationActions';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { Routes } from 'enums/Routes.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { hasPermission, usePermissions } from 'services/Permissions';
import { getRedirectUrl } from 'utils/helpers/urlHelper';

interface Props extends RouteProps {
  redirectPath?: Routes;
  forAuthenticated?: boolean;
  scopes?: UserScopes[];
}

const initPendo = async (pendo: any, data: User) => {
  const organizationData = data.organizationId
    ? await fetchOrganizationDetails(data.organizationId || '')
    : null;
  if (
    data &&
    organizationData &&
    data.organizationId &&
    data.role &&
    data.role !== UserRoles.SuperAdmin &&
    organizationData?.name != null
  ) {
    const visitorId = `${process.env.REACT_APP_ENVIRONMENT}-${data.id}`;
    const accountId = `${process.env.REACT_APP_ENVIRONMENT}-${data.organizationId}`;
    const totalStaffLicenses =
      organizationData.managersAmountLimit +
      organizationData.facilitatorsAmountLimit +
      organizationData.passiveFacilitatorsAmountLimit;

    pendo.initialize({
      visitor: {
        id: visitorId,
        role: data.role,
        name: data.fullName,
        organizationId: data.organizationId,
        email: data.email
      },

      account: {
        id: accountId,
        name: organizationData?.name,
        totalLicenses: organizationData?.totalUsersAmountLimit,
        staffLicenses: totalStaffLicenses,
        learnersLicenses: organizationData?.learnersAmountLimit,
        status: organizationData?.status,
        type: organizationData?.type,
        description: organizationData?.description,
        address: organizationData?.address
      }
    });
  } else {
    global.console.warn('No Pendo data');
  }
};

export const ProtectedRoute: React.FC<React.PropsWithChildren<Props>> = ({
  redirectPath,
  forAuthenticated = true,
  scopes,
  ...rest
}) => {
  const auth = useAuth();
  const navigate = useHistory();
  useEffect(() => {
    const executeSignIn = async () => {
      if (!auth.isLoading && !auth.isAuthenticated) {
        const state = getRedirectUrl(
          navigate.location.pathname,
          navigate.location.search
        );
        await auth.signinRedirect({ state });
      }
    };
    executeSignIn();
  }, [auth, navigate.location.pathname, navigate.location.search]);

  const { isAuthenticated } = auth;

  const { data, error } = useLoadAuthUserData({
    enabled: isAuthenticated
  });

  // init Pendo
  const { pendo } = window;
  useEffect(() => {
    if (data && pendo && (pendo as any).isReady() === false) {
      initPendo(pendo, data);
    }
  }, [data, pendo]);

  const loginRedirectPath = useMemo(() => {
    if (!data) {
      return Routes.Root;
    }

    return data.role === UserRoles.SuperAdmin
      ? Routes.Organizations
      : (generatePath(Routes.OrganizationInformation, {
          slugId: data.organization!.slugId,
          slug: data.organization!.slug
        }) as Routes);
  }, [data]);

  const redirectTo = redirectPath || loginRedirectPath;
  const permissions = usePermissions();

  const canOpen = forAuthenticated
    ? (data && scopes && hasPermission(permissions[data.role], scopes)) ||
      (data && !scopes)
    : !isAuthenticated;

  if (auth.isLoading) {
    return <AbsoluteSpinner loading={auth.isLoading} />;
  }

  const redirectToError = (error: Error | ApiError) => {
    console.warn(error);

    return <Redirect to={Routes.ErrorPage} />;
  };

  if (auth.error) {
    if (
      auth.error?.message === 'login_required' ||
      auth.error?.message === 'invalid_grant'
    ) {
      const state = getRedirectUrl(
        navigate.location.pathname,
        navigate.location.search
      );
      navigate.push(`${Routes.UserSignedOut}?state=${state}`);
    } else {
      console.warn(auth.error);
    }
  }

  if (error) {
    return redirectToError(error);
  }

  if (auth.isAuthenticated && !!data) {
    if (
      data.status === EntityStatus.Inactive ||
      data.status === EntityStatus.Archived
    ) {
      return <Redirect to={Routes.UserInactivePage} />;
    }
    if (data.role === UserRoles.PassiveFacilitator) {
      return <Redirect to={Routes.FailedRoleLoginPage} />;
    }
    return canOpen ? <Route {...rest} /> : <Redirect to={redirectTo} />;
  }
  return <div />;
};
