import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia
} from '@mui/material';
import imagePlaceholder from 'assets/big-image-placeholder.png';
import folder from 'assets/folder.png';
import cn from 'classnames';
import { If } from 'components/If';
import { Task } from 'entities/Task.entity';
import { TaskPreview } from 'views/Task/TaskPreview';

import styles from './TaskCard.module.scss';

interface Props {
  task: Task;
  onAddToMyTasks?: (taskId: Task['id']) => void;
  onAddToSharedLibrary?: (taskId: Task['id']) => void;
  onSelectCard: () => void;
}

export const TaskCard: FC<React.PropsWithChildren<Props>> = ({
  task,
  onAddToMyTasks,
  onAddToSharedLibrary,
  onSelectCard
}) => {
  const { id, stepsAmount, imageUrl, title, isFolder } = task;

  const showActions = !!(onAddToMyTasks || onAddToSharedLibrary);

  return (
    <div className={styles['card-container']}>
      <Card
        className={cn(styles.card, showActions ? styles.big : styles.small)}
      >
        <CardMedia
          component="img"
          height="152"
          image={imageUrl || imagePlaceholder}
          alt={`${title} image`}
        />
        <CardContent className={styles.content}>
          <If condition={isFolder}>
            <Avatar
              src={folder}
              alt={`${title} module`}
              className={styles['folder-icon']}
            />
          </If>
          <Button
            variant="text"
            onClick={onSelectCard}
            className={styles['title-button']}
            data-testid="title-button"
            title={title}
          >
            {title}
          </Button>
          <If condition={!isFolder}>
            <TaskPreview
              taskId={id}
              taskName={title}
              stepsAmount={stepsAmount}
              buttonWrapClassName={styles['preview-button-wrap']}
              buttonClassName={styles['preview-button']}
            />
          </If>
        </CardContent>
        <If condition={showActions}>
          <CardActions className={styles['card-actions']}>
            <LoadingButton
              size="small"
              color="secondary"
              variant="contained"
              disabled={!onAddToSharedLibrary}
              onClick={() => onAddToSharedLibrary!(id)}
            >
              Add to Shared Library
            </LoadingButton>
            <LoadingButton
              size="small"
              color="secondary"
              variant="contained"
              disabled={!onAddToMyTasks}
              onClick={() => onAddToMyTasks!(id)}
            >
              Add to My Lessons
            </LoadingButton>
          </CardActions>
        </If>
      </Card>
    </div>
  );
};
