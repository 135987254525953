import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { Modal } from 'components/Modal';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  lessons: { id: string; title: string }[];
  isLoading: boolean;
  handleAssignTasksList: () => Promise<void>;
}

export const AssignedLessonsModal: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onCloseModal,
  lessons,
  isLoading,
  handleAssignTasksList
}) => (
  <Modal
    title="The following lessons are already assigned to the Caregiver"
    isOpen={isOpen}
    onCloseModal={onCloseModal}
  >
    <div>
      <ul style={{ marginBottom: 32 }}>
        {lessons.map((lesson) => (
          <li key={lesson.id}>{lesson.title}</li>
        ))}
      </ul>
      <Grid container alignItems="stretch" spacing={2}>
        <Grid item xs={6}>
          <LoadingButton
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
            onClick={handleAssignTasksList}
            loading={isLoading}
          >
            Assign anyway
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => {
              onCloseModal();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
);
