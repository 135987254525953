import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import success from 'assets/success.svg';
import cn from 'classnames';
import { ConfirmationScreenTheme } from 'components/ConfirmationScreen/ConfirmationScreen.enum';

import styles from './ConfirmationScreen.module.scss';

interface Props {
  theme: ConfirmationScreenTheme;
  onSubmit: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  className?: string;
  icon?: string;
  submitButtonTitle: string;
  cancelButtonTitle: string;
}

export const ConfirmationScreen: FC<React.PropsWithChildren<Props>> = ({
  theme,
  onCancel,
  onSubmit,
  isLoading,
  className,
  icon = success,
  submitButtonTitle,
  cancelButtonTitle,
  children
}) => (
  <div className={cn(className, styles['confirmation-screen'])}>
    <img
      src={icon}
      className={styles.image}
      alt={`CareCoordinator ${theme} screen`}
      data-testid="confirmation-theme-img"
    />
    <div className={styles.content}>{children}</div>
    <Grid container alignItems="stretch" spacing={2}>
      <Grid item xs={6}>
        <LoadingButton
          fullWidth
          type="submit"
          color={theme}
          variant="contained"
          onClick={onSubmit}
          loading={isLoading}
        >
          {submitButtonTitle}
        </LoadingButton>
      </Grid>
      <Grid item xs={6}>
        <Button fullWidth variant="outlined" onClick={onCancel} color={theme}>
          {cancelButtonTitle}
        </Button>
      </Grid>
    </Grid>
  </div>
);
