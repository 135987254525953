import { ApiError } from 'entities/ApiError.entity';
import { NotificationApi } from 'services/API/Notification/NotificationApi';

export type UserNotification = {
  journals: {
    id: string;
    ownerId: string;
  }[];
  comments: {
    id: string;
    journalId: string;
    authorId: string;
    journalOwnerId: string;
  }[];
  notificationIds: Set<string>;
};

export const fetchNotifications = async (): Promise<UserNotification> => {
  try {
    const response = await NotificationApi.fetchUnreadNotifications();

    const { notifications } = response.data;
    const userNotification: UserNotification = notifications.reduce(
      (acc, n) => {
        if (n.type === 'journalEntryShared') {
          acc.journals.push({
            id: n.journalEntry.id,
            ownerId: n.journalEntry.ownerId
          });
        } else if (n.type === 'newJournalComment') {
          acc.comments.push({
            id: n.journalComment.id,
            journalId: n.journalEntry.id,
            authorId: n.journalComment.authorId,
            journalOwnerId: n.journalEntry.ownerId
          });
        }
        acc.notificationIds.add(n.id);
        return acc;
      },
      {
        notificationIds: new Set<string>(),
        journals: [] as UserNotification['journals'],
        comments: [] as UserNotification['comments']
      }
    );
    return userNotification;
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
