import {
  date,
  list,
  object,
  primitive,
  serializable,
  serialize
} from 'serializr';

export class CommentsDTO {
  @serializable
  id = '';

  @serializable
  createdAt = '';

  @serializable
  ownerId = '';

  @serializable
  body = '';

  @serializable(list(primitive()))
  files?: string[] = [];

  @serializable(list(primitive()))
  tags?: string[] = [];

  static serialize(json: Object | string): CommentsDTO {
    return serialize(CommentsDTO, json);
  }
}
