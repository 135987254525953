import { OIDC_CONFIG } from 'utils/oidcConfig';

import type { CRSessionMonitor } from './CRSessionMonitor';

export const SCRIPT_URL = `${OIDC_CONFIG.authority}/assets/js/cr-session-monitor.js`;
export const DEFAULT_TIMEOUT_MINUTES = 15;

export const getSessionMonitor = () =>
  window.cr_session_monitor as CRSessionMonitor;

export const loadScript = (): Promise<{
  script: HTMLScriptElement | null;
  alreadyInit: Boolean;
}> => {
  const src = SCRIPT_URL;
  return new Promise((resolve, reject) => {
    // Remove script if exists
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript || getSessionMonitor()) {
      return resolve({
        script: existingScript as HTMLScriptElement,
        alreadyInit: true
      });
    }
    // Add script
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve({ script, alreadyInit: false });
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    document.head.appendChild(script);

    return { script, alreadyInit: false };
  });
};

export const removeScript = (script: HTMLScriptElement) => {
  if (script && document.head.contains(script)) {
    document.head.removeChild(script);
  }
};
