import React from 'react';
import { Column } from 'react-table';
import { format } from 'date-fns';
import { Assessment } from 'entities/Assessment.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { Routes } from 'enums/Routes.enum';
import { UserTableNavCell } from 'views/People/UserTableNavCell';

import styles from './Assessments.module.scss';

type TaskProps = {
  id: string;
  title: string;
  stepsAmount: number | null;
  icon?: string | null;
};

type UserProps = {
  fullName: string;
  id: string;
  avatarUrl?: string | null;
};

export type AssessmentColumn = Assessment & {
  task: Task;
  user: User;
};

export const learnerColumns: Column<AssessmentColumn>[] = [
  {
    id: 'task',
    Header: 'Lesson',
    accessor: ({ task }): TaskProps => ({
      id: task.id,
      title: task.title,
      icon: task.imageUrl,
      stepsAmount: task.stepsAmount
    }),
    Cell: ({ value }: { value: TaskProps }) => (
      <UserTableNavCell
        hasAvatarPlaceholder
        title={value.title}
        imageURL={value.icon}
        params={{ id: value.id }}
        route={Routes.LearnerTaskDetails}
        task={{
          id: value.id,
          title: value.title,
          stepsAmount: value.stepsAmount
        }}
      />
    ),
    width: 3,
    minWidth: 200
  },
  {
    id: 'formattedCreatedAt',
    Header: 'Date created',
    accessor: (row) =>
      row.createdAt ? format(row.createdAt, 'd MMM yyyy, hh:mm:ss') : '-',
    width: 2,
    minWidth: 200
  },
  {
    id: 'user',
    Header: 'Staff',
    accessor: ({ user }): UserProps => ({
      fullName: user.fullName,
      avatarUrl: user.avatarUrl,
      id: user.id
    }),
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    Cell: ({ value }: { value: UserProps }) => {
      if (!value?.id) {
        return <>-</>;
      }

      return (
        <UserTableNavCell
          rounded
          title={value.fullName}
          imageURL={value.avatarUrl}
          params={{ id: value.id }}
          route={Routes.StaffDetails}
        />
      );
    },
    width: 1,
    minWidth: 150
  },
  {
    id: 'scorePercent',
    Header: 'Score (%)',
    accessor: 'scorePercent',
    width: 1,
    minWidth: 70
  },
  {
    id: 'notes',
    Header: 'Notes',
    accessor: 'notes',
    Cell: ({ value }) => (
      <span className={styles['notes-cell']} title={value!}>
        {value}
      </span>
    ),
    width: 1,
    minWidth: 200
  }
];

export const staffColumns: Column<AssessmentColumn>[] = [
  {
    id: 'user',
    Header: 'Caregiver',
    accessor: ({ user }): UserProps => ({
      fullName: user.fullName,
      avatarUrl: user.avatarUrl,
      id: user.id
    }),
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    Cell: ({ value }: { value: UserProps }) => {
      if (!value?.id) {
        return <>-</>;
      }

      return (
        <UserTableNavCell
          rounded
          title={value.fullName}
          imageURL={value.avatarUrl}
          params={{ id: value.id }}
          route={Routes.LearnerDetails}
        />
      );
    },
    width: 1,
    minWidth: 150
  },
  {
    id: 'task',
    Header: 'Lesson',
    accessor: ({ task }): TaskProps => ({
      id: task.id,
      title: task.title,
      icon: task.imageUrl,
      stepsAmount: task.stepsAmount
    }),
    Cell: ({ value }: { value: TaskProps }) => (
      <UserTableNavCell
        hasAvatarPlaceholder
        title={value.title}
        imageURL={value.icon}
        params={{ id: value.id }}
        route={Routes.StaffTaskDetails}
        task={{
          id: value.id,
          title: value.title,
          stepsAmount: value.stepsAmount
        }}
      />
    ),
    width: 3,
    minWidth: 200
  },
  {
    id: 'formattedCreatedAt',
    Header: 'Assessment Date / Time',
    accessor: (row) =>
      row.createdAt ? format(row.createdAt, 'd MMM yyyy, hh:mm:ss') : '-',
    width: 2,
    minWidth: 200
  },
  {
    id: 'scorePercent',
    Header: 'Score (%)',
    accessor: 'scorePercent',
    width: 1,
    minWidth: 70
  },
  {
    id: 'notes',
    Header: 'Notes',
    accessor: 'notes',
    Cell: ({ value }) => (
      <span className={styles['notes-cell']} title={value!}>
        {value}
      </span>
    ),
    width: 1,
    minWidth: 200
  }
];
