import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { fetchAssessments } from 'actions/Task/taskActions';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { MuiTable } from 'components/MuiTable';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Assessment } from 'entities/Assessment.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';
import { EvidenceChart } from 'views/People/TaskLibrary/EvidenceChart';

import { columns } from './columns';

import styles from './EvidenceTab.module.scss';

const MAX_CHART_RECORDS_AMOUNT = 10;

interface Props {
  taskId: Task['id'];
  isReport?: boolean;
  stickyHeader?: boolean;
}

export const EvidenceTab: FC<React.PropsWithChildren<Props>> = ({
  taskId,
  isReport,
  stickyHeader = true
}) => {
  const { data, isLoading, isError } = useQuery<Assessment[], ApiError>(
    queryKeys.taskAssessments(taskId),
    (): Promise<Assessment[]> =>
      fetchAssessments({
        limit: MAX_PAGE_LIMIT,
        taskIdEq: taskId,
        sort: ['createdAt:ASC']
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  if (!data?.length && isReport) {
    return null;
  }

  const chartData = (data || []).slice(-MAX_CHART_RECORDS_AMOUNT);

  return (
    <>
      {isReport && <h4 className={styles.title}>Evidence of learning</h4>}
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <LoadingOverlay loading={isLoading}>
        <div className={styles.container}>
          <If condition={!!data?.length && !isLoading}>
            <EvidenceChart assessments={chartData} />

            <div className={styles['table-container']}>
              <MuiTable
                data={data || []}
                columns={columns}
                stickyHeader={stickyHeader}
              />
            </div>
          </If>

          <If condition={!data?.length && !isLoading}>
            <EmptyState />
          </If>
        </div>
      </LoadingOverlay>
    </>
  );
};
