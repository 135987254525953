import React, { FC, useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem } from '@mui/material';
import {
  deleteTasks,
  duplicateTask,
  updateTaskDetails
} from 'actions/Task/taskActions';
import { IconMenu } from 'components/IconMenu';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskType } from 'enums/TaskType.enum';
import { useMessagesContext } from 'hooks/Auth/useMessagesContext';
import { TasksContext, useTasksCache } from 'hooks/Task/useTasksCache';
import { useTasksContext } from 'hooks/Task/useTasksContext';
import { queryClient } from 'queryClient';
import { CopyToOrganizationModal } from 'views/Library/CopyToOrganizationModal';
import { EditFolderModal } from 'views/Library/EditFolderModal';
import { DeleteTaskModal } from 'views/Task/DeleteTaskModal';
import { EditTaskModal } from 'views/Task/EditTaskModal';

import styles from './ActionMenu.module.scss';

interface Props {
  task: Task;
}
const taskActionTitle = Object.freeze({
  [TaskStatus.Published]: 'Unpublish',
  [TaskStatus.Draft]: 'Publish'
});

const taskOppositeStatus = Object.freeze({
  [TaskStatus.Published]: TaskStatus.Draft,
  [TaskStatus.Draft]: TaskStatus.Published
});

export const ActionMenu: FC<React.PropsWithChildren<Props>> = ({ task }) => {
  const {
    id,
    type,
    title,
    image,
    imageUrl,
    tasksAmount,
    publishStatus,
    description,
    repertoireId,
    parentId
  } = task;

  const { showSnackBar } = useMessagesContext();
  const { activeFolder, onChangeActiveFolder, search, status, sort } =
    useTasksContext();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

  const onToggleIsCopyModalOpen = useCallback(() => {
    setIsCopyModalOpen((prevIsCopyModalOpen) => !prevIsCopyModalOpen);
  }, []);

  const onToggleDeleteTaskModal = useCallback(() => {
    setIsDeleteTaskModalOpen(
      (prevIsDeleteTaskModalOpen) => !prevIsDeleteTaskModalOpen
    );
  }, []);

  const onToggleEditModal = useCallback(() => {
    setIsEditModalOpen((prevIsEditModalOpen) => !prevIsEditModalOpen);
  }, []);

  const options = useMemo(
    () =>
      activeFolder
        ? {
            search,
            status,
            sort,
            parentIdEq: activeFolder!.id,
            parentIdIsNull: undefined
          }
        : { search, status, sort, parentIdIsNull: true, parentIdEq: undefined },
    [activeFolder, search, sort, status]
  );

  const { prevTasksList, updateCache, resetToPrevCache } =
    useTasksCache(options);

  const showError = () => {
    showSnackBar({
      isError: true,
      errorMessage: ErrorMessages.FailedPostRequest
    });
  };

  const { mutateAsync } = useMutation<Task, ApiError, Task['id'], TasksContext>(
    (taskId: Task['id']) =>
      updateTaskDetails(taskId, {
        publishStatus: taskOppositeStatus[publishStatus]
      }),
    {
      retry: 0,
      onMutate: async (taskId) => {
        await queryClient.cancelQueries(queryKeys.tasksList);

        if (prevTasksList) {
          updateCache(
            prevTasksList.pages.map((page) =>
              page.map(
                (task): Task =>
                  task.id === taskId
                    ? {
                        ...task,
                        isFolder: task.isFolder,
                        imageUrl: task.imageUrl,
                        publishStatus: taskOppositeStatus[publishStatus]
                      }
                    : task
              )
            )
          );
        }

        return { prevTasksList };
      },
      onSuccess: (updatedTask) => {
        if (activeFolder?.id === updatedTask.id) {
          onChangeActiveFolder(updatedTask);
        }

        queryClient.invalidateQueries(queryKeys.tasksList);
        queryClient.invalidateQueries(queryKeys.taskDetails(id));
        queryClient.invalidateQueries(queryKeys.folders);
      },
      onError: (_errors, _vars, context) => {
        showError();
        resetToPrevCache(context);
      }
    }
  );

  const { mutateAsync: onDuplicateTask } = useMutation<
    Task,
    ApiError,
    Task['id'],
    TasksContext
  >(
    (taskId: Task['id']) =>
      duplicateTask(taskId, { publishStatus: TaskStatus.Draft }),
    {
      retry: 0,
      onMutate: async (taskId) => {
        await queryClient.cancelQueries(queryKeys.tasksList);

        if (prevTasksList) {
          const duplicatedTask = {
            ...task,
            id: '0',
            imageUrl: task.imageUrl,
            isFolder: task.isFolder,
            publishStatus: TaskStatus.Draft
          };

          updateCache(
            prevTasksList.pages.map((page) =>
              page
                .map((task) =>
                  task.id === taskId ? [task, duplicatedTask] : task
                )
                .flat()
            )
          );
        }

        return { prevTasksList };
      },
      onSuccess: () => {
        showSnackBar({
          isSuccess: true,
          successMessage: `${
            task.isFolder ? 'Module' : 'Lesson'
          } successfully copied`
        });
        queryClient.invalidateQueries(queryKeys.tasksList);
        queryClient.invalidateQueries(queryKeys.folders);
      },
      onError: (_errors, _vars, context) => {
        showError();
        resetToPrevCache(context);
      }
    }
  );

  const { mutateAsync: onDeleteTask } = useMutation<
    void,
    ApiError,
    Task['id'],
    TasksContext
  >((taskId: Task['id']) => deleteTasks([taskId]), {
    retry: 0,
    onMutate: async (taskId) => {
      await queryClient.cancelQueries(queryKeys.tasksList);

      if (prevTasksList) {
        updateCache(
          prevTasksList.pages.map((page) =>
            page.filter((task) => task.id !== taskId)
          )
        );
      }

      return { prevTasksList };
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.tasksList);
      await queryClient.invalidateQueries(queryKeys.taskDetails(id));
      await queryClient.invalidateQueries(queryKeys.folders);

      if (activeFolder?.id === task.id) {
        onChangeActiveFolder(null);
      }

      onToggleDeleteTaskModal();
    },
    onError: (_errors, _vars, context) => {
      showError();
      resetToPrevCache(context);
    }
  });

  return (
    <div className={styles.action}>
      <CopyToOrganizationModal
        taskIds={[id]}
        isOpen={isCopyModalOpen}
        onCloseModal={onToggleIsCopyModalOpen}
      />
      <DeleteTaskModal
        data={{
          id,
          title,
          type,
          tasksAmount
        }}
        onDeleteTask={() => onDeleteTask(id)}
        isOpen={isDeleteTaskModalOpen}
        onCloseModal={onToggleDeleteTaskModal}
      />
      {type === TaskType.Group ? (
        <EditFolderModal
          isOpen={isEditModalOpen}
          onCloseModal={onToggleEditModal}
          data={{
            id,
            title,
            image,
            description,
            imageUrl,
            repertoireId
          }}
        />
      ) : (
        <EditTaskModal
          isOpen={isEditModalOpen}
          taskQuery={{
            sort: [TaskSort.ByName],
            organizationIdIsNull: true
          }}
          onEditTask={() => queryClient.invalidateQueries(queryKeys.tasksList)}
          onCloseModal={onToggleEditModal}
          data={{
            id,
            title,
            description,
            imageUrl,
            image,
            repertoireId,
            parentId
          }}
        />
      )}
      <IconMenu
        className={styles.menu}
        icon={<MoreVertIcon />}
        buttonLabel="Kabob Menu"
      >
        <MenuItem onClick={onToggleEditModal}>Edit</MenuItem>
        <MenuItem onClick={() => onDuplicateTask(id)}>Duplicate</MenuItem>
        <MenuItem onClick={onToggleIsCopyModalOpen}>
          Copy to organization library
        </MenuItem>
        <MenuItem onClick={() => mutateAsync(id)}>
          {taskActionTitle[publishStatus]}
        </MenuItem>
        <MenuItem
          className={styles['delete-button']}
          onClick={onToggleDeleteTaskModal}
        >
          Delete
        </MenuItem>
      </IconMenu>
    </div>
  );
};
