import React, { FC, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import { PasswordAdornment } from 'components/PasswordAdornment';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { isPasswordMatch, isPasswordValid } from 'utils/helpers/validators';

import styles from './ChangePasswordForm.module.scss';

interface Props {
  isLoading: boolean;
  onCloseModal: () => void;
  onSubmit: (formData: FormData) => void;
}

export interface FormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const ChangePasswordForm: FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  onSubmit,
  onCloseModal
}) => {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

  const {
    control,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    mode: 'onChange',
    shouldUnregister: true,
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const oldPassword = getValues('oldPassword');
  const newPassword = getValues('newPassword');
  const confirmPassword = getValues('confirmPassword');

  const validateNewPassword = useCallback(
    (password: string): string => {
      let errorMessage = '';

      if (!password) {
        errorMessage = ErrorMessages.FailedRequiredField;
      } else if (!isPasswordValid(password)) {
        errorMessage = ErrorMessages.NotValidNewPassword;
      } else if (isPasswordMatch(password, oldPassword)) {
        errorMessage = ErrorMessages.NewPasswordMatchOld;
      }

      if (isPasswordMatch(password, confirmPassword)) {
        clearErrors('confirmPassword');
        setConfirmPasswordError('');
      } else if (
        confirmPassword &&
        !isPasswordMatch(password, confirmPassword)
      ) {
        setError('confirmPassword', {
          message: ErrorMessages.NotValidConfirmPassword
        });
        setConfirmPasswordError(ErrorMessages.NotValidConfirmPassword);
      }

      setNewPasswordError(errorMessage);

      return errorMessage;
    },
    [clearErrors, confirmPassword, oldPassword, setError]
  );

  const validateConfirmPassword = useCallback(
    (confirmPassword: string): string => {
      let errorMessage = '';

      if (!confirmPassword) {
        errorMessage = ErrorMessages.FailedRequiredField;
      } else if (!isPasswordMatch(confirmPassword, newPassword)) {
        errorMessage = ErrorMessages.NotValidConfirmPassword;
      }

      setConfirmPasswordError(errorMessage);

      return errorMessage;
    },
    [newPassword]
  );

  return (
    <form
      className={styles['change-password-form']}
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
    >
      <Controller
        rules={{
          required: true
        }}
        name="oldPassword"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={showOldPassword ? 'text' : 'password'}
            variant="outlined"
            label="Old password"
            margin="normal"
            inputRef={field.ref}
            error={!!errors?.oldPassword}
            helperText={
              errors?.oldPassword && ErrorMessages.FailedRequiredField
            }
            id="old-password-outlined-input"
            InputProps={{
              endAdornment: (
                <PasswordAdornment
                  isPasswordVisible={showOldPassword}
                  onShowPassword={() => setShowOldPassword(!showOldPassword)}
                />
              )
            }}
          />
        )}
      />

      <Controller
        rules={{
          validate: (value) => !validateNewPassword(value)
        }}
        name="newPassword"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={showNewPassword ? 'text' : 'password'}
            variant="outlined"
            label="New password"
            margin="normal"
            inputRef={field.ref}
            value={field.value}
            onChange={field.onChange}
            error={!!errors?.newPassword}
            helperText={errors?.newPassword && newPasswordError}
            id="new-password-outlined-input"
            InputProps={{
              endAdornment: (
                <PasswordAdornment
                  isPasswordVisible={showNewPassword}
                  onShowPassword={() => setShowNewPassword(!showNewPassword)}
                />
              )
            }}
          />
        )}
      />
      <Controller
        rules={{
          validate: (value) => !validateConfirmPassword(value)
        }}
        name="confirmPassword"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={showConfirmPassword ? 'text' : 'password'}
            variant="outlined"
            label="Confirm password"
            margin="normal"
            inputRef={field.ref}
            error={!!errors?.confirmPassword}
            helperText={errors?.confirmPassword && confirmPasswordError}
            id="confirm-new-password-outlined-input"
            InputProps={{
              endAdornment: (
                <PasswordAdornment
                  isPasswordVisible={showConfirmPassword}
                  onShowPassword={() =>
                    setShowConfirmPassword(!showConfirmPassword)
                  }
                />
              )
            }}
          />
        )}
      />
      <div className={styles.footer}>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                !!newPasswordError ||
                !!confirmPasswordError ||
                !!errors.oldPassword ||
                !oldPassword ||
                !newPassword ||
                !confirmPassword
              }
              loading={isLoading}
            >
              Set a new password
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
