import React, { FC } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import folder from 'assets/folder.png';
import { IconCell } from 'components/IconCell';
import { Task } from 'entities/Task.entity';
import { Routes } from 'enums/Routes.enum';
import { TaskPreview } from 'views/Task/TaskPreview';

import styles from './UserTableNavCell.module.scss';

interface Props {
  imageURL?: string | null;
  params?: Record<string, string>;
  route?: Routes;
  rounded?: boolean;
  title: string;
  hasAvatarPlaceholder?: boolean;
  isDragIconVisible?: boolean;
  isDragDisabled?: boolean;
  showCareIcon?: boolean;
  task?: {
    id: Task['id'];
    title: Task['title'];
    stepsAmount: Task['stepsAmount'];
    isFolder?: Task['isFolder'];
    tasksAmount?: Task['tasksAmount'];
  };
  onTitleClick?: () => void;
}

export const UserTableNavCell: FC<React.PropsWithChildren<Props>> = ({
  imageURL,
  title,
  params,
  rounded,
  hasAvatarPlaceholder,
  isDragIconVisible,
  isDragDisabled,
  showCareIcon,
  task,
  route,
  onTitleClick
}) => {
  const { slugId, slug, id } = useParams<{
    slugId: string;
    slug: string;
    id: string;
  }>();

  return (
    <IconCell
      rounded={rounded}
      imageURL={task?.isFolder ? folder : imageURL}
      title={title}
      hasAvatarPlaceholder={hasAvatarPlaceholder}
      isDragIconVisible={isDragIconVisible}
      isDragDisabled={isDragDisabled}
      onTitleClick={onTitleClick}
      showCareIcon={showCareIcon}
      navTo={
        route &&
        generatePath(route, {
          slugId,
          slug,
          userId: id,
          ...params
        })
      }
    >
      {task?.isFolder ? (
        <span className={styles['tasks-amount']}>
          {task?.tasksAmount} lessons
        </span>
      ) : (
        task?.id && (
          <TaskPreview
            buttonType="text"
            taskId={task.id}
            taskName={task.title || ''}
            stepsAmount={task.stepsAmount || 0}
            buttonWrapClassName={styles['preview-button-wrap']}
            buttonClassName={styles['preview-button']}
          />
        )
      )}
    </IconCell>
  );
};
