import React, { CSSProperties, FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Row as TableRow, UseTableCellProps } from 'react-table';
import { fetchTaskActivities } from 'actions/Task/taskActions';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { TaskActivity } from 'entities/TaskActivity.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';
import { ExpandedActivityTableCell } from 'views/People/ExpandedActivityTableCell/ExpandedActivityTableCell';

import { ActivityGroupColumn } from './columns';

import styles from './DurationTab.module.scss';

interface Props {
  row: TableRow;
  taskId: Task['id'];
  style?: CSSProperties;
  hideEmptyState?: boolean;
  fromTime?: Date;
  toTime?: Date;
}

const labelsMap = {
  month: 'Date',
  activitiesAmount: 'Time Spent',
  averageTimeSec: 'Lesson completion',
  action: ''
};

export const DurationTabSubRows: FC<React.PropsWithChildren<Props>> = ({
  row,
  taskId,
  hideEmptyState,
  fromTime,
  toTime
}) => {
  const {
    getRowProps,
    original: { year, month },
    cells
  } = row as unknown as TableRow<ActivityGroupColumn>;
  const rowProps = getRowProps();

  const currentRowMonth = new Date(year, month, 15);

  const fromTimeFilter =
    fromTime && month === fromTime.getMonth() && year === fromTime.getFullYear()
      ? startOfDay(fromTime).toISOString()
      : startOfMonth(currentRowMonth).toISOString();
  const toTimeFilter =
    toTime && month === toTime.getMonth() && year === toTime.getFullYear()
      ? endOfDay(toTime).toISOString()
      : endOfMonth(currentRowMonth).toISOString();

  const { data, isLoading, isError } = useQuery<TaskActivity[], ApiError>(
    queryKeys.filteredTaskActivity({
      taskIdEq: taskId,
      sort: ['id:ASC'],
      endTimeLt: toTimeFilter,
      endTimeGt: fromTimeFilter
    }),
    (): Promise<TaskActivity[]> => {
      return fetchTaskActivities({
        limit: MAX_PAGE_LIMIT,
        taskIdEq: taskId,
        sort: ['id:ASC'],
        endTimeLt: toTimeFilter,
        endTimeGt: fromTimeFilter
      });
    },
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  const renderEmptyState = useMemo(
    () =>
      hideEmptyState ? null : (
        <p className={styles['empty-state']}>No records</p>
      ),
    [hideEmptyState]
  );

  if (isLoading) {
    return (
      <div>
        <LoadingOverlay loading={isLoading} />
      </div>
    );
  }

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      {!data?.length
        ? renderEmptyState
        : data?.map((activity: TaskActivity) => {
            return (
              <tr
                {...{ ...rowProps, key: activity.id }}
                key={activity.id}
                className={styles['sub-row']}
              >
                {cells.map((cell: UseTableCellProps<ActivityGroupColumn>) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div {...cell.getCellProps()}>
                      <ExpandedActivityTableCell
                        id={cell.column.id}
                        activity={activity}
                        label={labelsMap[cell.column.id]}
                      />
                    </div>
                  );
                })}
              </tr>
            );
          })}
    </>
  );
};
