import React, { FC } from 'react';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';

export const TableLoadingRow: FC<React.PropsWithChildren<unknown>> = () => (
  <Grid container justifyContent="center">
    <LoadingButton
      loading
      loadingPosition="start"
      startIcon={<Save />}
      variant="contained"
    >
      Loading more...
    </LoadingButton>
  </Grid>
);
