import React, { FC, useMemo } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { DataTable } from 'components/DataTable';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useJournalCaregiverNotification } from 'hooks/Notification/useNotification';
import { JournalApi } from 'services/API/Journal/JournalApi';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

import { getColumnDefinitions, JournalEntriesColumn } from './columns';

import styles from './JournalEntries.module.scss';

interface Props {
  caregiverId: string;
}

export const JournalEntries: FC<React.PropsWithChildren<Props>> = ({
  caregiverId
}) => {
  const { slug, slugId, userId } = useParams<{
    slug: string;
    slugId: string;
    userId: string;
  }>();

  const { data: userData } = useLoadAuthUserData();

  const notifications = useJournalCaregiverNotification(
    userData?.id ?? '',
    caregiverId
  );

  const journalsToHiglight = useMemo(() => {
    if (!notifications.data) return [];
    return [
      ...notifications.data.journals,
      ...notifications.data.comments.map((x) => x.journalId)
    ];
  }, [notifications]);

  const fetch = async (
    caregiverId: string
  ): Promise<JournalEntriesColumn[]> => {
    try {
      const response = await JournalApi.fetchJournals(caregiverId);
      return response.data.map((m) => ({
        id: m.journalId,
        title: m.journalTitle,
        commentsCount: m.commentsCount,
        sharedOn: new Date(m.sharedOn),
        lastUpdated: new Date(m.lastUpdated)
      }));
    } catch (e) {
      throw ApiError.deserializeFromCatch(e);
    }
  };

  const { isError, isFetching, data } = useQuery<
    JournalEntriesColumn[],
    ApiError
  >(queryKeys.learnerJournals(caregiverId), () => fetch(caregiverId), {
    retry: 0,
    staleTime: ACCESS_TOKEN_EXPIRATION
  });

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles.container}>
        <LoadingOverlay loading={isFetching}>
          <If condition={!isFetching}>
            <DataTable
              dndDisabled
              className={styles.journals}
              isLoading={isFetching}
              data={data || []}
              columns={getColumnDefinitions(
                slug,
                slugId,
                caregiverId,
                notifications.data,
                journalsToHiglight
              )}
              emptyMessage={EmptyStateMessages.Journals}
            />
          </If>
        </LoadingOverlay>
      </div>
    </>
  );
};
