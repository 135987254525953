import React from 'react';
import { NavLink } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { Checkbox, Chip, Grid } from '@mui/material';
import cn from 'classnames';
import { IconCell } from 'components/IconCell';
import { NotificationCircle } from 'components/NotificationCircle';
import { Learner } from 'entities/Learner.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserTabs } from 'enums/UserTabs.enum';

import { EmailAddressVerificationChip } from '../EmailAddressVerificationChip';
import { ResendVerificationEmailButton } from '../ResendVerificationEmailButton';

import styles from './LearnersTable.module.scss';

type TitleProps = {
  fullName: string;
  avatarUrl?: string | null;
  username: string;
  id: string;
  canView: boolean;
};

type JournalProps = {
  journalCount?: number;
  id: string;
};

const emailAddressVerificationChipTextOverride = {
  missing: {
    label: 'Missing email address',
    title: 'Missing email address'
  },
  unverified: {
    label: 'Unverified email address',
    title: 'Unverified email address'
  }
};

const EmailAlert: React.FC<
  React.PropsWithChildren<{
    learner: Learner;
  }>
> = ({ learner: { id, email, isEmailVerified } }) => {
  const isUnverified = email && !isEmailVerified;
  const isMissing = !email;
  const isVerified = email && isEmailVerified;

  return (
    <>
      {isUnverified && (
        <span className={`${styles['email-status']} ${styles.resend}`}>
          <EmailAddressVerificationChip
            iconOnly
            chipType="unverified"
            textOverride={emailAddressVerificationChipTextOverride}
          />
          <p title={email}>{email}</p>
          <ResendVerificationEmailButton userId={id} />
        </span>
      )}
      {isMissing && (
        <span className={styles['email-status']}>
          <EmailAddressVerificationChip
            chipType="missing"
            textOverride={emailAddressVerificationChipTextOverride}
          />
        </span>
      )}
      {isVerified && (
        <span className={styles['email-status']}>
          <EmailAddressVerificationChip
            iconOnly
            chipType="verified"
            textOverride={emailAddressVerificationChipTextOverride}
          />
          <p title={email}>{email}</p>
        </span>
      )}
    </>
  );
};

const canViewCaregiver = (
  staffUserId: string,
  staffRole: UserRoles,
  facilitators?: string[] | null
) => {
  if (staffRole === UserRoles.Admin || staffRole === UserRoles.SuperAdmin) {
    return true;
  }
  return facilitators?.includes(staffUserId) || false;
};

export const getColumns = (
  notificationCaregiverIds: string[],
  staffUserId: string,
  userRole: UserRoles
): Column<Learner>[] => [
  {
    id: 'fullName',
    Header: 'Full name',
    accessor: (row): TitleProps => ({
      fullName: row.fullName,
      avatarUrl: row.avatarUrl,
      username: row.username,
      id: row.id,
      canView: canViewCaregiver(staffUserId, userRole, row.facilitatorIds)
    }),
    Cell: ({ value, row }: { value: TitleProps; row: Row<Learner> }) => (
      <Grid container>
        <Checkbox
          checked={row.isSelected}
          onChange={row.getToggleRowSelectedProps().onChange}
          inputProps={{
            'aria-label': value.canView
              ? 'Select caregiver'
              : 'You are not associated with this caregiver.'
          }}
          disabled={!value.canView}
        />
        <IconCell
          rounded
          title={value.fullName}
          subtitle={value.username}
          imageURL={value.avatarUrl}
          hasAvatarPlaceholder={false}
          navTo={value.canView ? `learners/${value.id}` : undefined}
        />
      </Grid>
    ),
    width: 3,
    minWidth: 70
  },
  {
    id: 'emailAlerts',
    Header: 'Email',
    width: 120,
    minWidth: 120,
    Cell: ({ row }: { row: Row<Learner> }) => (
      <EmailAlert learner={row.original} />
    )
  },
  {
    id: 'journal',
    Header: 'Journal Entries',
    accessor: ({ journalCount, id }): JournalProps => ({
      journalCount,
      id
    }),
    Cell: ({ value }: { value: JournalProps }) =>
      (value.journalCount && value.journalCount > 0 && (
        <NavLink to={`learners/${value.id}?tab=${UserTabs.Journal}`}>
          {value.journalCount}
          {notificationCaregiverIds.includes(value.id) && (
            <NotificationCircle />
          )}
        </NavLink>
      )) ||
      (value.journalCount ?? '-'),
    width: 3,
    minWidth: 40
  },
  {
    id: 'formattedLastSessionDate',
    Header: 'Last activity',
    accessor: 'formattedLastSessionDate',
    width: 1,
    minWidth: 40
  },
  {
    id: 'dateCreated',
    Header: 'Date created',
    accessor: 'formattedCreatedAt',
    width: 1,
    minWidth: 40
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <Chip
        size="small"
        label={value}
        className={cn(
          styles.chip,
          value === EntityStatus.Active && styles.primary
        )}
      />
    ),
    width: 1,
    minWidth: 50
  }
];
