import React, { FC, useCallback, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Fab, Grid } from '@mui/material';
import { fetchOrganizations } from 'actions/Organization/organizationActions';
import { DataTable, DataTableHandle } from 'components/DataTable';
import { If } from 'components/If';
import { Layout } from 'components/Layout';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { TableActionBar } from 'components/TableActionsBar';
import { Organization } from 'entities/Organization.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { EntityStatus, entityStatusOptions } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { useDebounce } from 'use-debounce';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';
import { OrganizationsStatusBar } from 'views/Organizations/OrganizationsStatusBar';

import { columns } from './columns';

import styles from './OrganizationsPage.module.scss';

const organizationStatusOptions = entityStatusOptions.filter(
  ({ value }) => value !== EntityStatus.Transferred
);

export const OrganizationsPage: FC<React.PropsWithChildren<unknown>> = () => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 1000);
  const [status, setStatus] = useState<EntityStatus>(EntityStatus.All);

  const tableRef = useRef<DataTableHandle>(null);
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Organization[]
  >([]);

  const onOrganizationSelect = useCallback(
    (organizations: Organization[]) => setSelectedOrganizations(organizations),
    []
  );

  const onClearSelected = useCallback(() => {
    tableRef.current?.reset();

    setSelectedOrganizations([]);
  }, []);

  const { data, hasNextPage, fetchNextPage, isFetching, isError } =
    useInfiniteQuery(
      queryKeys.filteredOrganizations({ search: debouncedSearch, status }),
      ({ pageParam }) =>
        fetchOrganizations({
          sort: ['name:ASC', 'status:ASC', 'createdAt:DESC'],
          statusEq: status === EntityStatus.All ? undefined : status,
          limit: DEFAULT_PAGE_LIMIT,
          offset: pageParam?.offset || 0,
          search: debouncedSearch === '' ? undefined : debouncedSearch
        }),
      {
        onSuccess: () => {
          onClearSelected();
        },
        staleTime: ACCESS_TOKEN_EXPIRATION,
        retry: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.length < DEFAULT_PAGE_LIMIT) {
            return undefined;
          }

          return {
            offset: allPages.flat().length
          };
        }
      }
    );

  const isInitialLoading = useInitialLoading(isFetching);

  return (
    <Layout loading={isInitialLoading}>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles.container}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={styles.filters}
        >
          <Grid item xs={2}>
            <h1 className={styles.header}>Organizations</h1>
          </Grid>
          <Grid item xs={7} lg={5}>
            <TableActionBar
              searchValue={search}
              searchLabel="Search for Organizations"
              onChangeSearchValue={setSearch}
              selectOptions={organizationStatusOptions}
              onChangeSelectOption={(e) => {
                setStatus(e.target.value as EntityStatus);
              }}
            />
          </Grid>
        </Grid>
        <NavLink to={Routes.CreateOrganization} className={styles.fabLink}>
          <Fab
            variant="extended"
            className={styles.fab}
            color="primary"
            aria-label="Create new organization"
          >
            <Add />
            Create new organization
          </Fab>
        </NavLink>

        <If condition={!isInitialLoading}>
          <DataTable
            ref={tableRef}
            columns={columns}
            data={data?.pages.flat() || []}
            className={styles.table}
            onRowSelect={onOrganizationSelect}
            hasNextPage={hasNextPage}
            isLoading={isFetching}
            onLoadMore={fetchNextPage}
            dndDisabled
            emptyMessage={
              debouncedSearch || status !== EntityStatus.All
                ? EmptyStateMessages.Search
                : EmptyStateMessages.OrganizationPage
            }
          />
        </If>
      </div>

      <OrganizationsStatusBar
        selectedOrganization={selectedOrganizations}
        onSuccess={onClearSelected}
      />
    </Layout>
  );
};
