import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Button, TextField } from '@mui/material';
import { createJournalComments } from 'actions/Journal/JournalActions';
import { ApiError } from 'entities/ApiError.entity';
import { Comments } from 'entities/Comments.entity';
import { Journal } from 'entities/Journal.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'queryClient';

import styles from './JournalDetailsCommentForm.module.scss';

interface Props {
  data?: Journal;
}

export interface FormData {
  comment: string;
}

export const JournalDetailsCommentForm: FC<React.PropsWithChildren<Props>> = ({
  data
}) => {
  const {
    control,
    handleSubmit,
    getValues: getFormValues,
    setValue: setFormValue,
    formState: { isDirty }
  } = useForm<FormData>({
    defaultValues: {
      comment: ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const { mutateAsync, data: createdComment } = useMutation<
    Comments,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      createJournalComments(data!.id, {
        id: '',
        createdAt: new Date().toJSON(),
        ownerId: data!.ownerId,
        body: formData.comment,
        files: [],
        tags: []
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeys.userJournalDetails(data!.ownerId, data!.id)
        );
        setFormValue('comment', '');
      }
    }
  );

  return (
    <form onSubmit={handleSubmit((formData) => mutateAsync(formData))}>
      <div>
        <Controller
          rules={{ required: true }}
          name="comment"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Type your comment here"
              multiline
              rows="3"
              inputProps={{ maxLength: 1000 }}
              variant="outlined"
              className={styles['comment-box']}
              sx={{
                fieldset: { borderColor: '#797979' }
              }}
            />
          )}
        />
      </div>
      <div>
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isDirty}
          className={styles['comment-button']}
          sx={{
            marginTop: '13px',
            fontSize: '16px'
          }}
        >
          Comment
        </Button>
      </div>
    </form>
  );
};
