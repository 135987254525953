import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { fetchTasks } from 'actions/Task/taskActions';
import { fetchUsers } from 'actions/User/userActions';
import { CreateTaskFab } from 'components/CreateTaskFab';
import { DataTable } from 'components/DataTable';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { useDebounce } from 'use-debounce';
import {
  ACCESS_TOKEN_EXPIRATION,
  DEFAULT_PAGE_LIMIT,
  MAX_PAGE_LIMIT
} from 'utils/constants';
import { CreateFolderModal } from 'views/Library/CreateFolderModal';
import { CreateTaskModal } from 'views/Task/CreateTaskModal';

import { columns, StaffTaskColumn } from './columns';

import styles from './StaffTaskLibrary.module.scss';

interface Props {
  user: User;
}

export const StaffTaskLibrary: FC<React.PropsWithChildren<Props>> = ({
  user
}) => {
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 1000);

  const onToggleCreateFolderModal = useCallback(() => {
    setIsCreateFolderModalOpen(
      (prevIsCreateFolderModalOpen) => !prevIsCreateFolderModalOpen
    );
  }, []);

  const onToggleCreateTaskModal = useCallback(() => {
    setIsCreateTaskModalOpen(
      (prevIsCreateTaskModalOpen) => !prevIsCreateTaskModalOpen
    );
  }, []);

  const { data, hasNextPage, fetchNextPage, isFetching, isError } =
    useInfiniteQuery<StaffTaskColumn[], ApiError>(
      queryKeys.filteredTasks({
        search: debouncedSearch,
        createdByIdEq: user.id,
        organizationIdEq: user.organizationId,
        parentIdIsNull: true,
        parentIdEq: undefined,
        sharableEq: false
      }),
      async ({ pageParam }): Promise<StaffTaskColumn[]> => {
        const tasks: Task[] = await fetchTasks({
          search: debouncedSearch === '' ? undefined : debouncedSearch,
          sort: [TaskSort.ByName],
          limit: DEFAULT_PAGE_LIMIT,
          offset: pageParam?.offset || 0,
          createdByIdEq: user.id,
          organizationIdEq: user.organizationId,
          parentIdIsNull: true,
          exclude: ['steps'],
          parentIdEq: undefined,
          sharableEq: false
        });

        const folderIds = (tasks || []).reduce((acc: string[], task) => {
          if (task.isFolder) {
            acc.push(task.id);
          }

          return acc;
        }, []);

        const folderTasks = folderIds?.length
          ? await fetchTasks({
              sort: ['learnerLibraryIndex:ASC'],
              parentIdIn: folderIds,
              createdByIdEq: user.id,
              organizationIdEq: user.organizationId,
              exclude: ['steps'],
              limit: MAX_PAGE_LIMIT,
              sharableEq: false
            })
          : null;

        const assignedIds = [...tasks, ...(folderTasks || [])].reduce(
          (acc: string[], { assignedToId }) => {
            if (assignedToId) {
              acc.push(assignedToId);
            }

            return acc;
          },
          []
        );

        const learners: User[] = await fetchUsers({
          sort: ['firstName:ASC'],
          limit: DEFAULT_PAGE_LIMIT,
          idIn: [...new Set(assignedIds)]
        });

        const learnersMap: Map<string, User> = learners.reduce(
          (acc, learner) => {
            acc.set(learner.id, learner);

            return acc;
          },
          new Map()
        );

        const folderTasksMap = (folderTasks || []).reduce((acc, task) => {
          if (task.parentId) {
            const taskWithUser = {
              ...task,
              imageUrl: task.imageUrl,
              user: learnersMap.get(task.assignedToId || '')
            };

            acc[task.parentId] = acc[task.parentId]?.length
              ? [...acc[task.parentId], taskWithUser]
              : [taskWithUser];
          }

          return acc;
        }, {});

        return tasks.map((task) => ({
          ...task,
          user: task.assignedToId ? learnersMap.get(task.assignedToId) : null,
          imageUrl: task.imageUrl,
          isFolder: task.isFolder,
          ...(task.isFolder && { subRows: folderTasksMap[task.id] })
        }));
      },
      {
        staleTime: ACCESS_TOKEN_EXPIRATION,
        retry: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.length < DEFAULT_PAGE_LIMIT) {
            return undefined;
          }

          return {
            offset: allPages.flat().length
          };
        }
      }
    );

  const isInitialLoading = useInitialLoading(isFetching);

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles.container}>
        <Grid item xs={12}>
          <TextField
            size="small"
            role="search"
            label="Search"
            variant="outlined"
            value={search}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            className={styles.search}
            id="outlined-search-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={styles.icon} />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <LoadingOverlay loading={isInitialLoading}>
          <If condition={!isInitialLoading}>
            <DataTable
              isLoading={isFetching}
              hasNextPage={hasNextPage}
              onLoadMore={fetchNextPage}
              data={data?.pages.flat() || []}
              dndDisabled
              className={styles.table}
              columns={columns}
              emptyMessage={
                debouncedSearch
                  ? EmptyStateMessages.Search
                  : EmptyStateMessages.TaskPage
              }
            />
          </If>

          <CreateTaskFab
            onCreateTask={onToggleCreateTaskModal}
            onCreateFolder={onToggleCreateFolderModal}
          />
        </LoadingOverlay>
      </div>

      <CreateFolderModal
        options={{
          createdById: user.id,
          organizationIdEq: user.organizationId
        }}
        onCloseModal={onToggleCreateFolderModal}
        isOpen={isCreateFolderModalOpen}
      />

      <CreateTaskModal
        options={{
          createdById: user.id,
          organizationIdEq: user.organizationId
        }}
        onCloseModal={onToggleCreateTaskModal}
        isOpen={isCreateTaskModalOpen}
      />
    </>
  );
};
