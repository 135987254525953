import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Modal } from 'components/Modal';
import { Routes } from 'enums/Routes.enum';
import { getRedirectUrl } from 'utils/helpers/urlHelper';
import { OIDC_CONFIG } from 'utils/oidcConfig';

import type { CRSessionMonitor } from './CRSessionMonitor';
import {
  DEFAULT_TIMEOUT_MINUTES,
  getSessionMonitor,
  loadScript,
  removeScript
} from './utils';

import styles from '../AuthCallback/SignoutCallback.module.scss';

export enum UserSessionMessage {
  RefreshSession,
  InactiveWarining,
  UserInteraction,
  SessionRefreshed
}

const UserSessionChannelName = 'user_session_channel';
const defaultSessionTimeout = OIDC_CONFIG.seconds_warning_before_timeout;

export const SSOKeepAlive = () => {
  const { user } = useAuth();
  const navigate = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [channel] = useState(new BroadcastChannel(UserSessionChannelName));
  const [countdown, setCountdown] = useState(defaultSessionTimeout);

  const timeoutSeconds =
    ((user?.profile.timeout as number) ?? DEFAULT_TIMEOUT_MINUTES) * 60;

  const refresh = () => {
    const sessionMonitor = getSessionMonitor();
    sessionMonitor.enable_interaction_updates();
    channel.postMessage(UserSessionMessage.RefreshSession);
    setIsOpen(false);
    setCountdown(defaultSessionTimeout);
  };

  const initBroadcastChannel = useCallback(
    (sessionMonitor: CRSessionMonitor) => {
      channel.onmessage = (event) => {
        switch (event.data) {
          case UserSessionMessage.RefreshSession:
            sessionMonitor.enable_interaction_updates();
            setIsOpen(false);
            setCountdown(defaultSessionTimeout);
            break;
          case UserSessionMessage.InactiveWarining:
            sessionMonitor.disable_interaction_updates();
            setIsOpen(true);
            break;
          case UserSessionMessage.UserInteraction:
            sessionMonitor.update_last_active();
            break;
          case UserSessionMessage.SessionRefreshed:
            sessionMonitor.session_refreshed();
            break;
          default:
            break;
        }
      };
    },
    [channel.onmessage]
  );

  const onTimeout = useCallback(() => {
    if (navigate.location.pathname === Routes.UserSignedOut) return;
    const state = getRedirectUrl(
      navigate.location.pathname,
      navigate.location.search
    );
    navigate.push(`${Routes.UserSignedOut}?state=${state}`);
  }, [navigate]);

  const initSessionMonitor = useCallback(
    (sessionMonitor: CRSessionMonitor, timeout: number) => {
      initBroadcastChannel(sessionMonitor);

      sessionMonitor.clear_event_listeners();
      channel.postMessage(UserSessionMessage.RefreshSession);

      sessionMonitor.add_user_activity_event_listener(() => {
        sessionMonitor.update_last_active();
        channel.postMessage(UserSessionMessage.UserInteraction);
      });

      sessionMonitor.add_timeout_warning_event_listener(() => {
        sessionMonitor.disable_interaction_updates();
        channel.postMessage(UserSessionMessage.InactiveWarining);
        setIsOpen(true);
      });

      sessionMonitor.add_timeout_event_listener(onTimeout);

      sessionMonitor.add_keep_alive_event_listener(() => {
        channel.postMessage(UserSessionMessage.SessionRefreshed);
      });

      sessionMonitor.start({
        warningSeconds: OIDC_CONFIG.seconds_warning_before_timeout,
        timeoutSeconds: timeout,
        keepAliveSeconds: 60,
        userInteractionEventSeconds: 1,
        baseUrl: OIDC_CONFIG.authority
      });
    },
    [channel, onTimeout, initBroadcastChannel]
  );

  useEffect(() => {
    let script: HTMLScriptElement;
    const executeInit = async () => {
      try {
        const scriptResult = await loadScript();
        const sessionMonitor = getSessionMonitor();
        if (sessionMonitor && !sessionMonitor.is_running()) {
          initSessionMonitor(sessionMonitor, timeoutSeconds);
        }

        if (scriptResult.alreadyInit) {
          return;
        }

        removeScript(scriptResult.script as HTMLScriptElement);
      } catch (error) {
        console.error('Script loading failed:', error);
      }
    };
    executeInit();

    return () => {
      removeScript(script);
    };
  }, [timeoutSeconds, navigate, channel, initSessionMonitor]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isOpen) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isOpen]);

  return (
    <div>
      <Modal isOpen={isOpen} onCloseModal={refresh} title="Session Timeout">
        <Box>
          Your session will expire in <b>{countdown} seconds</b>.
        </Box>
        <Box>To continue your session, select Stay Signed In.</Box>
        <Box className={styles.login}>
          <Button
            size="small"
            aria-label="Refresh Session"
            onClick={() => refresh()}
            color="primary"
            variant="contained"
          >
            Stay Signed In
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
