import React, { FC, useEffect, useRef, useState } from 'react';
import { ArrowBack, ArrowForward, Replay } from '@mui/icons-material';
import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import microphoneIcon from 'assets/microphone.svg';
import cn from 'classnames';
import { If } from 'components/If';
import { Step } from 'entities/Step.entity';

import styles from './StepPreview.module.scss';

interface Props {
  taskName: string;
  steps: Step[];
  onClose: () => void;
}

export const StepPreview: FC<React.PropsWithChildren<Props>> = ({
  taskName,
  steps,
  onClose
}) => {
  const [stepNumber, setStepNumber] = useState(1);
  const currentStep = steps[stepNumber - 1];

  const mediaRef = useRef<HTMLVideoElement>(null);

  const isLastStep = stepNumber === steps.length;

  const handlePrevStep = () => setStepNumber((prev) => prev - 1);

  const handleNextStep = () =>
    isLastStep ? onClose() : setStepNumber((prev) => prev + 1);

  const handleReplay = () => {
    if (mediaRef?.current?.currentTime) {
      mediaRef.current.currentTime = 0;
      mediaRef.current.play();
    }
  };

  useEffect(() => {
    if (mediaRef.current) {
      mediaRef.current.pause();
      mediaRef.current.load();
      mediaRef.current.play();
    }
  }, [currentStep.audioUrl, currentStep.videoUrl]);

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      className={styles['step-preview']}
    >
      <Typography variant="body2" className={styles.title}>
        {taskName}
      </Typography>

      <Grid className={styles['step-content']}>
        <Typography className={styles['step-title']}>
          {currentStep.title}
        </Typography>

        <Grid className={styles['content-wrap']}>
          <If condition={!!currentStep.text}>
            <Typography className={styles['content-text']}>
              {currentStep.text}
            </Typography>
          </If>
          <If condition={!!currentStep.audioUrl}>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={styles['content-audio']}
            >
              {currentStep.imageUrl ? (
                <img
                  src={currentStep.imageUrl}
                  alt={currentStep.title}
                  className={styles.image}
                />
              ) : (
                <Grid
                  container
                  alignContent="center"
                  justifyContent="center"
                  className={styles['microphone-img']}
                >
                  <img src={microphoneIcon} alt={currentStep.title} />
                </Grid>
              )}
              <audio
                autoPlay
                controls
                className={styles.audio}
                ref={mediaRef}
                data-testid="preview-audio"
              >
                <source src={currentStep.audioUrl} />
                <track
                  kind="captions"
                  label={currentStep.audio?.originalName}
                />
              </audio>
            </Grid>
          </If>
          <If condition={!!(currentStep.imageUrl && !currentStep.audioUrl)}>
            <img
              src={currentStep.imageUrl}
              alt={currentStep.title}
              className={styles.image}
            />
          </If>
          <If condition={!!currentStep.videoUrl}>
            <video
              ref={mediaRef}
              autoPlay
              controls
              className={styles.video}
              data-testid="preview-video"
            >
              <source src={currentStep.videoUrl} />
              <track kind="captions" label={currentStep.video?.originalName} />
            </video>
          </If>
        </Grid>
      </Grid>

      <Grid container className={styles.actions}>
        <Button
          color="primary"
          variant="contained"
          className={styles['step-action-btn']}
          disabled={stepNumber === 1}
          onClick={handlePrevStep}
        >
          <ArrowBack className={styles['step-action-btn-icon']} />
          <span>Prev step</span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={cn(styles['replay-btn'], styles['step-action-btn'])}
          onClick={handleReplay}
        >
          <Replay className={styles['step-action-btn-icon']} />
          <span>Replay</span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={styles['step-action-btn']}
          onClick={handleNextStep}
        >
          {!isLastStep && (
            <ArrowForward className={styles['step-action-btn-icon']} />
          )}
          <span>{isLastStep ? 'Finish' : 'Next step'}</span>
        </Button>
      </Grid>

      <Grid container justifyContent="space-between" className={styles.stepper}>
        {steps.map((step, number) => (
          <LinearProgress
            key={step.id}
            variant="determinate"
            className={cn(
              styles['linear-progress'],
              number === 0 && styles['first-linear-progress'],
              number === steps.length - 1 && styles['last-linear-progress']
            )}
            value={number < stepNumber ? 100 : 0}
          />
        ))}
      </Grid>

      <Button fullWidth color="primary" variant="outlined" onClick={onClose}>
        Close
      </Button>
    </Grid>
  );
};
