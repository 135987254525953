import { Association } from 'entities/Association.entity';
import { UserDTO } from 'entities/DTO/UserDTO.entity';
import { Organization } from 'entities/Organization.entity';
import { User } from 'entities/User.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import * as queryString from 'query-string';

import { ApiBase } from '../ApiBase';

export type UsersQuery = {
  sort: string[];
  idIn?: string[];
  limit?: number;
  offset?: number;
  search?: string;
  statusEq?: EntityStatus;
  statusNe?: EntityStatus;
  idNe?: string;
  statusIn?: EntityStatus[];
  roleIn?: UserRoles[];
  organizationIdEq?: string;
};

export type UserResponse = Omit<
  typeof User,
  | 'fullName'
  | 'initials'
  | 'formattedCreatedAt'
  | 'formattedLastActive'
  | 'isLearner'
  | 'formattedRole'
>;

class UserApi extends ApiBase {
  updateUserData(userId: string, userUpdateDTO: Partial<UserDTO>) {
    return this.client.patch<UserResponse>(
      `/${userId}`,
      UserDTO.serialize(userUpdateDTO)
    );
  }

  fetchUserData(userId: string) {
    return this.client.get<UserResponse>(`/${userId}`);
  }

  fetchUserByUsername(username: User['username']) {
    return this.client.get<UserResponse>(`/by-username/${username}`);
  }

  changePassword(
    userId: string,
    passwordData: { oldPassword: string; newPassword: string }
  ) {
    return this.client.post<null>(`/${userId}/change-password`, passwordData);
  }

  fetchUsers({ sort, ...params }: UsersQuery) {
    const sortQuery = queryString.stringify(
      { sort },
      {
        arrayFormat: 'comma'
      }
    );
    const query = queryString.stringify(params, {
      arrayFormat: 'none'
    });

    return this.client.get<UserResponse[]>(
      `${process.env.REACT_APP_BASE_URL}/users?${sortQuery}${
        sortQuery && query ? '&' : ''
      }${query}`,
      { baseURL: '' }
    );
  }

  activateUsers(userIds: Array<User['id']>) {
    const query = queryString.stringify({ idIn: userIds });

    return this.client.post<null>(`/activate?${query}`);
  }

  deactivateUsers(userIds: Array<User['id']>) {
    const query = queryString.stringify({ idIn: userIds });

    return this.client.post<null>(`/deactivate?${query}`);
  }

  archiveUsers(userIds: Array<User['id']>) {
    const query = queryString.stringify({ idIn: userIds });

    return this.client.delete<null>(`/archive?${query}`);
  }

  transferUser(userId: User['id'], organizationId: Organization['id']) {
    return this.client.post<UserResponse>(`/${userId}/transfer`, {
      organizationId
    });
  }

  updateReceiveEmailNotifications(
    userId: User['id'],
    receiveEmailNotifications: boolean
  ) {
    return this.client.post<UserResponse>(`/${userId}/email-notifications`, {
      receiveEmailNotifications
    });
  }

  sendResetPasswordLink(userId: User['id']) {
    return this.client.post<null>(`/${userId}/request-to-change-password`);
  }

  fetchAssociations(userId: User['id']) {
    return this.client.get<Association[]>(`/${userId}/associations`);
  }

  resendVerificationEmail(userId: User['id']) {
    return this.client.post<null>('resend-caregiver-welcome-email', {
      userId
    });
  }
}

const instance = new UserApi('/users');

export { instance as UserApi };
