import React, { FC, useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Snackbar, SnackbarContent } from '@mui/material';
import cn from 'classnames';
import { Task } from 'entities/Task.entity';
import { checkForPlural } from 'utils/helpers/stringHelpers';
import { CopyToOrganizationModal } from 'views/Library/CopyToOrganizationModal';

import styles from './TaskStatusBar.module.scss';

interface Props {
  selectedTasks: Task[];
  onSuccess: () => void;
}

export const TaskStatusBar: FC<React.PropsWithChildren<Props>> = ({
  selectedTasks,
  onSuccess
}) => {
  const taskIds = selectedTasks.map((task) => task.id);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

  const onToggleIsCopyModalOpen = useCallback(() => {
    setIsCopyModalOpen((prevIsCopyModalOpen) => !prevIsCopyModalOpen);
  }, []);

  return (
    <>
      <Snackbar
        open={!!selectedTasks.length}
        className={cn(styles.container, styles.snackbar)}
      >
        <SnackbarContent
          message={`You've selected ${
            selectedTasks.length
          } item${checkForPlural(selectedTasks.length)}`}
          action={
            <LoadingButton
              color="primary"
              variant="contained"
              className={styles['action-button']}
              loadingPosition="center"
              onClick={onToggleIsCopyModalOpen}
            >
              Copy to organization library
            </LoadingButton>
          }
          className={styles['snackbar-content']}
        />
      </Snackbar>
      <CopyToOrganizationModal
        taskIds={taskIds}
        isOpen={isCopyModalOpen}
        onCloseModal={onToggleIsCopyModalOpen}
        onSuccess={onSuccess}
      />
    </>
  );
};
