import React, { FC } from 'react';
import { If } from 'components/If';
import { SnackBar } from 'components/SnackBar';

interface Props {
  className?: string;
  isSuccess?: boolean;
  isError?: boolean;
  successMessage?: string;
  errorMessage?: string;
  hideDuration?: number;
  onClose?: () => void;
}

export const StatusSnackBar: FC<React.PropsWithChildren<Props>> = ({
  className,
  onClose,
  hideDuration,
  isSuccess = false,
  isError = false,
  successMessage = '',
  errorMessage = ''
}) => (
  <If condition={isError || isSuccess}>
    <SnackBar
      onClose={onClose}
      className={className}
      hideDuration={hideDuration}
      isOpen={isError || isSuccess}
      theme={isError ? 'error' : 'success'}
      message={isError ? errorMessage : successMessage}
    />
  </If>
);
