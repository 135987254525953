import React, { FC } from 'react';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { SvgIcon } from '@mui/material';
import cn from 'classnames';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';

import styles from './EmptyState.module.scss';

interface Props {
  title?: string;
  className?: string;
  icon?: typeof SvgIcon | null;
}

export const EmptyState: FC<React.PropsWithChildren<Props>> = ({
  title = EmptyStateMessages.Default,
  className,
  icon = WysiwygIcon
}) => {
  const IconComponent = icon;

  return (
    <div className={cn(styles['empty-state'], className)}>
      <div className={styles['main-container']}>
        {IconComponent && (
          <div className={styles['icon-container']}>
            <IconComponent
              className={styles.icon}
              data-testid="empty-state-icon"
            />
          </div>
        )}

        <p className={styles.title}>{title}</p>
      </div>
    </div>
  );
};
