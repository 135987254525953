import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Avatar } from '@mui/material';
import imagePlaceholder from 'assets/image-placeholder.png';
import { Association } from 'entities/Association.entity';
import { File } from 'entities/File.entity';

import styles from './AssociationRow.module.scss';

interface Props {
  navTo: string;
  image?: File['url'];
  label: Association['fullName'];
}

export const AssociationRow: FC<React.PropsWithChildren<Props>> = ({
  image,
  label,
  navTo
}) => (
  <div className={styles['association-row']} data-testid="association-row">
    <Avatar
      variant="circular"
      alt={`${label} avatar`}
      className={styles.avatar}
      src={image || imagePlaceholder}
    />
    <NavLink to={navTo} className={styles['nav-link']} target="_blank">
      <h5 className={styles.label}>{label}</h5>
    </NavLink>
  </div>
);
