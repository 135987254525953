import React, { FC, useCallback } from 'react';
import { TableLoadingRow } from 'components/DataTable/TableLoadingRow';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { Task } from 'entities/Task.entity';
import { useLoadMoreLessons } from 'hooks/Task/useLoadMoreLessons';
import { useSharedLibraryContext } from 'hooks/Task/useSharedLibraryContext';
import { TaskCard } from 'views/SharedLibrary/TaskCard';

import styles from './TaskCardGrid.module.scss';

interface Props {
  data: Task[];
  isLoading?: boolean;
  isInitialLoading?: boolean;
  onLoadMore?: () => void;
  emptyMessage: string;
  onAddToSharedLibrary?: (taskId: Task['id']) => void;
  onAddToMyTasks?: (taskId: Task['id']) => void;
}

const PageSpinner = () => (
  <div className={styles['page-loader']}>
    <span className={styles['page-spinner']} />
  </div>
);

export const TaskCardGrid: FC<React.PropsWithChildren<Props>> = ({
  data,
  isLoading,
  onLoadMore,
  emptyMessage,
  isInitialLoading,
  onAddToSharedLibrary,
  onAddToMyTasks
}) => {
  const lastLoadedItemRef = useLoadMoreLessons(data, onLoadMore);
  const { activeRepertoire, onChangeActiveTask } = useSharedLibraryContext();

  const renderCards = useCallback<() => React.ReactElement>(() => {
    if (!data) {
      return <TableLoadingRow />;
    }

    return (
      <>
        {data.map((lesson, index) => (
          <div
            key={lesson.id}
            ref={index === data.length - 1 ? lastLoadedItemRef : undefined}
            className={styles['card-item-container']}
          >
            <TaskCard
              task={lesson}
              onSelectCard={() => onChangeActiveTask(lesson)}
              onAddToSharedLibrary={onAddToSharedLibrary}
              onAddToMyTasks={onAddToMyTasks}
            />
          </div>
        ))}
      </>
    );
  }, [
    data,
    lastLoadedItemRef,
    onAddToMyTasks,
    onAddToSharedLibrary,
    onChangeActiveTask
  ]);

  return (
    <div className={styles['task-card-grid']}>
      <h3 className={styles.title}>{activeRepertoire?.title}</h3>
      <If condition={!data.length && !isLoading}>
        <EmptyState title={emptyMessage} />
      </If>

      <If condition={data.length > 0 && !isInitialLoading}>
        <div className={styles.content}>
          <div className={styles['cards-scroll-container']}>
            {renderCards()}
          </div>
          {isLoading && <PageSpinner />}
        </div>
      </If>
    </div>
  );
};
