import { JournalTemplateType } from 'enums/JournalTemplateType.enum';
import { deserialize, list, object, primitive, serializable } from 'serializr';

import { CheckinDTO } from './CheckinDto.entity';

export class JournalTemplateDTO {
  @serializable
  templateId = '';

  @serializable
  type: JournalTemplateType = JournalTemplateType.Checkin;

  @serializable
  title? = '';

  @serializable
  description? = '';

  @serializable(list(object(CheckinDTO)))
  checkin?: CheckinDTO[];

  static deserialize(json: Object | string): JournalTemplateDTO {
    return deserialize(JournalTemplateDTO, json);
  }

  static deserializeAsList(list: JournalTemplateDTO[]): JournalTemplateDTO[] {
    return list.map(JournalTemplateDTO.deserialize);
  }
}
