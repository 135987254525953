import React from 'react';
import { Column, Row } from 'react-table';
import { Checkbox, Chip, Grid } from '@mui/material';
import { Task } from 'entities/Task.entity';
import { Routes } from 'enums/Routes.enum';
import { TaskColumn } from 'hooks/Task/useTasksContext';
import { FolderCell } from 'views/Library/FolderCell';
import { OrganizationLibraryActionMenu } from 'views/Organizations/OrganizationLibrary/OrganizationLibraryActionMenu';
import { UserTableNavCell } from 'views/People/UserTableNavCell';

import styles from './OrganizationLibraryPage.module.scss';

type UserProps = {
  fullName: string;
  id: string;
  avatarUrl?: string | null;
};

export const columns: Column<TaskColumn>[] = [
  {
    id: 'name',
    Header: 'Name',
    accessor: (task): TaskColumn => task,
    Cell: ({ value, row }: { value: TaskColumn; row: Row<Task> }) =>
      value.isFolder ? (
        <FolderCell
          data={value}
          row={row}
          isDragIconVisible
          showCareIcon
          showRepertoire
        />
      ) : (
        <Grid container flexWrap="nowrap">
          <Checkbox
            checked={row.isSelected}
            onChange={row.getToggleRowSelectedProps().onChange}
            inputProps={{
              'aria-label': 'Select lesson'
            }}
          />
          <UserTableNavCell
            rounded
            isDragIconVisible
            title={value.title}
            imageURL={value.imageUrl}
            route={Routes.OrganizationTaskDetails}
            showCareIcon={value.availLibraryTaskReflection}
            params={{
              id: value.id
            }}
            task={{
              id: value.id,
              title: value.title,
              stepsAmount: value.stepsAmount
            }}
          />
        </Grid>
      ),
    width: 3,
    minWidth: 350
  },
  {
    id: 'stepsAmount',
    Header: 'Steps',
    accessor: ({ isFolder, stepsAmount }) => (isFolder ? '' : stepsAmount || 0),
    width: 1,
    minWidth: 70
  },
  {
    id: 'createdByUserList',
    Header: 'Created by',
    accessor: (row) => (row?.createdByUserList ? row.createdByUserList : null),
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    Cell: ({ value }: { value: UserProps | null }) => {
      if (!value) {
        return <>-</>;
      }

      return (
        <UserTableNavCell
          rounded
          title={value.fullName}
          imageURL={value.avatarUrl}
          params={{ id: value.id }}
          route={Routes.StaffDetails}
        />
      );
    },
    width: 3,
    minWidth: 150
  },
  {
    id: 'assignedTo',
    Header: 'Assigned to',
    accessor: (row) => (row?.assignedTo ? row.assignedTo : null),
    Cell: ({ value }: { value: UserProps | null }) =>
      value ? (
        <UserTableNavCell
          rounded
          title={value.fullName}
          imageURL={value.avatarUrl}
          params={{ id: value.id }}
          route={Routes.StaffDetails}
        />
      ) : (
        <Chip size="small" label="Unassigned" className={styles.chip} />
      ),
    width: 3,
    minWidth: 150
  },
  {
    id: 'id',
    Header: '',
    accessor: (task): Task => task,
    Cell: ({ value }: { value: Task }) => (
      <OrganizationLibraryActionMenu task={value} />
    ),
    width: 1,
    minWidth: 50
  }
];
