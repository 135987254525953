import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { FileUploader } from 'components/FileUploader';
import { If } from 'components/If';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { UserRoleLabels, UserRoles } from 'enums/UserRoles.enum';
import { isEmailValid } from 'utils/helpers/validators';

import styles from './CreateStaffForm.module.scss';

export const staffRolesOptions = [
  UserRoles.Manager,
  UserRoles.Facilitator,
  UserRoles.PassiveFacilitator
].map((value) => ({
  value,
  label: UserRoleLabels[value]
}));

export interface FormData {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  phoneNumber?: string;
  image?: string | null;
}

interface Props {
  isLoading: boolean;
  onCloseModal: () => void;
  onSubmit: (formData: FormData) => void;
}

export const CreateStaffForm: FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  isLoading,
  onCloseModal
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      role: ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  return (
    <form
      className={styles['create-staff-form']}
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
    >
      <Controller
        control={control}
        name="image"
        render={({ field: { onChange } }) => {
          return (
            <FileUploader label="Profile picture" onChangeFile={onChange} />
          );
        }}
      />
      <Controller
        rules={{ required: true }}
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="First name"
            margin="normal"
            inputRef={field.ref}
            error={!!errors?.firstName}
            helperText={errors?.firstName && ErrorMessages.FailedRequiredField}
            id="outlined-first-name-input"
          />
        )}
      />
      <Controller
        rules={{ required: true }}
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Last name"
            margin="normal"
            error={!!errors?.lastName}
            aria-invalid={!!errors?.lastName}
            helperText={errors?.lastName && ErrorMessages.FailedRequiredField}
            id="outlined-last-name-input"
          />
        )}
      />
      <Controller
        name="role"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <FormControl className={styles.select} error={!!errors?.role}>
            <InputLabel htmlFor="user-role-outlined-select-label">
              Role
            </InputLabel>
            <Select
              {...field}
              data-testid="user-role-select"
              input={
                <OutlinedInput
                  label="Role"
                  id="user-role-outlined-select-label"
                />
              }
            >
              {staffRolesOptions.map(({ value, label }) => (
                <MenuItem
                  key={value}
                  value={value}
                  data-testid="user-role-option"
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
            <If condition={!!errors?.role}>
              <FormHelperText>
                {ErrorMessages.FailedRequiredField}
              </FormHelperText>
            </If>
          </FormControl>
        )}
      />
      <Controller
        rules={{
          validate: (value) => {
            if (!value) {
              return ErrorMessages.FailedRequiredField;
            }
            if (!isEmailValid(value)) {
              return ErrorMessages.NotValidEmail;
            }

            return true;
          }
        }}
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Email"
            margin="normal"
            error={!!errors?.email}
            id="outlined-email-input"
            aria-invalid={!!errors?.email}
            helperText={errors?.email?.message}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Phone number"
            margin="normal"
            id="outlined-phone-number-input"
          />
        )}
      />
      <div className={styles.footer}>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
