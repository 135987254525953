import React, { FC } from 'react';
import { Add } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { IconMenu } from 'components/IconMenu';
import { If } from 'components/If';

import styles from './CreateTaskFab.module.scss';

interface Props {
  onCreateTask: () => void;
  onCreateFolder: () => void;
  showCreateFolderButton?: boolean;
}

export const CreateTaskFab: FC<React.PropsWithChildren<Props>> = ({
  onCreateTask,
  onCreateFolder,
  showCreateFolderButton = true
}) => (
  <span className={styles['fab-link']}>
    <div className={styles.fab}>
      <IconMenu
        rootClass={styles['icon-menu-root']}
        paperClass={styles['icon-menu-paper']}
        buttonLabel="Create new lesson or module"
        menuPosition={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        }}
        icon={
          <Add
            data-testid="add-tasks-menu-button"
            className={styles['add-icon-button']}
          />
        }
      >
        <If condition={showCreateFolderButton}>
          <MenuItem onClick={onCreateFolder}>
            <Add className={styles['add-icon']} /> Create Module
          </MenuItem>
        </If>

        <MenuItem onClick={onCreateTask}>
          <Add className={styles['add-icon']} /> Create Lesson
        </MenuItem>
      </IconMenu>
    </div>
  </span>
);
