import { ApiBase } from '../ApiBase';

class ModuleApi extends ApiBase {
  fetchPreassignedLessons(moduleId: string, userId: string) {
    return this.client.get<{
      isModulePreassigned: boolean;
      lessons: {
        id: string;
        title: string;
      }[];
    }>(`/modules/${moduleId}/lessons/preassigned/${userId}`);
  }
}

const instance = new ModuleApi();

export { instance as ModuleApi };
