import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TableLoadingRow } from 'components/DataTable/TableLoadingRow';
import { EmptyState } from 'components/EmptyState';
import { Task } from 'entities/Task.entity';
import { DndDroppableContainerIds } from 'enums/TableDnDType.enum';
import { useTasksContext } from 'hooks/Task/useTasksContext';
import { SharedLibraryListItem } from 'views/Organizations/OrganizationLibrary/SharedLibraryListItem';

import styles from './SharedLibraryList.module.scss';

const DEFAULT_ROW_HEIGHT = 64;
const DEFAULT_THRESHOLD = 10;

interface Props {
  data: Task[];
  hasNextPage?: boolean;
  isLoading?: boolean;
  onLoadMore?: () => void;
  emptyMessage: string;
  onChangeActiveFolder: Dispatch<SetStateAction<Task | null>>;
}

const emptyFunction = () => {};

export const SharedLibraryList: FC<React.PropsWithChildren<Props>> = ({
  data,
  hasNextPage,
  isLoading,
  onLoadMore = emptyFunction,
  emptyMessage,
  onChangeActiveFolder
}) => {
  const checkItemLoaded = useCallback<(index: number) => boolean>(
    (index) => !hasNextPage || index < data.length,
    [hasNextPage, data.length]
  );

  const { activeFolder } = useTasksContext();

  const itemCount = hasNextPage ? data.length + 1 : data.length;

  const renderItems = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const task = data[index];

      if (!task) {
        return <TableLoadingRow />;
      }

      return (
        <Draggable
          draggableId={`${task.isFolder ? 'row-' : ''}${task.id}`}
          index={index}
          key={task.id}
        >
          {(draggableProvided) => (
            <div
              key={task.id}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              style={style}
            >
              <SharedLibraryListItem
                task={task}
                onSetActiveFolder={() => onChangeActiveFolder(task)}
              />
            </div>
          )}
        </Draggable>
      );
    },
    [data, onChangeActiveFolder]
  );

  if (!data.length && !isLoading) {
    return (
      <Droppable droppableId={DndDroppableContainerIds.SharedLibrary}>
        {(provided) => (
          <div className={styles['empty-state-box']} ref={provided.innerRef}>
            <EmptyState title={emptyMessage} />
          </div>
        )}
      </Droppable>
    );
  }

  return (
    <Droppable
      droppableId={DndDroppableContainerIds.SharedLibrary}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => {
        const task = data[rubric.source.index];
        const showCopyIcon =
          snapshot.draggingOver === DndDroppableContainerIds.DataTable &&
          (!task.isFolder || !activeFolder);

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              opacity: 1
            }}
            key={task?.id}
            className={styles['list-item']}
          >
            {showCopyIcon && (
              <ContentCopyIcon className={styles['copy-icon']} />
            )}
            <SharedLibraryListItem
              task={task}
              onSetActiveFolder={() => onChangeActiveFolder(task)}
              isDragging={snapshot.isDragging}
            />
          </div>
        );
      }}
    >
      {(provided) => (
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => {
            return (
              <InfiniteLoader
                isItemLoaded={checkItemLoaded}
                itemCount={itemCount}
                loadMoreItems={isLoading ? emptyFunction : onLoadMore}
                threshold={DEFAULT_THRESHOLD}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    itemSize={DEFAULT_ROW_HEIGHT}
                    ref={ref}
                    outerRef={provided.innerRef}
                  >
                    {renderItems}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            );
          }}
        </AutoSizer>
      )}
    </Droppable>
  );
};
