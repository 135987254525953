import React, { FC, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button } from '@mui/material';
import { fetchAssociations } from 'actions/User/userActions';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Association } from 'entities/Association.entity';
import { User } from 'entities/User.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { UserRoleLabels, UserRoles } from 'enums/UserRoles.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { AssociationRow } from 'views/People/Associations/AsociationRow';
import { AssociationSection } from 'views/People/Associations/AssociationSection';
import { EditAssociationsModal } from 'views/People/Associations/EditAssociationsModal';

import styles from './LearnerAssociations.module.scss';

interface Props {
  userId: User['id'];
}

export const LearnerAssociations: FC<React.PropsWithChildren<Props>> = ({
  userId
}) => {
  const { data: organizationData } = useOrganizationBySlug();
  const { data: userData } = useLoadAuthUserData();

  const { slug, slugId } = useParams<{
    slugId: string;
    slug: string;
  }>();

  const [isEditAssociationsModalOpen, setIsEditAssociationsModalOpen] =
    useState<boolean>(false);

  const onToggleAssociationsModal = useCallback(() => {
    setIsEditAssociationsModalOpen(
      (prevIsEditAssociationsModalOpen) => !prevIsEditAssociationsModalOpen
    );
  }, []);

  const { data, isError, isLoading } = useQuery<Association[], ApiError>(
    queryKeys.userAssociations(organizationData!.id, userId),
    () => fetchAssociations(userId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const groupedData: Record<UserRoles, Association[]> | Object = useMemo(
    () =>
      data?.length
        ? data.reduce((acc, item) => {
            acc[item.role] = acc[item.role] || [];
            acc[item.role].push(item);
            return acc;
          }, {})
        : [],
    [data]
  );

  const associationOptions = useMemo(
    () =>
      (data || []).map(({ fullName, id }) => ({
        id,
        title: fullName
      })),
    [data]
  );

  const renderAssociationSection = (role: UserRoles) => {
    if (!groupedData?.[role]) {
      return null;
    }

    return (
      <AssociationSection
        label={`${UserRoleLabels[role]}s`}
        className={styles['learner-associations']}
      >
        <>
          {((groupedData[role] || []) as Association[]).map(
            ({ fullName, avatar, id }) => (
              <AssociationRow
                key={fullName}
                label={fullName}
                image={avatar?.url}
                navTo={generatePath(Routes.StaffDetails, {
                  slug,
                  slugId,
                  id
                })}
              />
            )
          )}
        </>
      </AssociationSection>
    );
  };

  if (isError) {
    return (
      <>
        <StatusSnackBar
          isError={isError}
          errorMessage={ErrorMessages.FailedGetRequest}
        />
        <EmptyState title={EmptyStateMessages.Associations} />
      </>
    );
  }

  return (
    <LoadingOverlay loading={isLoading}>
      <If condition={isEditAssociationsModalOpen}>
        <EditAssociationsModal
          userId={userId}
          isOpen={isEditAssociationsModalOpen}
          onCloseModal={onToggleAssociationsModal}
          selectedAssociation={associationOptions}
        />
      </If>
      <Button
        size="small"
        color="secondary"
        variant="contained"
        startIcon={<ModeEditIcon />}
        onClick={onToggleAssociationsModal}
        disabled={userData?.role === UserRoles.Facilitator}
      >
        Edit associations
      </Button>
      <If condition={!!data?.length}>
        {renderAssociationSection(UserRoles.Manager)}
        {renderAssociationSection(UserRoles.Facilitator)}
        {renderAssociationSection(UserRoles.PassiveFacilitator)}
      </If>
      <If condition={!data?.length && !isLoading}>
        <EmptyState
          className={styles.empty}
          title={EmptyStateMessages.Associations}
        />
      </If>
    </LoadingOverlay>
  );
};
