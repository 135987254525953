export enum UserTabs {
  PersonalInformation = 'Profile',
  TaskLibrary = 'TaskLibrary',
  Journal = 'Journals',
  Assessments = 'Data',
  Associations = 'Associations'
}

export const UserTabsLabels = Object.freeze<Record<UserTabs, string>>({
  [UserTabs.PersonalInformation]: 'Profile',
  [UserTabs.TaskLibrary]: 'Lessons',
  [UserTabs.Journal]: 'Journals',
  [UserTabs.Assessments]: 'Data',
  [UserTabs.Associations]: 'Associations'
});
