import React, { FC, useCallback, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { OrganizationDTO } from 'entities/DTO/OrganizationDTO.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';

import styles from './CreateOrganizationLicenses.module.scss';

export const CreateOrganizationLicenses: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const {
    getValues,
    control,
    formState: { errors }
  } = useFormContext();

  const getDefaultValue = useCallback(
    (fieldName: string) => getValues(fieldName) || 0,
    [getValues]
  );

  const managersAmountLimit = useWatch({
    control,
    name: 'managersAmountLimit',
    defaultValue: getDefaultValue('managersAmountLimit')
  });

  const learnersAmountLimit = useWatch({
    control,
    name: 'learnersAmountLimit',
    defaultValue: getDefaultValue('learnersAmountLimit')
  });

  const facilitatorsAmountLimit = useWatch({
    control,
    name: 'facilitatorsAmountLimit',
    defaultValue: getDefaultValue('facilitatorsAmountLimit')
  });

  const passiveFacilitatorsAmountLimit = useWatch({
    control,
    name: 'passiveFacilitatorsAmountLimit',
    defaultValue: getDefaultValue('passiveFacilitatorsAmountLimit')
  });

  const licensesNumber = useMemo(
    () =>
      +managersAmountLimit +
      +learnersAmountLimit +
      +facilitatorsAmountLimit +
      +passiveFacilitatorsAmountLimit,
    [
      facilitatorsAmountLimit,
      learnersAmountLimit,
      managersAmountLimit,
      passiveFacilitatorsAmountLimit
    ]
  );

  const renderLicenseField = (label: string, name: keyof OrganizationDTO) => (
    <Grid item xs={6}>
      <p className={styles.label}>{label}</p>
      <Controller
        rules={{ required: true }}
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            margin="normal"
            variant="outlined"
            inputRef={field.ref}
            label="Licenses"
            error={!!errors?.[name]}
            aria-invalid={!!errors?.[name]}
            inputProps={{ maxLength: 9 }}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/^\D+/g, '');

              field.onChange(inputValue ? +inputValue : '');
            }}
            helperText={errors?.[name] && ErrorMessages.FailedRequiredField}
            id={`outlined-${name}-licenses-input`}
          />
        )}
      />
    </Grid>
  );

  return (
    <>
      <Grid container alignItems="stretch" spacing={2} className={styles.row}>
        {renderLicenseField('Managers', 'managersAmountLimit')}
        {renderLicenseField('Caregivers', 'learnersAmountLimit')}
      </Grid>

      <Grid container alignItems="stretch" spacing={2}>
        {renderLicenseField('Facilitators', 'facilitatorsAmountLimit')}
        {renderLicenseField(
          'Passive facilitators',
          'passiveFacilitatorsAmountLimit'
        )}
      </Grid>
      <div className={styles.banner} data-testid="licenses-amount-banner">
        Total: <b>{` ${licensesNumber} licenses`}</b>
      </div>
    </>
  );
};
