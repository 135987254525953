import { useCallback, useMemo } from 'react';
import { InfiniteData } from 'react-query';
import { Task } from 'entities/Task.entity';
import { queryKeys, TaskFilters } from 'enums/QueryKeys.enum';
import { queryClient } from 'queryClient';

interface TaskCacheData {
  prevTasksList?: InfiniteData<Task[]> | undefined;
  updateCache: (pages: Array<Task[]>) => void;
  resetToPrevCache: (context?: TasksContext) => void;
}

export type TasksContext = {
  prevTasksList?: InfiniteData<Task[]> | undefined;
};

export const useTasksCache = (options: TaskFilters = {}): TaskCacheData => {
  const prevTasksList = useMemo(
    () =>
      queryClient.getQueryData<InfiniteData<Task[]>>(
        queryKeys.filteredTasks(options)
      ),
    [options]
  );

  const updateCache = useCallback(
    (pages: Array<Task[]>) => {
      if (prevTasksList) {
        queryClient.setQueriesData<InfiniteData<Task[]>>(
          {
            queryKey: queryKeys.filteredTasks(options),
            exact: false,
            active: true
          },
          {
            pageParams: prevTasksList.pageParams,
            pages
          }
        );
      }
    },
    [options, prevTasksList]
  );

  const resetToPrevCache = (context?: TasksContext) => {
    if (context?.prevTasksList) {
      queryClient.setQueriesData(
        {
          queryKey: queryKeys.filteredTasks(options),
          exact: false,
          active: true
        },
        context.prevTasksList
      );
    }
  };

  return { prevTasksList, updateCache, resetToPrevCache };
};
