import React, { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchTaskAssetHistoryGroup } from 'actions/Task/taskActions';
import ReductionIcon from 'assets/reduction-icon.png';
import cn from 'classnames';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { format, parseISO } from 'date-fns';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { TaskAssetGroup } from 'entities/TaskAssetGroup.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { StepContentTypeTitles } from 'enums/StepContentType.enum';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

import styles from './ReductionTab.module.scss';

const barOptions = {
  maxBarSize: 60,
  stackId: 'date',
  style: {
    opacity: 0.75
  }
};

const barChartMargin = {
  top: 24,
  right: 20,
  left: 0,
  bottom: 0
};

const MIN_CHART_RECORDS_AMOUNT = 5;

interface Props {
  taskId: Task['id'];
  className?: string;
  hideXAxisTick?: boolean;
}

export const ReductionTab: FC<React.PropsWithChildren<Props>> = ({
  taskId,
  className,
  hideXAxisTick
}) => {
  const {
    data: assets,
    isError,
    isLoading
  } = useQuery<TaskAssetGroup[], ApiError>(
    queryKeys.taskAssetHistoryDetails(taskId),
    (): Promise<TaskAssetGroup[]> =>
      fetchTaskAssetHistoryGroup({
        taskId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        limit: 10
      }),
    {
      onSuccess: (data) => data.reverse(),
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  const chartInterval: number = useMemo(
    () =>
      (assets || []).find(({ data }) => {
        const {
          audioStepsCount,
          videoStepsCount,
          imageStepsCount,
          textStepsCount
        } = data;
        return (
          audioStepsCount + videoStepsCount + imageStepsCount + textStepsCount >
          10
        );
      })
        ? 1
        : 0,
    [assets]
  );

  const formatLabel = (value: string) => {
    return value ? format(parseISO(value), 'dd-MMM-yyyy') : 'date';
  };

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <LoadingOverlay loading={isLoading}>
        <div
          data-testid="reduction-tab"
          className={cn(styles['reduction-tab'], className)}
        >
          <If condition={!assets?.length && !isLoading}>
            <EmptyState />
          </If>
          <If condition={!!assets?.length}>
            <div className={styles.header}>
              <h4 className={styles.title}>Steps</h4>
              <img src={ReductionIcon} alt="reduction-icon" />
            </div>
            <ResponsiveContainer width="95%" height="100%" minHeight={250}>
              <BarChart
                width={500}
                height={300}
                data={assets}
                margin={barChartMargin}
              >
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatLabel}
                  tick={!hideXAxisTick}
                  stroke={styles.CrGray}
                  style={{
                    fontSize: '14px'
                  }}
                />
                <YAxis
                  allowDecimals={false}
                  type="number"
                  domain={[
                    'dataMin',
                    (dataMax: number) =>
                      dataMax > MIN_CHART_RECORDS_AMOUNT
                        ? dataMax
                        : MIN_CHART_RECORDS_AMOUNT
                  ]}
                  interval={chartInterval}
                  tickCount={100}
                  style={{
                    fontSize: '12px'
                  }}
                  stroke={styles.CrGray}
                />
                <Tooltip
                  cursor={false}
                  labelFormatter={formatLabel}
                  contentStyle={{
                    borderRadius: 2,
                    fontWeight: 600,
                    fontSize: '14px'
                  }}
                />
                <Bar
                  name={StepContentTypeTitles.video}
                  dataKey="data.videoStepsCount"
                  fill={styles.StepVideo}
                  {...barOptions}
                />
                <Bar
                  name={StepContentTypeTitles.audio}
                  dataKey="data.audioStepsCount"
                  fill={styles.StepAudio}
                  {...barOptions}
                />
                <Bar
                  name={StepContentTypeTitles.image}
                  dataKey="data.imageStepsCount"
                  fill={styles.StepImage}
                  {...barOptions}
                />
                <Bar
                  name={StepContentTypeTitles.text}
                  dataKey="data.textStepsCount"
                  fill={styles.StepText}
                  {...barOptions}
                />
              </BarChart>
            </ResponsiveContainer>
          </If>
        </div>
      </LoadingOverlay>
    </>
  );
};
