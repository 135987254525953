export const STORAGE_KEY_PREFIX = 'CARE_STORAGE_';
export const AUTH_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const SESSION_TOKEN = 'sessionToken';
export const HIDE_DND_BANNER = 'hideDndBanner';
export const ACCESS_TOKEN_EXPIRATION = 1000 * 60 * 30;
export const ACTIVITY_IDLE_TIME = 1000 * 60 * 15;
export const ACTIVITY_PING_TIME = 1000 * 60 * 5;
export const DEFAULT_PAGE_LIMIT = 20;
export const MAX_PAGE_LIMIT = 10000;
