import React, { FC, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, TextField } from '@mui/material';
import cn from 'classnames';
import { FileUploader } from 'components/FileUploader';
import { File } from 'entities/File.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import {
  StepContentType,
  StepContentTypeIcon,
  StepContentTypeTitles
} from 'enums/StepContentType.enum';

import styles from './ContentUploader.module.scss';

const contentTypeValues = Object.values(StepContentType);

interface Props {
  contentData?: {
    image?: File | null;
    video?: File | null;
    audio?: File | null;
    text?: string | null;
  };
}

export const ContentUploader: FC<React.PropsWithChildren<Props>> = ({
  contentData
}) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext();

  const selectedContentTypes = useMemo(
    () => contentTypeValues.filter((contentType) => contentData?.[contentType]),
    [contentData]
  );

  const [contentTypes, setContentType] = useState<StepContentType[]>(
    selectedContentTypes || []
  );

  const isContentTypeSelected = useCallback(() => {
    const { image, video, audio, text } = getValues();

    return image || video || audio || text;
  }, [getValues]);

  const isCardDisabled = useCallback(
    (contentType: StepContentType): boolean => {
      if (!contentTypes.length) {
        return false;
      }

      const hasNotCombinedContent =
        contentTypes.includes(StepContentType.text) ||
        contentTypes.includes(StepContentType.video);

      const isDisabled = {
        [StepContentType.text]: !contentTypes.includes(StepContentType.text),
        [StepContentType.image]: hasNotCombinedContent,
        [StepContentType.video]: !contentTypes.includes(StepContentType.video),
        [StepContentType.audio]: hasNotCombinedContent
      };

      return isDisabled[contentType];
    },
    [contentTypes]
  );

  const handleChange = useCallback(
    (checked: boolean, contentType: StepContentType) => {
      if (checked) {
        setContentType([...contentTypes, contentType]);
      } else {
        setValue(contentType, null);
        setContentType(contentTypes.filter((type) => type !== contentType));
      }
    },
    [contentTypes, setValue]
  );

  const renderContentCard = (contentType: StepContentType) => {
    const Icon = StepContentTypeIcon[contentType];
    const disabled = isCardDisabled(contentType);

    return (
      <div
        key={contentType}
        data-testid="content-card"
        className={cn(styles['content-card'], disabled && styles.disabled)}
      >
        <Icon />
        <span className={styles.title}>
          {StepContentTypeTitles[contentType]}
        </span>
        <Controller
          rules={{
            required: !isContentTypeSelected() && !contentTypes.length
          }}
          control={control}
          name="hasContentType"
          render={({ field: { onChange } }) => {
            return (
              <Checkbox
                color="primary"
                disabled={disabled}
                data-testid="check-content"
                checked={contentTypes.includes(contentType)}
                onChange={({ target: { checked } }) => {
                  onChange(checked);
                  handleChange(checked, contentType);
                }}
                inputProps={{
                  'aria-label': 'Select content type'
                }}
              />
            );
          }}
        />
      </div>
    );
  };

  const renderContentUploader = () =>
    contentTypes.map((contentType) => {
      if (
        [
          StepContentType.image,
          StepContentType.video,
          StepContentType.audio
        ].includes(contentType)
      ) {
        return (
          <div key={contentType} className={styles.content}>
            <Controller
              name={contentType}
              control={control}
              rules={{
                required: contentTypes.includes(contentType)
              }}
              render={({ field: { onChange } }) => {
                return (
                  <FileUploader
                    onChangeFile={onChange}
                    contentType={contentType}
                    label={`Upload ${contentType}`}
                    fileData={contentData?.[contentType] as File | null}
                  />
                );
              }}
            />
            {errors[contentType] && (
              <p className={styles.error}>{ErrorMessages.NoMediaContent}</p>
            )}
          </div>
        );
      }

      return (
        <div key={contentType} className={styles.content}>
          <Controller
            name="text"
            rules={{
              required: contentTypes.includes(StepContentType.text)
            }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                minRows={2}
                maxRows={4}
                type="text"
                label="Text"
                margin="normal"
                variant="outlined"
                className={styles.text}
                id="outlined-text-input"
                error={!!errors?.text}
                aria-label="Text"
                inputProps={{ maxLength: 500 }}
                helperText={errors?.text && ErrorMessages.FailedRequiredField}
              />
            )}
          />
        </div>
      );
    });

  return (
    <div className={styles['content-uploader']}>
      <p className={styles.label}>Content type</p>
      <div className={styles['card-container']}>
        {contentTypeValues.map((value) => renderContentCard(value))}
      </div>
      {errors.hasContentType && (
        <p className={styles.error}>{ErrorMessages.NoStepContentType}</p>
      )}
      {!!contentTypes.length && renderContentUploader()}
    </div>
  );
};
