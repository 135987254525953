import { Comments } from 'entities/Comments.entity';
import { CommentsDTO } from 'entities/DTO/CommentsDTO.entity';
import { Journal } from 'entities/Journal.entity';

import { ApiBase } from '../ApiBase';

export type FacilitatorJournalResponse = {
  facilitatorId: string;
  caregiverId: string;
  journalId: string;
  journalTitle: string;
  lastUpdated: Date;
  sharedOn: Date;
  tags: string[];
  isUnreadJournal: boolean;
  commentsCount: number;
  unreadCommentsCount: number;
};

class JournalApi extends ApiBase {
  fetchJournals(caregiverId: string) {
    return this.client.get<FacilitatorJournalResponse[]>(
      `/shared/${caregiverId}`
    );
  }

  fetchSingleJournal(caregiverId: string, journalId: string) {
    return this.client.get<Journal>(
      `/shared/${caregiverId}/fetch/${journalId}`
    );
  }

  createJournalComments(id: string, commentsDTO: CommentsDTO) {
    return this.client.post<Comments>(
      `/entry/${id}/comment`,
      CommentsDTO.serialize(commentsDTO)
    );
  }

  deleteComments(
    journalId: Journal['id'],
    commentId: Comments['id'],
    deletedAt: string
  ) {
    return this.client.delete<null>(
      `/entry/${journalId}/comment/${commentId}`,
      {
        data: { deletedAt }
      }
    );
  }
}

const instance = new JournalApi('/journal');

export { instance as JournalApi };
