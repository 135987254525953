import { ApiError } from 'entities/ApiError.entity';
import { Association } from 'entities/Association.entity';
import { UserDTO } from 'entities/DTO/UserDTO.entity';
import { User } from 'entities/User.entity';
import { UserApi, UsersQuery } from 'services/API/User/UserApi';

export const updateUserData = async (
  userId: string,
  userUpdateDTO: Partial<UserDTO>
): Promise<User> => {
  try {
    const response = await UserApi.updateUserData(userId, userUpdateDTO);

    return User.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchUserData = async (userId: User['id']): Promise<User> => {
  try {
    const response = await UserApi.fetchUserData(userId);

    return User.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchUserByUsername = async (
  userName: User['username']
): Promise<User> => {
  try {
    const response = await UserApi.fetchUserByUsername(userName);

    return User.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const changePassword = async (
  userId: string,
  passwordData: { oldPassword: string; newPassword: string }
): Promise<void> => {
  try {
    await UserApi.changePassword(userId, passwordData);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const sendResetPasswordLink = async (userId: string): Promise<void> => {
  try {
    await UserApi.sendResetPasswordLink(userId);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const transferUser = async (
  userId: string,
  organizationId: string
): Promise<User> => {
  try {
    const response = await UserApi.transferUser(userId, organizationId);

    return User.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateEmailNotifications = async (
  userId: string,
  receiveEmailNotifications: boolean
): Promise<User> => {
  try {
    const response = await UserApi.updateReceiveEmailNotifications(
      userId,
      receiveEmailNotifications
    );

    return User.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchUsers = async (usersOptions: UsersQuery): Promise<User[]> => {
  try {
    const { data } = await UserApi.fetchUsers(usersOptions);

    return User.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const activateUsers = async (
  userIds: Array<User['id']>
): Promise<void> => {
  try {
    await UserApi.activateUsers(userIds);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const deactivateUsers = async (
  userIds: Array<User['id']>
): Promise<void> => {
  try {
    await UserApi.deactivateUsers(userIds);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const archiveUsers = async (
  userIds: Array<User['id']>
): Promise<void> => {
  try {
    await UserApi.archiveUsers(userIds);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchAssociations = async (
  userId: User['id']
): Promise<Association[]> => {
  try {
    const { data } = await UserApi.fetchAssociations(userId);

    return Association.deserializeAsList(data).sort((a, b) =>
      a.fullName.toString().localeCompare(b.fullName.toString())
    );
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const resendEmailVerification = async (
  userId: User['id']
): Promise<void> => {
  try {
    await UserApi.resendVerificationEmail(userId);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
