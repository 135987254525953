import React, { FC, useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { Repertoire } from 'entities/Repertoire.entity';
import { EditRepertoireModal } from 'views/Settings/EditRepertoireModal';

import styles from './RepertoireSettingAction.module.scss';

interface Props {
  repertoire: Repertoire;
}

export const RepertoireSettingAction: FC<React.PropsWithChildren<Props>> = ({
  repertoire
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const onToggleEditModal = useCallback(() => {
    setIsEditModalOpen((prevIsEditModalOpen) => !prevIsEditModalOpen);
  }, []);

  return (
    <>
      <EditRepertoireModal
        repertoire={repertoire}
        isOpen={isEditModalOpen}
        onCloseModal={onToggleEditModal}
      />

      <div className={styles['repertoire-setting-action']}>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={onToggleEditModal}
        >
          Edit
        </Button>
      </div>
    </>
  );
};
