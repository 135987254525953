import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { fetchTasks } from 'actions/Task/taskActions';
import { CreateTaskFab } from 'components/CreateTaskFab';
import { DataTable, DataTableHandle } from 'components/DataTable';
import { If } from 'components/If';
import { Layout } from 'components/Layout';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { useRepertoires } from 'hooks/Task/useRepertoires';
import { TaskColumn, useTasksContext } from 'hooks/Task/useTasksContext';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { useDebounce } from 'use-debounce';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';
import { getEntityName } from 'utils/helpers/commonHelpers';
import { CreateFolderModal } from 'views/Library/CreateFolderModal';
import { LibraryTableActionBar } from 'views/Library/LibraryTableActionsBar';
import { TaskStatusBar } from 'views/Library/TaskStatusBar';
import { SharedLibraryModal } from 'views/SharedLibrary/SharedLibraryModal';
import { SharedLibraryProvider } from 'views/SharedLibrary/SharedLibraryProvider';
import { CreateTaskModal } from 'views/Task/CreateTaskModal';

import { columns } from './columns';

import styles from './LibraryPage.module.scss';

export const LibraryPage: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    activeFolder,
    search,
    status,
    sort: taskSort,
    onChangeStatus,
    onChangeSearch,
    onChangeSort
  } = useTasksContext();
  const history = useHistory();

  const tableRef = useRef<DataTableHandle>(null);

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [isSharedLibraryModalOpen, setIsSharedLibraryModalOpen] =
    useState(false);
  const [formattedActiveFolder, setFormattedActiveFolder] =
    useState<TaskColumn | null>(null);

  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);

  const onToggleCreateFolderModal = useCallback(() => {
    setIsCreateFolderModalOpen(
      (prevIsCreateFolderModalOpen) => !prevIsCreateFolderModalOpen
    );
  }, []);

  const onToggleCreateTaskModal = useCallback(() => {
    setIsCreateTaskModalOpen(
      (prevIsCreateTaskModalOpen) => !prevIsCreateTaskModalOpen
    );
  }, []);

  const onToggleSharedLibraryModal = useCallback(() => {
    setIsSharedLibraryModalOpen(
      (prevIsSharedLibraryModalOpen) => !prevIsSharedLibraryModalOpen
    );
  }, []);

  const onTaskSelect = useCallback(
    (tasks: Task[]) => setSelectedTasks(tasks),
    []
  );

  const onSelectionActionSuccess = useCallback(() => {
    tableRef.current?.reset();

    setSelectedTasks([]);
  }, []);

  const options = useMemo(
    () =>
      activeFolder
        ? {
            parentIdEq: activeFolder!.id,
            parentIdIsNull: undefined
          }
        : { parentIdIsNull: true, parentIdEq: undefined },
    [activeFolder]
  );

  const [debouncedSearch] = useDebounce(search, 1000);

  const {
    data: repertoiresData,
    isError: isRepertoireError,
    isSuccess: isRepertoireSuccess,
    isFetching: isRepertoireFetching
  } = useRepertoires();

  const addRepertoires = useCallback(
    (task: Task) => ({
      ...task,
      imageUrl: task.imageUrl,
      repertoireName: getEntityName(task.repertoireId, repertoiresData),
      isFolder: task.isFolder
    }),
    [repertoiresData]
  );

  const { data, hasNextPage, fetchNextPage, isFetching, isError } =
    useInfiniteQuery<TaskColumn[], ApiError>(
      queryKeys.filteredTasks({
        search: debouncedSearch,
        status,
        organizationIdIsNull: true,
        sort: taskSort,
        ...options
      }),
      async ({ pageParam }): Promise<TaskColumn[]> => {
        const tasks: Task[] = await fetchTasks({
          ...options,
          sort: [taskSort],
          exclude: ['steps'],
          publishStatusEq: status === TaskStatus.All ? undefined : status,
          limit: DEFAULT_PAGE_LIMIT,
          offset: pageParam?.offset || 0,
          search: debouncedSearch === '' ? undefined : debouncedSearch,
          organizationIdIsNull: true
        });

        return tasks.map((task) => addRepertoires(task));
      },
      {
        staleTime: ACCESS_TOKEN_EXPIRATION,
        retry: 0,
        enabled: isRepertoireSuccess,
        keepPreviousData: true,
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.length < DEFAULT_PAGE_LIMIT) {
            return undefined;
          }

          return {
            offset: allPages.flat().length
          };
        }
      }
    );

  const isInitialLoading = useInitialLoading(
    isFetching || isRepertoireFetching
  );

  const tableData = data?.pages.flat() || [];

  useEffect(() => {
    if (activeFolder) {
      setFormattedActiveFolder(addRepertoires(activeFolder));
    } else {
      setFormattedActiveFolder(null);
    }
  }, [activeFolder, addRepertoires, repertoiresData]);

  return (
    <Layout loading={isInitialLoading}>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <StatusSnackBar
        isError={isRepertoireError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles['title-container']}>
            <h3 className={styles.title}>CareCoordinator® Library</h3>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={onToggleSharedLibraryModal}
            >
              Browse CareCoordinator® Library
            </Button>
          </div>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => history.push(Routes.LibrarySettings)}
          >
            Settings
          </Button>
        </div>
        <LibraryTableActionBar
          searchValue={search}
          searchLabel="Search for lessons or templates"
          statusValue={status}
          sortValue={taskSort}
          onChangeSearchValue={onChangeSearch}
          onChangeStatusValue={(e) => {
            onChangeStatus(e.target.value as TaskStatus);
          }}
          onChangeSortValue={(e) => {
            onChangeSort(e.target.value as TaskSort);
          }}
        />
        <If condition={!isInitialLoading}>
          <DataTable
            ref={tableRef}
            columns={columns}
            data={
              formattedActiveFolder
                ? [formattedActiveFolder, ...tableData]
                : tableData
            }
            className={styles.table}
            hasNextPage={hasNextPage}
            isLoading={isFetching}
            onLoadMore={fetchNextPage}
            onRowSelect={onTaskSelect}
            dndDisabled
            emptyMessage={
              debouncedSearch || status !== TaskStatus.All
                ? EmptyStateMessages.Search
                : EmptyStateMessages.TaskPage
            }
          />
        </If>
      </div>

      <CreateTaskFab
        showCreateFolderButton={!activeFolder}
        onCreateTask={onToggleCreateTaskModal}
        onCreateFolder={onToggleCreateFolderModal}
      />

      <If condition={!activeFolder}>
        <CreateFolderModal
          isOpen={isCreateFolderModalOpen}
          onCloseModal={onToggleCreateFolderModal}
          options={{
            publishStatus: TaskStatus.Draft
          }}
        />
      </If>
      <CreateTaskModal
        isOpen={isCreateTaskModalOpen}
        activeFolderId={activeFolder?.id}
        onCloseModal={onToggleCreateTaskModal}
        options={{
          organizationIdIsNull: true,
          publishStatus: TaskStatus.Draft
        }}
      />

      <SharedLibraryProvider>
        <SharedLibraryModal
          onCloseModal={onToggleSharedLibraryModal}
          isOpen={isSharedLibraryModalOpen}
        />
      </SharedLibraryProvider>

      <TaskStatusBar
        selectedTasks={selectedTasks}
        onSuccess={onSelectionActionSuccess}
      />
    </Layout>
  );
};
